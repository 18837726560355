import { del, get, post, put, patch } from "./api_helper"
import { get_token } from "./storage";

const token = () => ({ 'Authorization': 'Token ' + get_token() })

export const postLogin = data => post(`user/login/`, data)

export const getMe = () => get(
  `user/me/`,
  { headers: { 'Authorization': 'Token ' + get_token() } }
)

export const getProjects = () => get(
  `project/`,
  { headers: token() }
)

export const updateProject = project => put(
  `project/` + project.id,
  project,
  { headers: token() }
)


export const addProject = project => {
  return post(
    `project/`,
    project,
    { headers: token() }
  )
}

export const getProjectUsersRequest = (project) => get(
  `project/${project.id}/user/`,
  { headers: token() }
)

export const addUserToProjectRequest = (project_id, user_mail) => post(
  `project/${project_id}/user/add/`,
  { email: user_mail },
  { headers: token() }
)
export const removeUserToProjectRequest = (project_id, user_id) => post(
  `project/${project_id}/user/remove/`,
  { user_id: user_id },
  { headers: token() }
)

export const createNewUserRequest = (user_data) => post(
  `user/create/`,
  user_data,
  { headers: token() }
)

export const getProjectsFolderRequest = (project) => get(
  `information/${project.id}/folder/`,
  { headers: token() }
)

export const createProjectsFolderRequest = (project_id, name) => post(
  `information/${project_id}/folder/`,
  { name: name },
  { headers: token() }
)

export const getFoldersFilesRequest = (folder_id) => get(
  `information/folder/${folder_id}/file/`,
  { headers: token() }
)

export const getFoldersFileWorkFlowRequest = (file_id) => get(
  `file/${file_id}/work-flow`,
  { headers: token() }
)
export const createWorkflowRequest = (file_id,workfow_data) => post(
  `file/${file_id}/work-flow`,
  { ...workfow_data },
  { headers: token() }
)


export const deleteFileRequest = (file_id) => del(
  `information/file/${file_id}/`,
  { headers: token() }
)

export const createFileRequest = (folder_id, file_type, version_note, file, name) => post(
  `information/folder/${folder_id}/file/`,
  {
    file_type: file_type,
    version_note: version_note,
    path: file,
    name: name,
  },
  { headers: token() }
)

export const createPermissionRequest = (file_id, user_type) => post(
  `information/file/${file_id}/permission/`,
  {
    user_type: user_type
  },
  { headers: token() }
)

export const deletePermissionRequest = (file_id, user_type) => del(
  `information/file/${file_id}/permission/?user_type=${user_type}`,
  { headers: token() }
)


export const getProjectsVisitsRequest = (project_id) => get(
  `planning/${project_id}/visit/`,
  { headers: token() }
)


export const deleteProjectsVisitsRequest = (visit_id) => del(
  `planning/visit/${visit_id}`,
  { headers: token() }
)


export const updateProjectsVisitsRequest = (visit_id, duration) => patch(
  `planning/visit/${visit_id}`,
  { duration: duration },
  { headers: token() }
)
export const updateCentersDevicesRequest = (id, countdown) => patch(
  `planning/device-update/${id}`,
  { countdown: countdown },
  { headers: token() }
)

export const newProjectsVisitsRequest = (project_id, duration) => post(
  `planning/${project_id}/visit/`,
  { duration: duration },
  { headers: token() }
)

//  Monitorization

export const getProjectsMonitorizationsRequest = (project_id) => get(
  `planning/${project_id}/monitorization/`,
  { headers: token() }
)


export const deleteProjectsMonitorizationsRequest = (monitorization_id) => del(
  `planning/monitorization/${monitorization_id}`,
  { headers: token() }
)


export const updateProjectsMonitorizationsRequest = (monitorization_id, duration) => patch(
  `planning/monitorization/${monitorization_id}`,
  { duration: duration },
  { headers: token() }
)

export const newProjectsMonitorizationsRequest = (project_id, duration) => post(
  `planning/${project_id}/monitorization/`,
  { duration: duration },
  { headers: token() }
)

//  Center

export const getProjectsCentersRequest = (project_id) => get(
  `planning/${project_id}/center/`,
  { headers: token() }
)


export const deleteProjectsCentersRequest = (center_id) => del(
  `planning/center/${center_id}`,
  { headers: token() }
)


export const updateProjectsCentersRequest = (center_id, duration) => patch(
  `planning/center/${center_id}`,
  { duration: duration },
  { headers: token() }
)

export const newProjectsCentersRequest = (project_id, name) => post(
  `planning/${project_id}/center/`,
  { name: name },
  { headers: token() }
)

export const getCentersDevicesRequest = (center_id) => get(
  `planning/${center_id}/device/`,
  { headers: token() }
)

export const newCentersDevicesRequest = (center_id, name, file, file_name, countdown) => post(
  `planning/${center_id}/device/`,
  { name: name, file: file, file_name: file_name, countdown: countdown },
  { headers: token() }
)

export const deleteDeviceRequest = (device_id) => del(
  `planning/device/${device_id}`,
  { headers: token() }
)


export const getCentersPaymentsRequest = (center_id) => get(
  `planning/${center_id}/payment/`,
  { headers: token() }
)

export const newCentersPaymentsRequest = (center_id, name, file, file_name) => post(
  `planning/${center_id}/payment/`,
  { name: name, file: file, file_name: file_name },
  { headers: token() }
)

export const deletePaymentRequest = (payment_id,center_id) => del(
  `planning/payment/${payment_id}`,
  { headers: token() }
)


export const getSurecTakiplerRequest = (project_id) => get(
  `operational_follow/${project_id}/process_follow/`,
  { headers: token() }
)

export const setProcessFollowRequest = (follow_id, name, value) => patch(
  `operational_follow/process_follow/${follow_id}`,
  { name: name, value: value },
  { headers: token() }
)

export const deleteProcessFollowRequest = (follow_id) => del(
  `operational_follow/process_follow/${follow_id}`,
  { headers: token() }
)

export const newProcessFollowRequest = (name, project_id, category_id) => post(
  `operational_follow/${project_id}/process_follow/`,
  { name: name, category: category_id },
  { headers: token() }
)

export const getPatientGroupRequest = (project_id) => get(
  `operational_follow/${project_id}/patient_group/`,
  { headers: token() }
)

export const setChangedGroupRequest = (patient_group_id, changed_vals) => patch(
  `operational_follow/patient_group/${patient_group_id}`,
  changed_vals,
  { headers: token() }
)

export const newVisitFollowRequest = (project_id, center_id) => post(
  `operational_follow/${project_id}/visit_follow/`,
  { center: center_id },
  { headers: token() }
)

export const getVisitFollowRequest = (project_id) => get(
  `operational_follow/${project_id}/visit_follow/`,
  { headers: token() }
)
export const getCentersPaymentFlowsRequest = (payment_id,project_id) => get(
  `payment/${payment_id}/${project_id}/payment-flow`,
  { headers: token() }
)


export const docxToPdfRequest = (base64_docx) => fetch(process.env["REACT_APP_BACKEND_URL"] + "core/docx_to_pdf/", {
  method: 'POST',
  headers: {
    'Authorization': 'Token ' + get_token(),
    'Content-Type': 'application/json'
  },

  body: JSON.stringify({ file: base64_docx })
})
  .then(response => response.blob())
  .then(blob => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = "filename.pdf";
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();  //afterwards we remove the element again
  });

export const downloadAllFilesOfProjectRequest = (project_id) => fetch(
  process.env["REACT_APP_BACKEND_URL"] + "project/" + project_id + "/download-files/", {
  method: 'GET',
  headers: {
    'Authorization': 'Token ' + get_token(),
    'Content-Type': 'application/json'
  },
})
  .then(response => response.blob())
  .then(blob => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = "files.zip";
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();  //afterwards we remove the element again
  });


export const newProformaRequest = (payload) => post(
  `project/${payload.project_id}/proforma`,
  {title: payload.title, value: payload.value, id: payload.id, status: payload.status},
  { headers: token() }
)

export const newPayFlowRequest = (payload) => post(
  `payment/${payload.payment_id}/${payload.proje_id}/payment-flow`,
  {file:payload.file, file_full_name: payload.file_full_name,process_time:payload.paymentModalData.process_time,proforma_id:payload.paymentModalData.proforma_id,user_id:payload.paymentModalData.user_id,value:payload.paymentModalData.value,id:payload.paymentModalData.id,delete_wf:payload.paymentModalData.delete_wf},
  { headers: token() }
)



export const projectBudgetAbstractRequest = (project) => get(
  `budget/${project}`,
  { headers: token() }
)
