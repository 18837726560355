import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

// import Component

import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'
import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import {
  getProjectsVisitsAction,
  deleteProjectsVisitsAction,
  updateProjectsVisitDurationAction,
  newProjectsVisitAction,
  closeModalAction,
} from '../../store/planlama-ve-takvim/actions'

const APP = props => {
  const [current_visits, set_current_visits] = useState(null)

  const dispatch = useDispatch()

  // TODO:
  const { projects_visits, close_modal } = useSelector(state => ({
    projects_visits: state.PlanlamaVeTakvim.projects_visits,
    close_modal: state.PlanlamaVeTakvim.close_modal,
  }))

  const get_visits = () => {
    if (props.current_proje && props.current_proje.id) {
      dispatch(getProjectsVisitsAction(props.current_proje.id))
    }
  }

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')

  useEffect(() => {
    get_visits()
    if (props.current_proje) {
      set_current_visits(projects_visits[props.current_proje.id])
    }
  }, [props.current_proje])

  useEffect(() => {
    if (props.current_proje) {
      set_current_visits(projects_visits[props.current_proje.id])
    }
  }, [projects_visits, props.current_proje])

  const delete_visit = visit_id => {
    if (confirm('Emin misiniz?')) {
      dispatch(deleteProjectsVisitsAction(visit_id, props.current_proje.id))
      get_visits()
    }
  }

  const handle_duration_change = (visit_id, e) => {
    dispatch(updateProjectsVisitDurationAction(visit_id, props.current_proje.id, e.target.value))
    e.target.value = 'updating'
  }

  ///

  const [modal_opened, set_modal_opened] = useState(false)
  const [new_visit_duration, set_new_visit_duration] = useState(0)

  const handle_new_visit = () => {
    dispatch(newProjectsVisitAction(props.current_proje.id, new_visit_duration))
  }

  useEffect(() => {
    if (close_modal === true) {
      set_modal_opened(false)
      dispatch(closeModalAction(false))
    }
  }, [close_modal])

  return (
    <React.Fragment>
      <Row>
        <Col xs={4} />
        <Col xs={4} className="d-flex justify-content-center">
          <Label>Bir önceki vize ile arasındaki süre</Label>
        </Col>
        <Col xs={4} />
      </Row>

      {current_visits ? (
        current_visits.map((visit, index) => (
          <Row className="mt-3">
            <Col xs={4}>
              <Input type="text" value={`${index + 1}. vizit`} disabled />
            </Col>
            <Col xs={4}>
              {index != 0 ? (
                <Input
                  name="proje_baslangic"
                  type="number"
                  className="form-control"
                  onChange={handle_duration_change.bind(this, visit.id)}
                  value={visit.duration}
                />
              ) : (
                <></>
              )}
            </Col>
            <Col xs={4} className="d-flex justify-content-center">
              <Button onClick={delete_visit.bind(this, visit.id)} color="primary">
                Sil
              </Button>
            </Col>
          </Row>
        ))
      ) : (
        <></>
      )}
      <Row className="mt-3">
        <Col>
          {user_type == 'ADM' || user_type == 'PM' || user_type == 'CRA' ? (
            <Button
              color="success"
              onClick={() => {
                set_modal_opened(!modal_opened)
              }}>
              Ekle
            </Button>
          ) : (
            ''
          )}
        </Col>
      </Row>

      {/* NEW */}

      <Modal
        isOpen={modal_opened}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          set_modal_opened(!modal_opened)
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              set_modal_opened(!modal_opened)
            }}>
            Yeni Vizit
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <Label>Süre</Label>
              <Input
                type="number"
                className="form-control"
                value={new_visit_duration}
                onChange={e => {
                  set_new_visit_duration(e.target.value)
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                set_modal_opened(!modal_opened)
              }}>
              Kapat
            </Button>
            <Button type="button" color="primary" onClick={handle_new_visit}>
              Ekle
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default APP
