/* Projects */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"

/**
 * add project
 */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL"

/**
 * Edit project
 */
export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"

/**
 * Get projects users
 */
export const GET_PROJECTS_USERS = "GET_PROJECTS_USERS"
export const GET_PROJECTS_USERS_SUCCESS = "GET_PROJECTS_USERS_SUCCESS";
export const GET_PROJECTS_USERS_FAIL = "GET_PROJECTS_USERS_FAIL";

/**
 * Add user to project
 */
export const ADD_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";
export const ADD_USER_TO_PROJECT_LAST_RESPONSE = "ADD_USER_TO_PROJECT_LAST_RESPONSE";
export const ADD_USER_TO_PROJECT_LAST_RESPONSE_HANDLED = "ADD_USER_TO_PROJECT_LAST_RESPONSE_HANDLED";
export const ADD_USER_TO_PROJECT_SUCCESS = "ADD_USER_TO_PROJECT_SUCCESS";
export const ADD_USER_TO_PROJECT_FAIL = "ADD_USER_TO_PROJECT_FAIL";

/**
 * Remove user to project
 */
export const REMOVE_USER_TO_PROJECT = "REMOVE_USER_TO_PROJECT";
export const REMOVE_USER_TO_PROJECT_LAST_RESPONSE = "REMOVE_USER_TO_PROJECT_LAST_RESPONSE";
export const REMOVE_USER_TO_PROJECT_LAST_RESPONSE_HANDLED = "REMOVE_USER_TO_PROJECT_LAST_RESPONSE_HANDLED";
export const REMOVE_USER_TO_PROJECT_SUCCESS = "REMOVE_USER_TO_PROJECT_SUCCESS";
export const REMOVE_USER_TO_PROJECT_FAIL = "REMOVE_USER_TO_PROJECT_FAIL";

/**
 * Add user to project
 */
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_FAIL = "CREATE_NEW_USER_FAIL";
export const CREATE_NEW_USER_RESET_RESULT = "CREATE_NEW_USER_RESET_RESULT";

/**
 * Delete project??
 */
export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

export const GET_PROJECT_BUDGET_ABSTRACT = "GET_PROJECT_BUDGET_ABSTRACT"
export const GET_PROJECT_BUDGET_ABSTRACT_SUCCESS = "GET_PROJECT_BUDGET_ABSTRACT_SUCCESS"
