const extend_local_storage = (key, new_data) => {
  if (localStorage.getItem(key)) {
    let old_data = JSON.parse(localStorage.getItem(key));

    let new_local = {
      ...old_data,
      ...new_data,
    };

    localStorage.setItem(key, JSON.stringify(new_local))
  }
  else {
    localStorage.setItem(key, JSON.stringify(new_data))
  }
}


const get_token = () => {
  let auth_info = JSON.parse(localStorage.getItem("authUser"))
  if (auth_info)
    return auth_info.token
  else
    return null
}

const get_user_type = () => {
  const obj = JSON.parse(localStorage.getItem('authUser'))
  return obj.user_type
}


export {
  extend_local_storage,
  get_token,
  get_user_type,
}
