import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import { Link, withRouter } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { map } from 'lodash'

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'

//Import Card
import CardContact from './card-contact'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { getProjects as onGetUsers } from '../../store/projeler/actions'

const ContactsGrid = props => {
  const dispatch = useDispatch()

  const { projeler, projects_get } = useSelector(state => ({
    projeler: state.contacts.projeler,
    projects_get: state.contacts.projects_get,
  }))

  useEffect(() => {
    if (projects_get == false) {
      dispatch(onGetUsers())
    }
  }, [dispatch, projeler])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Grid </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User Grid" />

          <Row>
            {map(projeler, (user, key) => (
              <CardContact user={user} key={'_user_' + key} />
            ))}
          </Row>

          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsGrid)
