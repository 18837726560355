import React, { Component, useState, useEffect } from 'react'

import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'
import doc from '../../assets/fiziblite3.docx'
import { docxToPdfRequest } from '../../helpers/backend'
import { Button } from 'reactstrap'

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback)
}

const DocGenerator = props => {
  /*
    ############################################################################

    GENERIC FUNCTIONS

    ############################################################################
  */

  useEffect(() => {}, [props.surecler])

  const get_carpan_value = (kalem_id, carpan_id) => {
    for (let surec of props.surecler) {
      for (let kategori of surec.kategoriler) {
        for (let kalem of kategori.kalemler) {
          if (kalem.id == kalem_id) {
            for (let carpan of kalem.carpanlar) {
              if (carpan.id == carpan_id) {
                return Number.parseInt(carpan.value)
              }
            }
          }
        }
      }
    }
  }

  const get_all_kalems_of_kategori = kategori_id => {
    for (let surec of props.surecler) {
      for (let kategori of surec.kategoriler) {
        if (kategori.id == kategori_id) {
          return kategori.kalemler.map(i => i.id)
        }
      }
    }
  }

  const get_kalem_name = kalem_id => {
    for (let surec of props.surecler) {
      for (let kategori of surec.kategoriler) {
        for (let kalem of kategori.kalemler) {
          if (kalem.id == kalem_id) {
            return kalem.name
          }
        }
      }
    }
  }

  const get_node_total = node => {
    let total = 0
    if (node.kategoriler) {
      for (let k of node.kategoriler) {
        total += get_node_total(k)
      }
    } else if (node.kalemler) {
      for (let k of node.kalemler) {
        total += get_node_total(k)
      }
    } else if (node.carpanlar) {
      let carpim = 1
      for (let k of node.carpanlar) {
        carpim *= k.value
      }

      return carpim
    }

    return total
  }

  /*
    ############################################################################

    Not Geeric

    ############################################################################
  */

  const get_kategori_13_info = () => {
    return get_all_kalems_of_kategori(13)
      .filter(k => get_carpan_value(k, 35) * get_carpan_value(k, 34) * get_carpan_value(k, 33) > 0)
      .map(kalem_id => {
        let adet_gonderim_ucreti = Number.parseInt(get_carpan_value(kalem_id, 35))
        let proje_suresi = get_carpan_value(kalem_id, 34)
        let hasta_sayisi = get_carpan_value(kalem_id, 33)

        return {
          name: get_kalem_name(kalem_id),
          toplam_adet: proje_suresi * hasta_sayisi,
          adet_gonderim_ucreti: adet_gonderim_ucreti,
          toplam_odeme_tutari: proje_suresi * hasta_sayisi * adet_gonderim_ucreti,
          proje_suresi_PROD_hedef_hasta_sayisi: proje_suresi * hasta_sayisi,
        }
      })
  }

  const get_kategori_8_info = () => {
    return get_all_kalems_of_kategori(8)
      .filter(
        kalem_id => get_carpan_value(kalem_id, 89) * get_carpan_value(kalem_id, 90) * get_carpan_value(kalem_id, 91) > 0
      )
      .map(kalem_id => {
        let proje_suresi = get_carpan_value(kalem_id, 89)
        let hasta_sayisi = get_carpan_value(kalem_id, 90)
        let gonderim_plus_alim_ucreti = get_carpan_value(kalem_id, 91)

        return {
          name: get_kalem_name(kalem_id),
          toplam_adet: proje_suresi * hasta_sayisi,
          gonderim_plus_alim_ucreti: gonderim_plus_alim_ucreti,
          toplam: proje_suresi * hasta_sayisi * gonderim_plus_alim_ucreti,
        }
      })
  }

  const get_kategori_7_info = () => {
    return get_all_kalems_of_kategori(7)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 85) *
            get_carpan_value(kalem_id, 86) *
            get_carpan_value(kalem_id, 87) *
            get_carpan_value(kalem_id, 88) >
          0
      )
      .map(kalem_id => {
        let aylik_siparis_sayisi = get_carpan_value(kalem_id, 85)
        let siparis_basina_ucret = get_carpan_value(kalem_id, 86)
        let proje_suresi = get_carpan_value(kalem_id, 87)
        let merkez_sayisi = get_carpan_value(kalem_id, 88)

        return {
          name: get_kalem_name(kalem_id),
          aylik_siparis_sayisi: aylik_siparis_sayisi,
          siparis_basina_ucret: siparis_basina_ucret,
          toplam: aylik_siparis_sayisi * siparis_basina_ucret * proje_suresi * merkez_sayisi,
        }
      })
  }

  const get_kategori_14_info = () => {
    return get_all_kalems_of_kategori(14)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 36) *
            get_carpan_value(kalem_id, 37) *
            get_carpan_value(kalem_id, 38) *
            get_carpan_value(kalem_id, 39) >
          0
      )
      .map(kalem_id => {
        let cihaz_sayisi = get_carpan_value(kalem_id, 36)
        let merkez_sayisi = get_carpan_value(kalem_id, 37)
        let cihaz_alim_ucreti = get_carpan_value(kalem_id, 38)
        let gonderim_ucreti = get_carpan_value(kalem_id, 39)

        return {
          name: get_kalem_name(kalem_id),
          cihaz_alim_ucreti: cihaz_alim_ucreti,
          gonderim_ucreti: gonderim_ucreti,
          toplam: cihaz_sayisi * merkez_sayisi * cihaz_alim_ucreti * gonderim_ucreti,
        }
      })
  }

  const get_kategori_12_insert_info = () => {
    let related_infos = get_all_kalems_of_kategori(12)
      .filter(
        kalem_id => get_carpan_value(kalem_id, 30) * get_carpan_value(kalem_id, 31) * get_carpan_value(kalem_id, 32) > 0
      )
      .map(kalem_id => {
        let merkez_basina_aylik_kullanim = get_carpan_value(kalem_id, 30)
        let calisma_suresi = get_carpan_value(kalem_id, 31)
        let kurye_gonderim_ucreti = get_carpan_value(kalem_id, 32)

        return {
          name: get_kalem_name(kalem_id),
          merkez_basina_aylik_kullanim,
          calisma_suresi,
          kurye_gonderim_ucreti,
          toplam: merkez_basina_aylik_kullanim * calisma_suresi * kurye_gonderim_ucreti,
        }
      })

    return {
      kategori_12_merkez_basina_aylik_kullanim: related_infos.reduce(
        (x, y) => ({ merkez_basina_aylik_kullanim: x.merkez_basina_aylik_kullanim + y.merkez_basina_aylik_kullanim }),
        { merkez_basina_aylik_kullanim: 0 }
      ).merkez_basina_aylik_kullanim,
      kategori_12_kurye_gonderim_ucreti: related_infos.reduce(
        (x, y) => ({ kurye_gonderim_ucreti: x.kurye_gonderim_ucreti + y.kurye_gonderim_ucreti }),
        { kurye_gonderim_ucreti: 0 }
      ).kurye_gonderim_ucreti,
      kategori_12_toplam: related_infos.reduce((x, y) => ({ toplam: x.toplam + y.toplam }), { toplam: 0 }).toplam,
    }
  }

  const get_kategori_2_insert_info = () => {
    let related_infos = get_all_kalems_of_kategori(2)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 103) * get_carpan_value(kalem_id, 104) * get_carpan_value(kalem_id, 105) > 0
      )
      .map(kalem_id => {
        let merkez_sayisi = get_carpan_value(kalem_id, 103)
        let basim_adedi = get_carpan_value(kalem_id, 104)
        let birim_ucreti = get_carpan_value(kalem_id, 105)

        return {
          name: get_kalem_name(kalem_id),
          merkez_sayisi,
          basim_adedi,
          birim_ucreti,
          toplam: merkez_sayisi * basim_adedi * birim_ucreti,
        }
      })

    return {
      kategori_2_basim_adedi: related_infos.reduce((x, y) => ({ basim_adedi: x.basim_adedi + y.basim_adedi }), {
        basim_adedi: 0,
      }).basim_adedi,
      kategori_2_birim_ucreti: related_infos.reduce((x, y) => ({ birim_ucreti: x.birim_ucreti + y.birim_ucreti }), {
        birim_ucreti: 0,
      }).birim_ucreti,
      kategori_2_toplam: related_infos.reduce((x, y) => ({ toplam: x.toplam + y.toplam }), { toplam: 0 }).toplam,
    }
  }

  const get_kategori_21_info = () => {
    return get_all_kalems_of_kategori(21)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 61) *
            get_carpan_value(kalem_id, 62) *
            get_carpan_value(kalem_id, 63) *
            get_carpan_value(kalem_id, 64) >
          0
      )
      .map(kalem_id => {
        let hasta_basi_aylik_kullanim_ucreti = get_carpan_value(kalem_id, 61)
        let hedef_hasta_sayisi = get_carpan_value(kalem_id, 62)
        let merkez_sayisi = get_carpan_value(kalem_id, 63)
        let calisma_suresi = get_carpan_value(kalem_id, 64)

        return {
          name: get_kalem_name(kalem_id),
          hedef_hasta_sayisi,
          hasta_basi_aylik_kullanim_ucreti,
          toplam: hasta_basi_aylik_kullanim_ucreti * hedef_hasta_sayisi * merkez_sayisi * calisma_suresi,
        }
      })
  }

  const get_kategori_18_info = () => {
    return get_all_kalems_of_kategori(18)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 50) *
            get_carpan_value(kalem_id, 51) *
            get_carpan_value(kalem_id, 52) *
            get_carpan_value(kalem_id, 53) >
          0
      )
      .map(kalem_id => {
        let hedef_hasta_sayisi = get_carpan_value(kalem_id, 50)
        let hasta_basina_odeme_tutari = get_carpan_value(kalem_id, 51)
        let calisma_suresi = get_carpan_value(kalem_id, 52)
        let merkez_sayisi = get_carpan_value(kalem_id, 53)

        return {
          name: get_kalem_name(kalem_id),
          hedef_hasta_sayisi,
          hasta_basina_odeme_tutari,
          toplam: hedef_hasta_sayisi * hasta_basina_odeme_tutari * calisma_suresi * merkez_sayisi,
        }
      })
  }

  const get_kategori_17_info = () => {
    return get_all_kalems_of_kategori(17)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 46) *
            get_carpan_value(kalem_id, 47) *
            get_carpan_value(kalem_id, 48) *
            get_carpan_value(kalem_id, 49) >
          0
      )
      .map(kalem_id => {
        let hasta_basina_vizit_sayisi = get_carpan_value(kalem_id, 46)
        let hasta_sayisi = get_carpan_value(kalem_id, 47)
        let calisma_suresi = get_carpan_value(kalem_id, 48)
        let hasta_basina_yol_ucreti = get_carpan_value(kalem_id, 49)

        return {
          name: get_kalem_name(kalem_id),
          hasta_basina_yol_ucreti,
          toplam: hasta_basina_vizit_sayisi * hasta_sayisi * calisma_suresi * hasta_basina_yol_ucreti,
        }
      })
  }

  const get_kategori_27_info = () => {
    return get_all_kalems_of_kategori(27)
      .filter(
        kalem_id => get_carpan_value(kalem_id, 92) * get_carpan_value(kalem_id, 93) * get_carpan_value(kalem_id, 94) > 0
      )
      .map(kalem_id => {
        let hasta_basina_sigorta_ucreti = get_carpan_value(kalem_id, 92)
        let hasta_sayisi = get_carpan_value(kalem_id, 93)
        let calisma_suresi = get_carpan_value(kalem_id, 94)

        return {
          name: get_kalem_name(kalem_id),
          hasta_basina_sigorta_ucreti,
          toplam: hasta_basina_sigorta_ucreti * hasta_sayisi * calisma_suresi,
        }
      })
  }

  const get_kategori_22_info = () => {
    return get_all_kalems_of_kategori(22)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 65) *
            get_carpan_value(kalem_id, 66) *
            get_carpan_value(kalem_id, 67) *
            get_carpan_value(kalem_id, 68) >
          0
      )
      .map(kalem_id => {
        let birim_ucreti = get_carpan_value(kalem_id, 65)
        let adet_sayisi = get_carpan_value(kalem_id, 66)
        let merkez_sayisi = get_carpan_value(kalem_id, 67)
        let calisma_suresi = get_carpan_value(kalem_id, 68)

        return {
          name: get_kalem_name(kalem_id),
          adet_sayisi,
          birim_ucreti,
          toplam: birim_ucreti * adet_sayisi * merkez_sayisi * calisma_suresi,
        }
      })
  }

  const get_kategori_23_info = () => {
    return get_all_kalems_of_kategori(23)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 69) *
            get_carpan_value(kalem_id, 70) *
            get_carpan_value(kalem_id, 71) *
            get_carpan_value(kalem_id, 72) >
          0
      )
      .map(kalem_id => {
        let birim_ucreti = get_carpan_value(kalem_id, 69)
        let adet_sayisi = get_carpan_value(kalem_id, 70)
        let merkez_sayisi = get_carpan_value(kalem_id, 71)
        let calisma_suresi = get_carpan_value(kalem_id, 72)

        return {
          name: get_kalem_name(kalem_id),
          adet_sayisi,
          birim_ucreti,
          toplam: birim_ucreti * adet_sayisi * merkez_sayisi * calisma_suresi,
        }
      })
  }

  const get_kategori_28_info = () => {
    return get_all_kalems_of_kategori(28)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 95) *
            get_carpan_value(kalem_id, 96) *
            get_carpan_value(kalem_id, 97) *
            get_carpan_value(kalem_id, 98) >
          0
      )
      .map(kalem_id => {
        let vizit_basina_kullanim_sayisi = get_carpan_value(kalem_id, 95)
        let kullanim_ucreti = get_carpan_value(kalem_id, 96)
        let merkez_sayisi = get_carpan_value(kalem_id, 97)
        let calisma_ucreti = get_carpan_value(kalem_id, 98)

        return {
          name: get_kalem_name(kalem_id),
          vizit_basina_kullanim_sayisi,
          kullanim_ucreti,
          toplam: vizit_basina_kullanim_sayisi * kullanim_ucreti * merkez_sayisi * calisma_ucreti,
        }
      })
  }

  const get_kategori_29_info = () => {
    return get_all_kalems_of_kategori(29)
      .filter(
        kalem_id =>
          get_carpan_value(kalem_id, 99) *
            get_carpan_value(kalem_id, 100) *
            get_carpan_value(kalem_id, 101) *
            get_carpan_value(kalem_id, 102) >
          0
      )
      .map(kalem_id => {
        let vizit_basina_kullanim_sayisi = get_carpan_value(kalem_id, 99)
        let kullanim_ucreti = get_carpan_value(kalem_id, 100)
        let merkez_sayisi = get_carpan_value(kalem_id, 101)
        let calisma_ucreti = get_carpan_value(kalem_id, 102)

        return {
          name: get_kalem_name(kalem_id),
          vizit_basina_kullanim_sayisi,
          kullanim_ucreti,
          toplam: vizit_basina_kullanim_sayisi * kullanim_ucreti * merkez_sayisi * calisma_ucreti,
        }
      })
  }

  const get_kategori_11_info = () => {
    return get_all_kalems_of_kategori(11)
      .filter(kalem_id => get_carpan_value(kalem_id, 28) * get_carpan_value(kalem_id, 29) > 0)
      .map(kalem_id => {
        let hedef_hasta_sayisi = get_carpan_value(kalem_id, 28)
        let dokuman_birim_maliyeti = get_carpan_value(kalem_id, 29)

        return {
          name: get_kalem_name(kalem_id),
          hedef_hasta_sayisi,
          dokuman_birim_maliyeti,
          toplam: dokuman_birim_maliyeti * hedef_hasta_sayisi,
        }
      })
  }

  const get_kategori_10_info = () => {
    return get_all_kalems_of_kategori(10)
      .filter(
        kalem_id => get_carpan_value(kalem_id, 25) * get_carpan_value(kalem_id, 26) * get_carpan_value(kalem_id, 27) > 0
      )
      .map(kalem_id => {
        let merkez_sayisi = get_carpan_value(kalem_id, 25)
        let dokuman_adedi = get_carpan_value(kalem_id, 26)
        let dokuman_birim_maliyeti = get_carpan_value(kalem_id, 27)

        return {
          name: get_kalem_name(kalem_id),
          dokuman_adedi,
          dokuman_birim_maliyeti,
          toplam: merkez_sayisi * dokuman_adedi * dokuman_birim_maliyeti,
        }
      })
  }

  const get_doc_data = () => {
    let doc_data = {
      kategori_10: get_kategori_10_info(),
      kategori_11: get_kategori_11_info(),
      kategori_28: get_kategori_28_info(),
      kategori_29: get_kategori_29_info(),
      kategori_23: get_kategori_23_info(),
      kategori_22: get_kategori_22_info(),
      kategori_27: get_kategori_27_info(),
      kategori_17: get_kategori_17_info(),
      kategori_18: get_kategori_18_info(),
      kategori_21: get_kategori_21_info(),
      ...get_kategori_12_insert_info(),
      ...get_kategori_2_insert_info(),
      kategori_14: get_kategori_14_info(),
      kategori_7: get_kategori_7_info(),
      kategori_8: get_kategori_8_info(),
      kategori_13: get_kategori_13_info(),
      // merkez_PROD_calisma_suresi: props.merkez_sayisi * props.proje_suresi_yil,

      gonullu_hasta_sayisi_PROD_hasta_basina_yillik_vizit_sayisi_PROD_calisma_suresi:
        props.hasta_sayisi * props.hasta_basina_vizit_sayisi * props.proje_suresi_yil,
      hasta_basina_yillik_vizit_PROD_calisma_yil: props.proje_suresi_yil * props.hasta_basina_vizit_sayisi,
      //
      arastirici_toplanti_sayisi: get_all_kalems_of_kategori(16)
        .map(kategori_id => Number.parseInt(get_carpan_value(kategori_id, 43)))
        .reduce((x, y) => x + y, 0),
      saha_gorevlisi_sayisi: get_carpan_value(13, 12),
      klinik_arastirma_uzmani_sayisi: get_carpan_value(12, 12),
      proje_yoneticisi_sayisi: get_carpan_value(11, 12),
      arastirici_hekim_sayisi: get_carpan_value(14, 12),
      proje_suresi_yil: props.proje_suresi_yil,
      calisma_icin_belirlenen_tibbi_brans: props.calisma_icin_belirlenen_tibbi_brans,
      hasta_sayisi: props.hasta_sayisi,
      merkez_sayisi: props.merkez_sayisi,
      calisma_adi_ve_kodu: props.calisma_adi_ve_kodu,
      para_birimi: props.para_birimi,
      hafta_sayisi: props.hafta_sayisi,
      toplam_monitorizasyon_sayisi: props.merkez_sayisi * ((props.proje_suresi_yil * 4) / props.hafta_sayisi),
    }

    doc_data.merkezlere_medikal_donanım_teslim_edilmesi_sureci = true
    if (doc_data.kategori_13.length == 0 && doc_data.kategori_8.length == 0 && doc_data.kategori_7.length == 0) {
      doc_data.merkezlere_medikal_donanım_teslim_edilmesi_sureci = false
    }

    doc_data.merkezlere_tibbi_cihaz_gonderim_sureci = true
    if (doc_data.kategori_14.length == 0) {
      doc_data.merkezlere_tibbi_cihaz_gonderim_sureci = false
    }

    doc_data.merkezlerden_tibbi_orneklem_alimi_kurye_gonderim = true
    if (doc_data.kategori_12_toplam == 0) {
      doc_data.merkezlerden_tibbi_orneklem_alimi_kurye_gonderim = false
    }

    doc_data.tibbi_cihazlarla_ilgili_servis_hizmet_bilgisi = true
    if (doc_data.kategori_21.length == 0) {
      doc_data.tibbi_cihazlarla_ilgili_servis_hizmet_bilgisi = false
    }

    doc_data.odeme_bilgisi = true
    if (doc_data.kategori_17.length == 0 && doc_data.kategori_18.length == 0 && doc_data.kategori_27.length == 0) {
      doc_data.odeme_bilgisi = false
    }

    doc_data.sehir_ici_monitorizasyon = true
    if (doc_data.kategori_22.length == 0) {
      doc_data.sehir_ici_monitorizasyon = false
    }

    doc_data.sehir_disi_monitorizasyon = true
    if (doc_data.kategori_23.length == 0) {
      doc_data.sehir_disi_monitorizasyon = false
    }

    doc_data.sehir_ici_vizit_butcesi = true
    if (doc_data.kategori_28.length == 0) {
      doc_data.sehir_ici_vizit_butcesi = false
    }

    doc_data.sehir_disi_vizit_butcesi = true
    if (doc_data.kategori_29.length == 0) {
      doc_data.sehir_disi_vizit_butcesi = false
    }

    doc_data.tibbi_rapor_ve_dokuman_basim_bilgisi = true
    if (doc_data.kategori_10.length == 0 && doc_data.kategori_11.length == 0 && doc_data.kategori_2_toplam == 0) {
      doc_data.tibbi_rapor_ve_dokuman_basim_bilgisi = false
    }

    doc_data.protokol_kitabcigi_gonderim_bilgisi = true
    if (doc_data.kategori_2_toplam == 0) {
      doc_data.protokol_kitabcigi_gonderim_bilgisi = false
    }

    return doc_data
  }

  useEffect(() => {
    // make it on function, not in use effect
    // console.log(get_doc_data()) // debug
  }, [props])

  const generateDocument = () => {
    loadFile(doc, function (error, content) {
      if (error) {
        throw error
      }
      const zip = new PizZip(content)
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      })
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render(get_doc_data())
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (error, key) {
              error[key] = value[key]
              return error
            }, {})
          }
          return value
        }
        console.log(JSON.stringify({ error: error }, replaceErrors))

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation
            })
            .join('\n')
          console.log('errorMessages', errorMessages)
        }
        throw error
      }
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }) //Output the document using Data-URI

      blobToBase64(out)
      // saveAs(out, 'output.docx')
    })
  }

  var blobToBase64 = function (blob) {
    var reader = new FileReader()
    reader.onload = function () {
      var dataUrl = reader.result
      // var base64 = dataUrl.split(',')[1]

      docxToPdfRequest(dataUrl)
    }
    reader.readAsDataURL(blob)
  }

  return (
    <Button type="button" color="success" onClick={generateDocument}>
      PDF Olarak İndir
    </Button>
  )
}

export default DocGenerator
