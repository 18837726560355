import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  Button,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  Collapse,
} from 'reactstrap'
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { DEFAULT_SURECLER, DEFAULT_KATEGORILER, DEFAULT_KALEMLER, DEFAULT_CARPANLAR } from './defaults'
import classnames from 'classnames'
import DocGenerator from './DocGenerator'

let LAST_KALEM_ID = DEFAULT_KALEMLER.length + 1

// import Component
const TemelBilgilerFormContent = props => {
  return (
    <>
      <Row>
        <Col>
          <h4 className="card-title mb-4">Proje Temel Bilgileri</h4>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm="6">
          <Label htmlFor="proje_baslangic">Proje Başlangıç Yılı - Ayı</Label>
          <Input id="proje_baslangic" name="proje_baslangic" type="month" className="form-control" />
        </Col>

        <Col sm="6">
          <Label htmlFor="proje_suresi">Proje Süresi(ay)</Label>
          <Input id="proje_suresi" name="proje_suresi" type="number" className="form-control" />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col sm="4">
          <Label htmlFor="calisma_adi_ve_kodu">Çalışma Adı ve Kodu</Label>
          <Input id="calisma_adi_ve_kodu" name="calisma_adi_ve_kodu" type="text" className="form-control" />
        </Col>
        <Col sm="4">
          <Label htmlFor="calisma_icin_belirlenen_tibbi_brans">Çalışma İçin Belirlenen Tıbbi Branş</Label>
          <Input
            id="calisma_icin_belirlenen_tibbi_brans"
            name="calisma_icin_belirlenen_tibbi_brans"
            type="text"
            className="form-control"
          />
        </Col>
        <Col sm="4">
          <Label htmlFor="hafta_sayisi">Merkez Monitorizasyon Aralığı (Hafta)</Label>
          <Input id="hafta_sayisi" name="hafta_sayisi" type="number" className="form-control" />
        </Col>
      </Row>

      <Row>
        <Col sm="3">
          <Label htmlFor="merkez_sayisi">Merkez Sayısı</Label>
          <Input id="merkez_sayisi" name="merkez_sayisi" type="number" className="form-control" />
        </Col>
        <Col sm="3">
          <Label htmlFor="hasta_sayisi">Hedef Hasta Sayısı</Label>
          <Input id="hasta_sayisi" name="hasta_sayisi" type="number" className="form-control" />
        </Col>
        <Col sm="3">
          <Label htmlFor="hasta_basina_vizit_sayisi">Hasta Başına Vizit Sayısı</Label>
          <Input
            id="hasta_basina_vizit_sayisi"
            name="hasta_basina_vizit_sayisi"
            type="number"
            className="form-control"
          />
        </Col>

        <Col sm="3">
          <Label htmlFor="para_birimi">Para Birimi</Label>
          <select id="para_birimi" name="para_birimi" className="form-control">
            <option>...</option>
            <option value="₺">₺ - TL</option>
            <option value="$">$ - DOLAR</option>
            <option value="€">€ - EURO</option>
          </select>
        </Col>
      </Row>

      <br />
    </>
  )
}

const SelectMonthComponent = props => {
  /*
    ############################################################################

    Initil year and months

    ############################################################################
  */
  let year_start = props.baslangic_yil
  let year_count = props.proje_suresi_yil

  let all_years = []
  for (let i = 0; i < year_count; i++) {
    all_years.push(year_start + i)
  }
  const all_months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const get_month_full_name = month => {
    let names = {
      1: 'Ocak',
      2: 'Şubat',
      3: 'Mart',
      4: 'Nisan',
      5: 'Mayıs',
      6: 'Haziran',
      7: 'Temmuz',
      8: 'Ağustos',
      9: 'Eylül',
      10: 'Ekim',
      11: 'Kasım',
      12: 'Aralık',
    }
    return names[month]
  }

  /*
    ############################################################################

    Month collapse things

    ############################################################################
  */

  const get_all_uncollapsed = () => {
    let initial_year_collapse = []
    for (let i = 0; i < year_count; i++) {
      initial_year_collapse.push(false)
    }
    return initial_year_collapse
  }

  const [year_collapse_data, set_year_collapse_data] = useState(get_all_uncollapsed())
  const get_is_year_collapsed = year => {
    return year_collapse_data[year - year_start]
  }
  const select_for_year_collapse = year => {
    let before = year_collapse_data[year - year_start]
    let initial = get_all_uncollapsed()
    initial[year - year_start] = !before
    set_year_collapse_data(initial)
  }

  /*
    ############################################################################

    Selected Months THings

    ############################################################################
  */

  const get_initial_selected_months = () => {
    for (let surec of props.nested_surecler) {
      if (surec.id == props.month_selected_kalem.surec_id) {
        for (let kategori of surec.kategoriler) {
          if (kategori.id == props.month_selected_kalem.kategori_id) {
            for (let kalem of kategori.kalemler) {
              if (kalem.id == props.month_selected_kalem.kalem_id) {
                return kalem.months
              }
            }
          }
        }
      }
    }
    return []
  }

  const [selected_months, set_selected_months] = useState(get_initial_selected_months())

  const toggle_selected_month = (year, month) => {
    let all = [...selected_months]
    let found_year = all.find(data => data.year == year)

    if (found_year == undefined) {
      all.push({ year: year, months: [month] })
    } else {
      let founded = all.find(data => data.year == year && data.months.includes(month))

      if (founded == undefined) {
        found_year.months.push(month)
      } else {
        found_year.months = found_year.months.filter(elem => {
          return elem != month
        })
      }
    }
    set_selected_months(all)
  }

  useEffect(() => {
    props.set_month_function(
      props.month_selected_kalem.surec_id,
      props.month_selected_kalem.kategori_id,
      props.month_selected_kalem.kalem_id,
      selected_months
    )
  }, [selected_months])

  /*
    ############################################################################

    Render Helpers

    ############################################################################
  */

  const is_month_selected = (year, month) => {
    let founded = selected_months.find(data => data.year == year && data.months.includes(month))
    if (founded == undefined) {
      return false
    }
    return true
  }

  const get_year_total_selected_count_render = year => {
    let founded = selected_months.find(data => data.year == year)
    if (founded == undefined) {
      return <></>
    }
    if (founded.months.length == 0) {
      return <></>
    }
    return <span> - ({founded.months.length})</span>
  }

  /*
  ############################################################################

  Render

  ############################################################################
*/
  return (
    <>
      <Col>
        <CardTitle className="h4">Ayları Seçiniz </CardTitle>

        <div className="accordion" id="accordion">
          {all_years.map((year, i) => (
            <div className="accordion-item" key={i}>
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !get_is_year_collapsed(year),
                  })}
                  type="button"
                  style={{ cursor: 'pointer' }}
                  onClick={select_for_year_collapse.bind(this, year)}>
                  {year} {get_year_total_selected_count_render(year)}
                </button>
              </h2>

              <Collapse isOpen={get_is_year_collapsed(year)} className="accordion-collapse">
                <div className="accordion-body">
                  <div className="text-muted">
                    {all_months.map(month => (
                      <button
                        className={classnames('btn m-1', {
                          'btn-primary': is_month_selected(year, month),
                          'btn-secondary': !is_month_selected(year, month),
                        })}
                        onClick={toggle_selected_month.bind(this, year, month)}>
                        {get_month_full_name(month)}
                      </button>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </Col>
    </>
  )
}

const Index = props => {
  /*
    ############################################################################

    SAYFA KONTROL

    ############################################################################
  */
  const [is_page_temel_bilgiler, set_is_page_temel_bilgiler] = useState(true) // debug

  /*
    ############################################################################

    TEMEL BILILER RELATED THINGS

    ############################################################################
  */
  const [temel_bilgiler, set_temel_bilgiler] = useState({
    paroje_baslangic_yili: 2000,
    proje_baslangic_ay: 1, // ocak 1
    proje_suresi: 2,
    merkez_sayisi: 3,
    hasta_sayisi: 4,
    hasta_basina_vizit_sayisi: 5,
    calisma_adi_ve_kodu: 'CALISMA-XYZ',
    calisma_icin_belirlenen_tibbi_brans: 'Tıbbi Brans',
    hafta_sayisi: 2,
    para_birimi: '$',
  })

  const handle_temel_bilgiler_form = _ => {
    let baslangic_yil_ay = document.querySelector('#proje_baslangic').value.split('-')
    let x = {
      paroje_baslangic_yili: parseInt(baslangic_yil_ay[0]),
      proje_baslangic_ay: parseInt(baslangic_yil_ay[1]),
      proje_suresi: parseInt(document.querySelector('#proje_suresi').value),
      merkez_sayisi: parseInt(document.querySelector('#merkez_sayisi').value),
      hasta_sayisi: parseInt(document.querySelector('#hasta_sayisi').value),
      calisma_icin_belirlenen_tibbi_brans: document.querySelector('#calisma_icin_belirlenen_tibbi_brans').value,
      calisma_adi_ve_kodu: document.querySelector('#calisma_adi_ve_kodu').value,
      hasta_basina_vizit_sayisi: parseInt(document.querySelector('#hasta_basina_vizit_sayisi').value),
      hafta_sayisi: parseInt(document.querySelector('#hafta_sayisi').value),
      para_birimi: document.querySelector('#para_birimi').value,
    }
    if (
      Number.isNaN(x.paroje_baslangic_yili) ||
      Number.isNaN(x.proje_baslangic_ay) ||
      Number.isNaN(x.proje_suresi) ||
      Number.isNaN(x.merkez_sayisi) ||
      Number.isNaN(x.hasta_sayisi) ||
      !x.calisma_icin_belirlenen_tibbi_brans ||
      !x.calisma_adi_ve_kodu ||
      Number.isNaN(x.hafta_sayisi) ||
      Number.isNaN(x.hasta_basina_vizit_sayisi) ||
      x.para_birimi === '...'
    ) {
      alert('Tüm alanları doldurun.')
      alert(x.calisma_icin_belirlenen_tibbi_brans)
      alert(x.calisma_adi_ve_kodu)
    } else {
      set_is_page_temel_bilgiler(false)
      set_temel_bilgiler(x)
    }
  }

  /*
    ############################################################################

    Initial Süreç olayları

    ############################################################################
  */

  const carpan_of_kategori = kategori_id => {
    return DEFAULT_CARPANLAR.filter(c => c.kategori_id === kategori_id)
  }

  const calculate_initial_surecler = () => {
    let result = []
    for (let surec of DEFAULT_SURECLER) {
      let surec_data = { ...surec, kategoriler: [] }
      for (const kategori of DEFAULT_KATEGORILER.filter(c => c.surec_id === surec.id)) {
        let kategori_data = { ...kategori, kalemler: [] }
        for (const kalem of DEFAULT_KALEMLER.filter(c => c.kategori_id === kategori.id)) {
          let kalem_data = { ...kalem, renameable: false, carpanlar: [], months: [] } // a month= {year:2020, months:[1,2,3,]}
          for (const carpan of carpan_of_kategori(kategori.id)) {
            kalem_data.carpanlar.push({
              ...carpan,
              value: temel_bilgiler[carpan.pre_defination] ? temel_bilgiler[carpan.pre_defination] : 0, // debug
            })
          }
          kategori_data.kalemler.push(kalem_data)
        }
        surec_data.kategoriler.push(kategori_data)
      }
      result.push(surec_data)
    }
    return result
  }

  /*
    ############################################################################

    Süreç Helper functions

    ############################################################################
  */

  const add_new_kalem = (surec_id, category_id) => {
    let copy_of_nested = [...nested_surecler]

    for (let surec of copy_of_nested) {
      if (surec.id === surec_id) {
        for (let kategori of surec.kategoriler) {
          if (kategori.id === category_id) {
            let kalem_data = {
              id: LAST_KALEM_ID++,
              name: '',
              kategori_id: kategori.id,
              renameable: true,
              carpanlar: [],
            }
            for (const carpan of carpan_of_kategori(kategori.id)) {
              kalem_data.carpanlar.push({
                ...carpan,
                value: temel_bilgiler[carpan.pre_defination] ? temel_bilgiler[carpan.pre_defination] : 0,
              })
            }
            kategori.kalemler.unshift(kalem_data)
          }
        }
      }
    }
    set_nested_surecler(copy_of_nested)
  }

  const set_carpan_value = (surec_id, kategori_id, kalem_id, carpan_id, new_value) => {
    let copy_of_nested = [...nested_surecler]
    for (let surec of copy_of_nested) {
      if (surec.id === surec_id) {
        for (let kategori of surec.kategoriler) {
          if (kategori.id === kategori_id) {
            for (let kalem of kategori.kalemler) {
              if (kalem.id === kalem_id) {
                for (let i = 0; i < kalem.carpanlar.length; i++) {
                  if (kalem.carpanlar[i].id === carpan_id) {
                    kalem.carpanlar[i].value = new_value
                  }
                }
              }
            }
          }
        }
      }
    }
    set_nested_surecler(copy_of_nested)
  }

  const set_kalem_name = (surec_id, kategori_id, kalem_id, new_value) => {
    let copy_of_nested = [...nested_surecler]
    for (let surec of copy_of_nested) {
      if (surec.id === surec_id) {
        for (let kategori of surec.kategoriler) {
          if (kategori.id === kategori_id) {
            for (let i = 0; i < kategori.kalemler.length; i++) {
              if (kategori.kalemler[i].id === kalem_id) {
                kategori.kalemler[i].name = new_value
              }
            }
          }
        }
      }
    }

    set_nested_surecler(copy_of_nested)
  }

  const set_kalem_months = (surec_id, kategori_id, kalem_id, kalem_months) => {
    let copy_of_nested = [...nested_surecler]
    for (let surec of copy_of_nested) {
      if (surec.id === surec_id) {
        for (let kategori of surec.kategoriler) {
          if (kategori.id === kategori_id) {
            for (let i = 0; i < kategori.kalemler.length; i++) {
              if (kategori.kalemler[i].id === kalem_id) {
                kategori.kalemler[i].months = kalem_months
              }
            }
          }
        }
      }
    }

    set_nested_surecler(copy_of_nested)
  }

  /*
    ############################################################################

    Süreç States

    ############################################################################
  */

  // TODO:make it empty list
  const [nested_surecler, set_nested_surecler] = useState(calculate_initial_surecler())
  useEffect(() => {
    set_nested_surecler(calculate_initial_surecler())
  }, [temel_bilgiler])

  /*
    ############################################################################

    Totals

    ############################################################################
  */

  // const [kategori_totals, set_kategori_totals] = useState([])
  // const calculate_kategori_totals = () => {
  //   let new_kategori_totals = []
  //   for (let surec of nested_surecler) {
  //     for (let kategori of surec.kategoriler) {
  //       new_kategori_totals.push({
  //         ...kategori,
  //         total: get_node_total(kategori),
  //       })
  //     }
  //   }
  //   set_kategori_totals(new_kategori_totals)
  // }

  const [kalem_totals, set_kalem_totals] = useState([])

  useEffect(() => {
    calculate_kalem_totals()
  }, [nested_surecler])

  const get_node_total = node => {
    let total = 0
    if (node.kategoriler) {
      for (let k of node.kategoriler) {
        total += get_node_total(k)
      }
    } else if (node.kalemler) {
      for (let k of node.kalemler) {
        total += get_node_total(k)
      }
    } else if (node.carpanlar) {
      let carpim = 1
      for (let k of node.carpanlar) {
        carpim *= k.value
      }

      return carpim
    }

    return total
  }

  const calculate_kalem_totals = () => {
    let new_kalem_totals = []

    for (let surec of nested_surecler) {
      for (let kategori of surec.kategoriler) {
        for (let kalem of kategori.kalemler) {
          new_kalem_totals.push({
            id: kalem.id,
            total: get_node_total(kalem),
          })
        }
      }
    }
    set_kalem_totals(new_kalem_totals)
  }

  const get_kalem_total = kalem_id => {
    let k = kalem_totals.find(kalem => kalem.id === kalem_id)

    if (k && k.total) {
      return k.total
    } else {
      return 0
    }
  }

  const get_kalem_total_value = kalem_id => {
    let total = get_kalem_total(kalem_id)

    if (total == 0) {
      return ''
    } else {
      return `${temel_bilgiler.para_birimi} ${total}`
    }
  }

  /*
    ############################################################################

    Tab things

    ############################################################################
  */

  const [activeTab, setactiveTab] = useState(1)

  const change_active_tab = activeTab => {
    setactiveTab(activeTab)
    window.scrollTo(0, 0)
  }

  /*
    ############################################################################

    Date pick modal things

    ############################################################################
  */

  const [is_month_modal, set_is_month_modal] = useState(false)
  const toggle_is_month_modal = () => {
    set_is_month_modal(!is_month_modal)
  }

  const [month_selected_kalem, set_month_selected_kalem] = useState({
    surec_id: 1,
    kategori_id: 1,
    kalem_id: 1,
  })


  // const set_kalem_months = (surec_id, kategori_id, kalem_id, kalem_months) => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - Bilgilendirme</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Fiziblite" breadcrumbItem="Ön Fiziblite" />

          {is_page_temel_bilgiler ? (
            <div className="justify-content-center mt-lg-5 row">
              <div className="d-xl-flex col-sm-10 col-xl-8">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <Form id="temel_bilgiler_form">
                        <TemelBilgilerFormContent />
                        <Row>
                          <Col>
                            <Button
                              type="button"
                              color="primary"
                              onClick={handle_temel_bilgiler_form}
                              style={{ float: 'right' }}>
                              Ön Fizibilite Formuna Git
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        {nested_surecler.map(surec => (
                          <NavItem className={classnames({ current: activeTab === surec.id })}>
                            <NavLink
                              className={classnames({ current: activeTab === surec.id })}
                              onClick={() => {
                                change_active_tab(surec.id)
                              }}>
                              <span className="number">{surec.id}.</span> {surec.name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {nested_surecler
                    .filter(surec => surec.id == activeTab)
                    .map(surec => {
                      let ret = []

                      surec.kategoriler.map(kategori => {
                        if (kategori.kalemler.length === 0) {
                          ret.push(
                            // kalemleri olmayan kategoriler için
                            <Row key={`kategori-${kategori.id}`} className="mt-5">
                              <Col sm={3}>
                                <Row>
                                  <Col>
                                    {/* kategori_id: {kategori.id} {/\* debug *\/} */}
                                    <label
                                      key={`kategori-${kategori.id}`}
                                      className="col-form-label"
                                      style={{
                                        'line-height': 15,
                                        'white-space': 'nowrap',
                                      }}>
                                      {kategori.name}
                                      {'  '}
                                      <i
                                        className="bx bx-plus-circle label text-primary d-inline-block mb-0"
                                        style={{ cursor: 'pointer' }}
                                        onClick={add_new_kalem.bind(this, surec.id, kategori.id)}></i>
                                    </label>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )
                        }

                        kategori.kalemler.map((kalem, index) => {
                          ret.push(
                            <Row key={`kategori-${kategori.id}-kalem-${kalem.id}`} className={index != 0 ? '' : 'mt-5'}>
                              <Col sm={3}>
                                <Row>
                                  <Col>
                                    {/* kategori baŞlığı eğer kategori varsa */}
                                    {index != 0 ? (
                                      <label
                                        key={`kategori-${kategori.id}`}
                                        className="col-form-label"
                                        style={{
                                          color: 'rgba(0, 0, 255, 0)',
                                          'line-height': 15,
                                        }}>{`.`}</label>
                                    ) : (
                                      <label
                                        key={`kategori-${kategori.id}`}
                                        className="col-form-label"
                                        style={{
                                          'line-height': 15,
                                          'white-space': 'nowrap',
                                        }}>
                                        <i
                                          className="bx bx-plus-circle label text-primary d-inline-block mb-0"
                                          style={{ cursor: 'pointer' }}
                                          onClick={add_new_kalem.bind(this, surec.id, kategori.id)}></i>
                                        {'  '}
                                        {/* kategori_id: {kategori.id} -- {/\* TODO debug *\/} */}
                                        {kategori.name}
                                      </label>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <div
                                    className="col-auto"
                                    style={{
                                      padding: 0,
                                      paddingLeft: 'calc(var(--bs-gutter-x) / 2)',
                                    }}>
                                    <div className="d-flex align-items-center" style={{ height: '100%' }}>
                                      <i
                                        className="mdi inline mdi-calendar justify-content-center "
                                        style={{ fontSize: 15, cursor: 'pointer' }}
                                        onClick={() => {
                                          set_month_selected_kalem({
                                            surec_id: surec.id,
                                            kategori_id: kategori.id,
                                            kalem_id: kalem.id,
                                          })
                                          set_is_month_modal(false)
                                          set_is_month_modal(true)
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <Col style={{ paddingLeft: 5 }}>
                                    {/* kalem_id: {kalem.id} {/\* TODO debug *\/} */}
                                    <Input
                                      type="text"
                                      className="form-control"
                                      disabled={!kalem.renameable}
                                      placeholder={'Kalem İsmi'}
                                      onChange={e => {
                                        set_kalem_name.bind(this, surec.id, kategori.id, kalem.id)(e.target.value)
                                      }}
                                      value={kalem.name}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={7}>
                                <Row>
                                  {kalem.carpanlar.map(carpan => (
                                    <Col sm={3}>
                                      <Row>
                                        <Col>
                                          <label
                                            className="col-form-label"
                                            style={{
                                              'font-size': Math.min(220 / carpan.name.length, 11.5),
                                              'line-height': 15,
                                              'white-space': 'nowrap',
                                            }}>
                                            {carpan.name}
                                          </label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {/* carpan_id: {carpan.id} {/\* TODO debug *\/} */}
                                          <Input
                                            type="number"
                                            className="form-control"
                                            onChange={e => {
                                              set_carpan_value.bind(
                                                this,
                                                surec.id,
                                                kategori.id,
                                                kalem.id,
                                                carpan.id
                                              )(e.target.value)
                                            }}
                                            value={carpan.value == 0 ? `` : carpan.value}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                              <Col sm={2}>
                                <Row>
                                  <Col>
                                    <Row>
                                      <Col>
                                        <label className="col-form-label" style={{ 'line-height': 15 }}>
                                          Toplam Tutar
                                        </label>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Input
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          value={get_kalem_total_value(kalem.id)}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )
                        })
                      })

                      return ret
                    })}

                  <Row className="mt-4">
                    <Col>
                      {activeTab > 1 ? (
                        <Button type="button" color="primary" onClick={change_active_tab.bind(this, activeTab - 1)}>
                          {nested_surecler.find(surec => surec.id == activeTab - 1).name}
                          'Sayfasına Dön
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          color="secondary"
                          onClick={change_active_tab.bind(this, activeTab - 1)}
                          disabled={true}>
                          Geri
                        </Button>
                      )}
                    </Col>
                    <Col>
                      {activeTab < nested_surecler.length ? (
                        <Button
                          type="button"
                          color="primary"
                          onClick={change_active_tab.bind(this, activeTab + 1)}
                          style={{ float: 'right' }}>
                          {nested_surecler.find(surec => surec.id == activeTab + 1).name}
                          'Sayfasına Geç
                        </Button>
                      ) : (
                        <></>
                        /* <div style={{ float: 'right' }}> */
                        /*   <DocGenerator */
                        /*     surecler={nested_surecler} */
                        /*     kalem_totals={kalem_totals} */
                        /*   /> */
                        /* </div> */
                      )}
                      {/* TODO: move up */}
                      <div style={{ float: 'right' }}>
                        <DocGenerator
                          surecler={nested_surecler}
                          kalem_totals={parseInt(kalem_totals)}
                          para_birimi={temel_bilgiler.para_birimi}
                          proje_suresi_yil={parseInt(temel_bilgiler.proje_suresi / 12 + 2)}
                          calisma_icin_belirlenen_tibbi_brans={temel_bilgiler.calisma_icin_belirlenen_tibbi_brans}
                          calisma_adi_ve_kodu={temel_bilgiler.calisma_adi_ve_kodu}
                          hasta_sayisi={parseInt(temel_bilgiler.hasta_sayisi)}
                          merkez_sayisi={parseInt(temel_bilgiler.merkez_sayisi)}
                          hafta_sayisi={parseInt(temel_bilgiler.hafta_sayisi)}
                          hasta_basina_vizit_sayisi={parseInt(temel_bilgiler.hasta_basina_vizit_sayisi)}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
      </div>

      {/*
      /////////////////
      Date Pick Modal
      /////////////////
      */}

      <Modal isOpen={is_month_modal} toggle={toggle_is_month_modal}>
        <ModalHeader toggle={toggle_is_month_modal}>Ay Seç</ModalHeader>
        <ModalBody>
          <SelectMonthComponent
            nested_surecler={nested_surecler}
            set_month_function={set_kalem_months}
            month_selected_kalem={month_selected_kalem}
            baslangic_yil={temel_bilgiler.paroje_baslangic_yili}
            proje_suresi_yil={parseInt(temel_bilgiler.proje_suresi / 12 + 2)}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default Index
