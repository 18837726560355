import {
  GET_SUREC_TAKIPLER,
  SET_PROCESS_FOLLOW,
  DELETE_PROCESS_FOLLOW,
  NEW_PROCESS_FOLLOW,
  GET_PATIENT_GROUP,
  SET_PATIENT_GROUP,
  NEW_VISIT_FOLLOW,
  GET_VISIT_FOLLOW,
} from "./actionTypes"

import {
  getSurecTakiplerSuccessAction,
  getSurecTakiplerAction,
  getPatientGroupSuccessAction,
  getPatientGroupAction,
  getVisitFollowAction,
  getVisitFollowSuccessAction,
} from "./actions"

import {
  globalFailAction,
} from "../bilgilendirme/actions"

import {
  closeModalAction,
} from "../planlama-ve-takvim/actions"


import { call, put, takeEvery } from "redux-saga/effects"

import {
  getSurecTakiplerRequest,
  setProcessFollowRequest,
  deleteProcessFollowRequest,
  newProcessFollowRequest,
  getPatientGroupRequest,
  setChangedGroupRequest,
  newVisitFollowRequest,
    getVisitFollowRequest,
} from "../../helpers/backend"


function* getSurecTakipler({ payload }) {
  try {
    const response = yield call(
      getSurecTakiplerRequest,
      payload.project_id,
    )
    yield put(getSurecTakiplerSuccessAction(payload.project_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* setProcessFollow({ payload }) {
  try {
    const response = yield call(
      setProcessFollowRequest,
      payload.follow_id,
      payload.name,
      payload.value,
    )
    yield put(getSurecTakiplerAction(payload.project_id))
    yield put(closeModalAction(true))
  }

  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* deletedProcessFollow({ payload }) {
  try {
    const response = yield call(deleteProcessFollowRequest, payload.follow_id)
    yield put(getSurecTakiplerAction(payload.project_id))
  } catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newProcessFollow({ payload }) {
  try {
    yield call(newProcessFollowRequest, payload.name, payload.project_id, payload.category_id)
    yield put(getSurecTakiplerAction(payload.project_id))
    yield put(closeModalAction(true))
  }
  catch (error){
    yield put(globalFailAction(error.message))
  }
}

function* getPatientGroup({payload}){
  try{
    const response = yield call( //TODO
      getPatientGroupRequest,
      payload.project_id,
    )
    yield put(getPatientGroupSuccessAction(payload.project_id, response))
  }catch(error){
    yield put(globalFailAction(error.message))
  }
}


function* setPatientGroup({ payload }) {
  try {
    yield call(
      setChangedGroupRequest,
      payload.patient_group_id,
      payload.changed_vals,
    )
    yield put(getPatientGroupAction(payload.project_id))
  }

  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newVisitFollow({payload}){
  try {
    yield call(
      newVisitFollowRequest,
      payload.project_id,
      payload.center_id,
    )
    yield put(getVisitFollowAction(payload.project_id))
  }

  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* getVisitFollow({payload}){
  try{
    const response = yield call(
      getVisitFollowRequest,
      payload.project_id,
    )
    yield put(getVisitFollowSuccessAction(payload.project_id, response))
  }catch(error){
    yield put(globalFailAction(error.message))
  }
}

function* operasyonelSaga() {
  yield takeEvery(GET_SUREC_TAKIPLER, getSurecTakipler)
  yield takeEvery(SET_PROCESS_FOLLOW, setProcessFollow)
  yield takeEvery(DELETE_PROCESS_FOLLOW, deletedProcessFollow)
  yield takeEvery(NEW_PROCESS_FOLLOW, newProcessFollow)
  yield takeEvery(GET_PATIENT_GROUP, getPatientGroup)
  yield takeEvery(SET_PATIENT_GROUP, setPatientGroup)
  yield takeEvery(NEW_VISIT_FOLLOW, newVisitFollow)
  yield takeEvery(GET_VISIT_FOLLOW, getVisitFollow)
}


export default operasyonelSaga;
