import { error } from "toastr"
import {
  CLEAR_ERROR_MESSAGE,
  GET_PROJECTS_FOLDER_SUCCESS,
  GET_FOLDERS_FILES_SUCCESS,
  GET_WORKFLOW_SUCCESS,
  GET_WORKFLOW_FAILED,
  FORM_DONE,
  GET_PROJECTS_FOLDER,
} from "./actionTypes"

const INIT_STATE = {
  folders: [],
  files: [],
  workflows: [],
  form_done: false,
  errorMessage: "",
  showWorkflowPane: false,
}

const contacts = (state = INIT_STATE, action) => {
  let found = false

  switch (action.type) {
    case GET_WORKFLOW_FAILED:
      return {
        ...state,
        errorMessage: action.payload.error.msg,
      }
    case GET_WORKFLOW_SUCCESS:

      return {
        ...state,
        workflows: action.payload.workflows,
        showWorkflowPane: true,
        errorMessage: "",
      }
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      }
    case GET_PROJECTS_FOLDER_SUCCESS:

      let new_folders = []
      state.folders.forEach(folder => {
        if (folder.project_id === action.payload.project_id) {
          new_folders.push(action.payload)
          found = true
        }
        else {
          new_folders.push(folder)
        }
      })
      if (found === false) {
        new_folders.push(action.payload)
      }

      return {
        ...state,
        folders: new_folders,
        error: "",
      }
    case GET_FOLDERS_FILES_SUCCESS:
      let files = action.payload.files
      let folder_id = action.payload.folder_id
      let new_files = []

      state.files.forEach(file => {
        let old_folder_id = file.folder_id
        if (folder_id === old_folder_id) {
          found = true
          new_files.push(action.payload)
        } else {
          new_files.push(file)
        }
      })
      if (found === false) {
        new_files.push(action.payload)
      }

      return {
        ...state,
        files: new_files,
        error: "",
        showWorkflowPane: false,
      }


    case FORM_DONE:
      return {
        ...state,
        error: "",
        forum_done: action.payload
      }

    default:
      return state
  }
}

export default contacts
