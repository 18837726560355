import { act } from "react-dom/test-utils"
import {
  GET_PROJECTS_VISITS_SUCCESS,
  GET_PROJECTS_MONITORIZATIONS_SUCCESS,
  GET_PROJECTS_CENTERS_SUCCESS,
  CLOSE_MODAL,
  GET_CENTERS_DEVICES_SUCCESS,
  GET_CENTERS_PAYMENTS_SUCCESS,
  GET_CENTERS_PAYMENTS_FLOW,
  GET_CENTERS_PAYMENTS_FLOW_SUCCESS,
  NEW_PROFORMA,
  NEW_PROFORMA_SUCCESS,
  NEW_PAY_FLOW,
  NEW_PAY_FLOW_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  projects_visits: {},
  projects_monitorizations: {},
  projects_centers: {},
  centers_devices: {},
  centers_payments: {},
  payment_flows: {},
  proformas: {},
  flow_status: {},
  close_modal: false,
}

const contacts = (state = INIT_STATE, action) => {
  let new_state = { ...state }

  switch (action.type) {

    case GET_CENTERS_PAYMENTS_FLOW_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        payment_flows: action.payload.payment_flows,
      }

    case NEW_PAY_FLOW_SUCCESS:
      new_state.flow_status = action.payload.payment_flows
      return new_state
    case NEW_PROFORMA_SUCCESS:
      new_state.proformas = action.payload.response
      new_state.flow_status = {}
      return new_state

    case GET_PROJECTS_VISITS_SUCCESS:
      let new_projects_visits = { ...new_state.projects_visits }
      new_projects_visits[action.payload.project_id] = action.payload.visit
      new_state.projects_visits = new_projects_visits
      return new_state

    case GET_PROJECTS_MONITORIZATIONS_SUCCESS:
      let new_projects_monitorizations = { ...new_state.projects_monitorizations }
      new_projects_monitorizations[action.payload.project_id] = action.payload.monitorization
      new_state.projects_monitorizations = new_projects_monitorizations
      return new_state

    case GET_PROJECTS_CENTERS_SUCCESS:
      let new_projects_centers = { ...new_state.projects_centers }
      new_projects_centers[action.payload.project_id] = action.payload.center
      new_state.projects_centers = new_projects_centers
      return new_state

    case GET_CENTERS_DEVICES_SUCCESS:
      let new_centers_devices = { ...new_state.centers_devices }
      new_centers_devices[action.payload.center_id] = action.payload.devices
      new_state.centers_devices = new_centers_devices
      return new_state

    case GET_CENTERS_PAYMENTS_SUCCESS:
      let new_centers_payments = { ...new_state.centers_payments }
      new_centers_payments[action.payload.center_id] = action.payload.payments
      new_state.centers_payments = new_centers_payments
      return new_state

    case CLOSE_MODAL:
      return { ...state, close_modal: action.payload.close }

    default:
      return state
  }
}

export default contacts
