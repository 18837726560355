import MetaTags from 'react-meta-tags'

import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

import { Col, Row, Input, CardTitle } from 'reactstrap'
import { Card, CardBody, Container } from 'reactstrap'
import { getPatientGroupAction, setPatientGroupAction } from '../../store/actions'

const APP = props => {
  // HERE

  const dispatch = useDispatch()

  // STORE
  const { projeler, patient_group } = useSelector(state => ({
    projeler: state.contacts.projeler,
    patient_group: state.OperasyonelTakip.patient_group,
  }))

  // States
  const [current_proje, setCurrentProje] = useState({})
  const [current_patient_group, set_current_patient_group] = useState({})

  useEffect(() => {
    if (patient_group) {
      set_current_patient_group(patient_group)
    }
  }, [patient_group, current_proje])

  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  useEffect(() => {
    if (current_proje !== undefined && current_proje.id !== undefined) {
      dispatch(getPatientGroupAction(current_proje.id))
    }
  }, [current_proje])

  const set_current_patient_groups_value = (center_id, key, value) => {
    let new_one = { ...current_patient_group }

    new_one[center_id][key] = value
    set_current_patient_group(new_one)
    set_kaydet_enabled(true)
  }

  const handle_save = () => {
    Object.keys(current_patient_group).map(center_id => {
      dispatch(
        setPatientGroupAction(current_patient_group[center_id].id, current_proje.id, {
          screened_count: current_patient_group[center_id].screened_count,
          leaving_count: current_patient_group[center_id].leaving_count,
          followed_count: current_patient_group[center_id].followed_count,
          continuing_count: current_patient_group[center_id].continuing_count,
        })
      )
    })
    set_kaydet_enabled(false)
  }

  // States
  const [kaydet_enabled, set_kaydet_enabled] = useState(false)

  ///

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - HastaGruplari</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Hasta Grupları"
            /* TODO  */
            breadcrumbItem={current_proje ? current_proje.name : ''}
          />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                {/* FileRightBar  */}

                <div className="w-100">
                  <Card>
                    <CardBody>
                      {current_patient_group ? (
                        <React.Fragment>
                          {Object.keys(current_patient_group).map(center_id => (
                            <div className="mb-5">
                              <Row>
                                <Col>
                                  <CardTitle className="h4">{current_patient_group[center_id].center_name}</CardTitle>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <label className="col-md-4 col-form-label">Taranan Hasta Sayısı</label>
                                <Col md={2}>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    value={current_patient_group[center_id].screened_count}
                                    onChange={e => {
                                      set_current_patient_groups_value(center_id, 'screened_count', e.target.value)
                                    }}
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <label className="col-md-4 col-form-label">Ayrılan Hasta Sayısı</label>
                                <Col md={2}>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    value={current_patient_group[center_id].leaving_count}
                                    onChange={e => {
                                      set_current_patient_groups_value(center_id, 'leaving_count', e.target.value)
                                    }}
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <label className="col-md-4 col-form-label">Takip Edilen Hasta Sayısı</label>
                                <Col md={2}>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    value={current_patient_group[center_id].followed_count}
                                    onChange={e => {
                                      set_current_patient_groups_value(center_id, 'followed_count', e.target.value)
                                    }}
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <label className="col-md-4 col-form-label">Devam Edilen Hasta Sayısı</label>
                                <Col md={2}>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    value={current_patient_group[center_id].continuing_count}
                                    onChange={e => {
                                      set_current_patient_groups_value(center_id, 'continuing_count', e.target.value)
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <button
                            className="btn btn-success"
                            type="button"
                            id="inputGroupFileAddon03"
                            onClick={handle_save}
                            disabled={!kaydet_enabled}>
                            Kaydet
                          </button>
                        </React.Fragment>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default APP
