import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECTS_USERS,
  GET_PROJECTS_USERS_FAIL,
  GET_PROJECTS_USERS_SUCCESS,
  ADD_USER_TO_PROJECT,
  ADD_USER_TO_PROJECT_SUCCESS,
  ADD_USER_TO_PROJECT_FAIL,
  ADD_USER_TO_PROJECT_LAST_RESPONSE,
  ADD_USER_TO_PROJECT_LAST_RESPONSE_HANDLED,
  REMOVE_USER_TO_PROJECT,
  REMOVE_USER_TO_PROJECT_SUCCESS,
  REMOVE_USER_TO_PROJECT_FAIL,
  REMOVE_USER_TO_PROJECT_LAST_RESPONSE,
  REMOVE_USER_TO_PROJECT_LAST_RESPONSE_HANDLED,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_RESET_RESULT,
  CREATE_NEW_USER_FAIL,
  GET_PROJECT_BUDGET_ABSTRACT,
  GET_PROJECT_BUDGET_ABSTRACT_SUCCESS,
} from "./actionTypes"

export const createNewUser = (new_user_data) => ({
  type: CREATE_NEW_USER,
  payload: new_user_data,
})

export const createNewUserResetResult = () => ({
  type: CREATE_NEW_USER_RESET_RESULT,
  payload: true,
})

export const createNewUserSuccess = (response_data) => ({
  type: CREATE_NEW_USER_SUCCESS,
  payload: response_data,
})

export const createNewUserFail = fail_data => ({
  type: CREATE_NEW_USER_FAIL,
  payload: fail_data,
})

export const getProjects = () => ({
  type: GET_PROJECTS,
})

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const addNewProject = project => ({
  type: ADD_NEW_PROJECT,
  payload: project,
})

export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
})

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const getProjectUsers = project => ({
  type: GET_PROJECTS_USERS,
  payload: project,
})

export const getProjectUsersSuccess = (project, users) => {
  return {
    type: GET_PROJECTS_USERS_SUCCESS,
    payload: {
      id: project.id,
      users: users,
    },
  }
}

export const getProjectUsersFail = error => {
  return {
    type: GET_PROJECTS_USERS_FAIL,
    payload: error,
  }
}

export const addUserToProject = (project_id, user_mail) => {
  return {
    type: ADD_USER_TO_PROJECT,
    payload: {
      user_mail: user_mail,
      project_id: project_id,
    }
  }
}
export const removeUserToProject = (project_id, user_id) => {
  return {
    type: REMOVE_USER_TO_PROJECT,
    payload: {
      user_id: user_id,
      project_id: project_id,
    }
  }
}

export const addUserToProjectSuccess = response => ({
  type: ADD_USER_TO_PROJECT_SUCCESS,
  payload: response,
})
export const removeUserToProjectSuccess = response => ({
  type: REMOVE_USER_TO_PROJECT_SUCCESS,
  payload: response,
})

export const addUserToProjectFail = error => ({
  type: ADD_USER_TO_PROJECT_FAIL,
  payload: error,
})

export const removeUserToProjectFail = error => ({
  type: REMOVE_USER_TO_PROJECT_FAIL,
  payload: error,
})

export const addUserToProjectLastResponse = (response) => ({
  type: ADD_USER_TO_PROJECT_LAST_RESPONSE,
  payload: response
})

export const removeUserToProjectLastResponse = (response) => ({
  type: REMOVE_USER_TO_PROJECT_LAST_RESPONSE,
  payload: response
})

export const addUserToProjectLastResponseHandled = () => ({
  type: ADD_USER_TO_PROJECT_LAST_RESPONSE_HANDLED,
  payload: true
})

export const removeUserToProjectLastResponseHandled = () => ({
  type: REMOVE_USER_TO_PROJECT_LAST_RESPONSE_HANDLED,
  payload: true
})

export const updateUser = project => ({
  type: UPDATE_PROJECT,
  payload: project,
})

export const updateUserSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
})

export const updateUserFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

export const deleteUser = project => ({
  type: DELETE_PROJECT,
  payload: project,
})

export const deleteUserSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteUserFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})
export const getProjectBudgetAbstract = project => ({
  type: GET_PROJECT_BUDGET_ABSTRACT,
  payload: project,
})
export const projectBudgetAbstractSuccess = (response_data) => ({
  type: GET_PROJECT_BUDGET_ABSTRACT_SUCCESS,
  payload: response_data,
})
