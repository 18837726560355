import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  workFlowSet,
} from '../../store/bilgilendirme/actions'
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap'
import * as moment from 'moment'
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'
import { Circles } from 'react-loader-spinner'
import DeleteModal from "./DeleteModal"
const Workflows = props => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)

  const { workflows } = useSelector(state => ({
    workflows: state.Bilgilendirme.workflows
  }))

  const [showLoader, setshowLoader] = useState(false)


  const [show_save_button, setShowSaveButton] = useState(true)


  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
      setUserData(obj)
    }
  }, [props.success])
  const [AuthUserData, setUserData] = useState({})
  const [user_type, setUserType] = useState('DCT')
  const [modalData, modalSetData] = useState({ assigned_user_id: null, id: null, create_datetime: null, delete_wf: false })
  const [new_work_flow, setNewWorkflowModal] = useState(false)

  const set_new_work_flow_toggle = (fc = null, assigned_user_id = null, create_datetime = null, id = null) => {
    setNewWorkflowModal(!new_work_flow)
    modalSetData({
      id: id,
      assigned_user_id: assigned_user_id,
      create_datetime: create_datetime,
    })
  }
  const handleModalUserChange = (event) => {
    modalSetData(modalData => ({
      ...modalData,
      assigned_user_id: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(modalData);
    });
  };
  const handleModalCreateTimeChange = (event) => {
    const { value } = event.target;
    modalSetData(modalData => ({
      ...modalData,
      create_datetime: datetimeToStrws(value)
    }), (modalData) => {
      // This callback is executed after the state has been updated
    });
  };
  useEffect(() => {
    console.log(modalData);
  }, [modalData]); // This effect will run every time modalData changes
  const handle_new_work_flow = (_, input_data) => {
    dispatch(workFlowSet(props.file_id, modalData));
    setNewWorkflowModal(!new_work_flow)
  }
  const datetimeToStr = date => {
    const date1 = moment(new Date(date)).format('DD-MM-YYYY')
    return date1
  }
  const datetimeToStrws = date => {
    const formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
    return formattedDate;
  }

  const setProcessWorkFlow = (id) => {
    modalSetData(modalData => ({
      ...modalData,
      id: id,
      delete_wf: true
    }), (modalData) => {
      console.log(modalData)
      // This callback is executed after the state has been updated
    });
    setDeleteModal(true);
  }
  const handleDeleteEvent = () => {
    console.log("handle")
    dispatch(workFlowSet(props.file_id, modalData));
    // dispatch(onDeleteEvent(event))
    setDeleteModal(false)
    // toggle()
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      {props.showWorkflowPanel ? (
        <>
          <div className="mt-4">
            <div className="d-flex flex-wrap">
              <h5 className="font-size-16 me-3">İş Akışları</h5>

              <div className="ms-auto">
                {(user_type == 'ADM' || user_type == 'PM' || user_type == 'CRA' || user_type == 'SC') ? (
                  <Link to="#" className="fw-medium text-reset" onClick={set_new_work_flow_toggle}>
                    + Yeni İş Akışı
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              <Table className="table align-middle table-nowrap table-hover mb-0 ">
                <thead>
                  <tr>
                    <th scope="col">Kullanıcı</th>
                    <th scope="col">Tarih</th>
                    <th scope="col">İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {workflows.workflows ? (
                    <>
                      {
                        workflows.workflows.length > 0 && props.workflows.users ? (
                          workflows.workflows.map(workflow => (
                            <tr key={workflow.id}>

                              {/* <td>{props.workflows.users.find(user => user.id === workflow.assigned_user_id)?.name}</td> */}
                              <td>{workflow.user_detail.name}({workflow.user_detail.email})</td>
                              <td>{datetimeToStr(workflow.create_datetime)}</td>
                              <td>
                                <div className="d-flex gap-3">

                                  {(user_type == 'ADM' || user_type == 'PM' ||  ((user_type == 'CRA' || user_type == 'SC') && workflow.assigned_user_id==AuthUserData.id)) ? (
                                    <>
                                      <Link className="text-success" to="#">
                                        <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => set_new_work_flow_toggle(this, workflow.assigned_user_id, datetimeToStrws(workflow.create_datetime), workflow.id)}></i>
                                      </Link>
                                      <Link className="text-danger" to="#">
                                        <i className="mdi mdi-delete font-size-18" id="edittooltip" onClick={() => setProcessWorkFlow(workflow.id)}></i>
                                      </Link>
                                    </>

                                  ) : (
                                    <><div>-</div></>
                                  )}
                                  {/* <Link className="text-danger" to="#"> */}
                                  {/*   <i */}
                                  {/*     className="mdi mdi-delete font-size-18" */}
                                  {/*     id="deletetooltip" */}
                                  {/*     onClick={() => handleDeleteUser(user)}></i> */}
                                  {/* </Link> */}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <span>Henüz iş akışı bulunmamaktadır.</span>
                            </td>
                          </tr>
                        )
                      }
                    </>

                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <span>Yükleniyor, lütfen bekleyin...</span>
                      </td>
                    </tr>
                  )}

                </tbody>
              </Table>
            </div>
          </div>
        </>
      ) : null}
      {/*
      /////////////////
      New Work Flow
      /////////////////
      */}
      <Modal isOpen={new_work_flow} toggle={set_new_work_flow_toggle}>
        <ModalHeader toggle={set_new_work_flow_toggle} tag="h4">
          İş Akışı
          {modalData.id ? (
            <span> Düzenle</span>
          ) : (
            <span> Ekle</span>
          )}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handle_new_work_flow}>
            {props.workflows ? (
              <Row form>
                {JSON.stringify(modalData.user_id, null, 2)}
                <Col xs={12}>
                  <div className="mb-3">
                    <AvField
                      name="assigned_user_id"
                      label="Kullanıcı"
                      type="select"
                      onChange={handleModalUserChange}
                      errorMessage="Yanlış Kullanıcı"
                      validate={{
                        required: { value: true },
                      }}
                      value={modalData.assigned_user_id} // Set the value attribute here
                    >
                      <option value="">Lütfen Seçiniz</option>
                      {props.workflows.users ? (
                        props.workflows.users.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value="">None</option>
                      )}
                    </AvField>

                  </div>
                    <AvField
                      name="create_datetime"
                      label="İşlem Tarihi"
                      type="date"
                      errorMessage="Hatalı Tarih"
                      onChange={handleModalCreateTimeChange}
                      format="yyyy-MM-dd"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={modalData.create_datetime ? datetimeToStrws(modalData.create_datetime) : ''} // Pass create_datetime to datetimeToStrws function
                    />
                </Col>
              </Row>
            ) : (
              <p>Saving, please wait...
                <div style={{ "width": "100%" }}>
                  <center>
                    <Circles
                      height="80"
                      width="80"
                      color="#4fa94d"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={showLoader}
                    />
                  </center>
                </div>
              </p>
            )}

            {show_save_button ? (
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-workflow">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
      {/*
                    ///////////////////////////////////////////////////
                                    */}
    </React.Fragment >
  )
}

export default Workflows
