import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projelerSaga from "./projeler/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import bilgilendirmeSaga from "./bilgilendirme/saga";
import planlamaVeTakvimSaga from "./planlama-ve-takvim/saga";
import operasyonelSaga from "./operasyonel_takip/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projelerSaga),
    fork(tasksSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(bilgilendirmeSaga),
    fork(planlamaVeTakvimSaga),
    fork(operasyonelSaga),
  ])
}
