import {
  GET_SUREC_TAKIPLER_SUCCESS,
  GET_PATIENT_GROUP_SUCCESS,
  GET_VISIT_FOLLOW_SUCCESS,
} from "./actionTypes"


/*
{
  "tab_id_XXXX": {
    "category_id_XXXXXX": {
      "category": {
        "id": "",
        "name": "",
        "tab": ""
      },
      "follows": [
        {
          "id": "y",
          "vale": 1
        },
        {
          "id": "z",
          "vale": 2
        },
        {
          "id": "x",
          "vale": 1
        }
      ]
    }
  }
}
*/

const INIT_STATE = {
  process_follows: {},
  patient_group: {},
  visit_follows: [],
}

const contacts = (state = INIT_STATE, action) => {
  let new_state = { ...state }

  switch (action.type) {
    case GET_SUREC_TAKIPLER_SUCCESS:
      new_state.process_follows = {}
      for (const process_follow of action.payload.response.process_follows) {
        let follow_data = {
          "id": process_follow.id,
          "name": process_follow.name,
          "value": process_follow.value,
        }
        let category_data = {
          "id": process_follow.category.id,
          "name": process_follow.category.name,
          "tab": process_follow.category.tab,
        }

        if (process_follow.category.id in new_state.process_follows) {
          new_state.process_follows[process_follow.category.id]["follows"].push(follow_data)
        }
        else {
          new_state.process_follows[process_follow.category.id] = {
            "category": category_data,
            "follows": [follow_data]
          }
        }
      }

      let new_process_follows = {}

      for (const category_id of Object.keys(new_state.process_follows)) {
        let value = new_state.process_follows[category_id]
        let tab = value.category.tab
        if (tab in new_process_follows) {
          new_process_follows[tab][category_id] = value
        }
        else {
          new_process_follows[tab] = { [category_id]: value }
        }
      }
      for (const category of action.payload.response.other_categories) {
        if (category.tab in new_process_follows) {
          new_process_follows[category.tab][category.id] = { category: category, follows: [] }
        }
        else {
          new_process_follows[category.tab] = { [category.id]: { category: category, follows: [] }
        }
      }
  }


  new_state.process_follows = new_process_follows

  return new_state

    case GET_PATIENT_GROUP_SUCCESS:
let center_by_center = {}
for (let patient_group of action.payload.response) {
  center_by_center[patient_group.center] = { ...patient_group }
}
new_state.patient_group = { ...center_by_center }
return new_state

    case GET_VISIT_FOLLOW_SUCCESS:
let visit_follows = [...action.payload.response]

new_state.visit_follows = visit_follows
return new_state

    default:
return state
  }
}

export default contacts
