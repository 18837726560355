import {
  GET_PROJECTS_VISITS,
  DELETE_PROJECTS_VISITS,
  UPDATE_PROJECTS_VISIT_DURATION,
  GET_PROJECTS_MONITORIZATIONS,
  DELETE_PROJECTS_MONITORIZATIONS,
  UPDATE_PROJECTS_MONITORIZATION_DURATION,
  NEW_PROJECTS_MONITORIZATION,
  NEW_PROJECTS_VISIT,
  GET_PROJECTS_CENTERS,
  GET_PROJECTS_CENTERS_SUCCESS,
  DELETE_PROJECTS_CENTERS,
  UPDATE_PROJECTS_CENTER_NAME,
  NEW_PROJECTS_CENTER,
  GET_CENTERS_DEVICES,
  NEW_CENTERS_DEVICES,
  UPDATE_DEVICE_DATA,
  DELETE_CENTERS_DEVICES,
  GET_CENTERS_PAYMENTS,
  GET_CENTERS_PAYMENTS_FLOW,
  GET_CENTERS_PAYMENTS_SUCCESS,
  NEW_PROFORMA,
  NEW_PAY_FLOW,
  NEW_CENTERS_PAYMENTS,
  DELETE_CENTERS_PAYMENTS,
} from "./actionTypes"

import {
  getProjectsVisitsSuccessAction,
  getProjectsVisitsAction,
  getProjectsMonitorizationsSuccessAction,
  getProjectsMonitorizationsAction,
  getProjectsCentersSuccessAction,
  getProjectsCentersAction,
  closeModalAction,
  getCentersDevicesActionSuccess,
  getCentersDevicesAction,
  getCentersPaymentsAction,
  getCentersPaymentsActionSuccess,
  newCentersPaymentsAction,
  deleteCentersPaymentAction,
  getCentersPaymentFlowsSuccess,
  getCentersPaymentFlowReqSuccess,
  newProformaActionSuccess,
} from "./actions"
import {
  globalFailAction,
} from "../bilgilendirme/actions"


import { call, put, takeEvery } from "redux-saga/effects"


import {
  getProjectsVisitsRequest,
  deleteProjectsVisitsRequest,
  updateProjectsVisitsRequest,
  newProjectsVisitsRequest,
  getProjectsMonitorizationsRequest,
  deleteProjectsMonitorizationsRequest,
  updateProjectsMonitorizationsRequest,
  newProjectsMonitorizationsRequest,
  getProjectsCentersRequest,
  deleteProjectsCentersRequest,
  updateProjectsCentersRequest,
  newProjectsCentersRequest,
  getCentersDevicesRequest,
  newCentersDevicesRequest,
  updateCentersDevicesRequest,
  deleteDeviceRequest,
  getCentersPaymentsRequest,
  newCentersPaymentsRequest,
  deletePaymentRequest,
  getCentersPaymentFlowsRequest,
  newProformaRequest,
  newPayFlowRequest,
} from "../../helpers/backend"
import { get } from "lodash"


function* getProjectsVisits({ payload }) {
  try {
    const response = yield call(
      getProjectsVisitsRequest,
      payload.project_id,
    )
    yield put(getProjectsVisitsSuccessAction(payload.project_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* deleteProjectsVisits({ payload }) {
  try {
    yield call(
      deleteProjectsVisitsRequest,
      payload.visit_id,
    )
    yield put(getProjectsVisitsAction(payload.project_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* updateProjectsVisitDuration({ payload }) {
  try {
    yield call(
      updateProjectsVisitsRequest,
      payload.visit_id,
      payload.new_duration,
    )
    yield put(getProjectsVisitsAction(payload.project_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newProjectsVisit({ payload }) {
  try {
    yield call(
      newProjectsVisitsRequest,
      payload.project_id,
      payload.duration,
    )
    yield put(getProjectsVisitsAction(payload.project_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

// Monitorization

function* getProjectsMonitorizations({ payload }) {
  try {
    const response = yield call(
      getProjectsMonitorizationsRequest,
      payload.project_id,
    )
    yield put(getProjectsMonitorizationsSuccessAction(payload.project_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* deleteProjectsMonitorizations({ payload }) {
  try {
    yield call(
      deleteProjectsMonitorizationsRequest,
      payload.monitorization_id,
    )
    yield put(getProjectsMonitorizationsAction(payload.project_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* updateProjectsMonitorizationDuration({ payload }) {
  try {
    yield call(
      updateProjectsMonitorizationsRequest,
      payload.monitorization_id,
      payload.new_duration,
    )
    yield put(getProjectsMonitorizationsAction(payload.project_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newProjectsMonitorization({ payload }) {
  try {
    yield call(
      newProjectsMonitorizationsRequest,
      payload.project_id,
      payload.duration,
    )
    yield put(getProjectsMonitorizationsAction(payload.project_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

// Centers

function* getProjectsCenters({ payload }) {
  try {
    const response = yield call(
      getProjectsCentersRequest,
      payload.project_id,
    )
    yield put(getProjectsCentersSuccessAction(payload.project_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* deleteProjectsCenters({ payload }) {
  try {
    yield call(
      deleteProjectsCentersRequest,
      payload.center_id,
    )
    yield put(getProjectsCentersAction(payload.project_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* updateProjectsCenterName({ payload }) {
  try {
    yield call(
      updateProjectsCentersRequest,
      payload.center_id,
      payload.new_name,
    )
    yield put(getProjectsCentersAction(payload.project_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newProjectsCenter({ payload }) {
  try {
    yield call(
      newProjectsCentersRequest,
      payload.project_id,
      payload.duration,
    )
    yield put(getProjectsCentersAction(payload.project_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* getCentersDevices({ payload }) {
  try {
    const response = yield call(
      getCentersDevicesRequest,
      payload.center_id,
    )
    yield put(getCentersDevicesActionSuccess(payload.center_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newCentersDevices({ payload }) {
  try {
    yield call(
      newCentersDevicesRequest,
      payload.center_id,
      payload.name,
      payload.file,
      payload.file_name,
      payload.countdown,
    )
    yield put(getCentersDevicesAction(payload.center_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}
function* centersDevicesUpdateCountDown({ payload }) {
  try {
    console.log(payload)
    yield call(
      updateCentersDevicesRequest,
      payload.id,
      payload.countdown,
    )
    yield put(getCentersDevicesAction(payload.center_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* deleteCentersDevices({ payload }) {
  try {
    yield call(
      deleteDeviceRequest,
      payload.payment_id,
    )
    yield put(getCentersDevicesAction(payload.center_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* getCentersPayments({ payload }) {
  try {
    const response = yield call(
      getCentersPaymentsRequest,
      payload.center_id,
    )
    yield put(getCentersPaymentsActionSuccess(payload.center_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newCentersPayments({ payload }) {
  try {
    yield call(
      newCentersPaymentsRequest,
      payload.center_id,
      payload.name,
      payload.file,
      payload.file_name,
    )
    yield put(getCentersPaymentsAction(payload.center_id))
    yield put(closeModalAction(true))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* deleteCentersPayments({ payload }) {
  try {
    yield call(
      deletePaymentRequest,
      payload.payment_id,
      payload.center_id,
    )
    yield put(getCentersPaymentsAction(payload.center_id))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}



function* getCentersPaymentsFlow({ payload }) {
  try {
    const response = yield call(
      getCentersPaymentFlowsRequest,
      payload.payment_id,
      payload.project_id,
    )
    yield put(getCentersPaymentFlowsSuccess(payload.payment_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}

function* newProforma({ payload }) {
  try {
    const response = yield call(newProformaRequest,payload)
    yield put(newProformaActionSuccess(response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}


function* newPayFlow({ payload }) {
  try {
    const response = yield call(
      newPayFlowRequest,
      payload
    )
    yield put(getCentersPaymentFlowReqSuccess(payload.payment_id, response))
  }
  catch (error) {
    yield put(globalFailAction(error.message))
  }
}


function* contactsSaga() {
  yield takeEvery(GET_PROJECTS_VISITS, getProjectsVisits)
  yield takeEvery(DELETE_PROJECTS_VISITS, deleteProjectsVisits)
  yield takeEvery(UPDATE_PROJECTS_VISIT_DURATION, updateProjectsVisitDuration)
  yield takeEvery(NEW_PROJECTS_VISIT, newProjectsVisit)

  yield takeEvery(GET_PROJECTS_MONITORIZATIONS, getProjectsMonitorizations)
  yield takeEvery(DELETE_PROJECTS_MONITORIZATIONS, deleteProjectsMonitorizations)
  yield takeEvery(UPDATE_PROJECTS_MONITORIZATION_DURATION, updateProjectsMonitorizationDuration)
  yield takeEvery(NEW_PROJECTS_MONITORIZATION, newProjectsMonitorization)

  yield takeEvery(GET_PROJECTS_CENTERS, getProjectsCenters)
  yield takeEvery(DELETE_PROJECTS_CENTERS, deleteProjectsCenters)
  yield takeEvery(UPDATE_PROJECTS_CENTER_NAME, updateProjectsCenterName)
  yield takeEvery(NEW_PROJECTS_CENTER, newProjectsCenter)
  yield takeEvery(GET_CENTERS_DEVICES, getCentersDevices)
  yield takeEvery(NEW_CENTERS_DEVICES, newCentersDevices)
  yield takeEvery(UPDATE_DEVICE_DATA, centersDevicesUpdateCountDown)
  yield takeEvery(DELETE_CENTERS_DEVICES, deleteCentersDevices)
  yield takeEvery(GET_CENTERS_PAYMENTS, getCentersPayments)
  yield takeEvery(NEW_CENTERS_PAYMENTS, newCentersPayments)
  yield takeEvery(DELETE_CENTERS_PAYMENTS, deleteCentersPayments)
  yield takeEvery(GET_CENTERS_PAYMENTS_FLOW, getCentersPaymentsFlow)
  yield takeEvery(NEW_PROFORMA, newProforma)
  yield takeEvery(NEW_PAY_FLOW, newPayFlow)

}



export default contactsSaga;
