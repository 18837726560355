import classnames from 'classnames'
import React, { useEffect, useState, useRef } from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'
import { Col, Row, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import { Card, CardBody, CardText, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import axios from 'axios'

import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'
import {
  newProformaAction,
  newPayFlow,
} from '../../store/planlama-ve-takvim/actions'
import { set } from 'lodash'
import { Circles } from 'react-loader-spinner'

import DeleteModal from "./../Bilgilendirme/DeleteModal"
import * as moment from 'moment'
function getFileName(fullPath) {
  if (fullPath) {
    var startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')
    var filename = fullPath.substring(startIndex)
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1)
    }
    return filename
  }
}

const APP = props => {
  const dispatch = useDispatch()
  const [titleSubList, setTitleSubList] = useState(props.currentSubTitle)
  const [deleteModal, setDeleteModal] = useState(false)
  const [showLoader, setshowLoader] = useState(false)
  const [show_save_button, setShowSaveButton] = useState(true)

  const { proformas, flow_status } = useSelector(state => ({
    proformas: state.PlanlamaVeTakvim.proformas ? state.PlanlamaVeTakvim.proformas : { status: false, msg: null },
    flow_status: state.PlanlamaVeTakvim.flow_status ? state.PlanlamaVeTakvim.flow_status : { status: false, msg: null },
  }))
  const proformaList = useRef(false);
  const isFirstRun = useRef(true);
  const isFirstRunPayFLow = useRef(true);

  const [updatedProformas, setUproformas] = useState(props.payment_flows.proformas)
  const [current_proc_proforma, setcpp] = useState({ status: false, msg: null })
  const [current_flow_status, setcfs] = useState({ saved_data: {}, msg: null })
  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
      setUserData(obj)
    }
  }, [props.success])

  const clickedButtonRefCurrent = useRef({});
  const [user_type, setUserType] = useState('DCT')
  const [AuthUserData, setUserData] = useState({})
  const [new_proforma_modal_show, setNewProformaModal] = useState(false)
  const [new_payment_flow, set_new_payment_flow] = useState(false)
  const [uyari, setUyari] = useState(false)
  const [setAlertWhenchanged, setAlertWhenchangedF] = useState(false)

  const [proforma_modal_data, proformaSetpaymentModalData] = useState({ title: null, project_id: props.project_id, status: false, value: null,id:null })

  const [paymentModalData, setPaymentModalData] = useState({ id: null, name: null, path: null, value: null, user_id: false, proforma_id: null, process_time: null, id: null, delete_wf: false })
  const payflowactions = (payment_id) => {

    const button = props.clickedButtonRef[payment_id]
    // const button = props.clickedButtonRef.current[payment_id];
    // console.log(button)
    if (button) {
      button.onClick();
    }
  };

  const proforma_data_change_title = (event) => {

    proformaSetpaymentModalData(proforma_modal_data => ({
      ...proforma_modal_data,
      title: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(proforma_modal_data);
    }
    );
  };
  const proforma_data_change_budget = (event) => {
    proformaSetpaymentModalData(proforma_modal_data => ({
      ...proforma_modal_data,
      value: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(proforma_modal_data);
    }
    );
  };
  const paymentFlowReButton = () => {
    setTitleSubList("Ödeme Akışları")
    payflowactions(props.payment_id)
  }
  const proforma_list = () => {
    setTitleSubList("Proforma Listesi")
    console.log(props.payment_flows.proformas)
  }
  const new_proforma_modal_action = () => {
    proforma_modal_data.project_id = props.project_id;
    console.log(proforma_modal_data)
    setNewProformaModal(!new_proforma_modal_show)
  }
  const edit_proforma_modal_action = (t, proforma_id) => {
    console.log(proforma_id);
    const editProformaData = props.payment_flows.proformas.find(item => item.id === proforma_id);
    if (editProformaData.title.includes('(' + editProformaData.value + ')'))
      editProformaData.title = editProformaData.title.substring(0, editProformaData.title.indexOf('(' + editProformaData.value + ')')).trim();

    proformaSetpaymentModalData(proforma_modal_data => ({
      ...proforma_modal_data,
      title: editProformaData.title,
      value: editProformaData.value,
      id: editProformaData.id,
      status: editProformaData.status ? editProformaData.status : false
    }), () => {
      // This callback is executed after the state has been updated
      console.log("proforma_modal_data");
      console.log(proforma_modal_data);
    }
    );
    setNewProformaModal(!new_proforma_modal_show)
  }
  const handleModalBudgetChange = (event) => {
    setPaymentModalData(paymentModalData => ({
      ...paymentModalData,
      value: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(proforma_modal_data);
    }
    );
  }
  const handleModalProformaChange = (event) => {
    setPaymentModalData(paymentModalData => ({
      ...paymentModalData,
      proforma_id: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(proforma_modal_data);
    }
    );
  }
  const handleModalUserChange = (event) => {
    setPaymentModalData(paymentModalData => ({
      ...paymentModalData,
      user_id: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(proforma_modal_data);
    }
    );
  }
  const handleModalCreateTimeChange = (event) => {
    setPaymentModalData(paymentModalData => ({
      ...paymentModalData,
      process_time: event.target.value
    }), () => {
      // This callback is executed after the state has been updated
      console.log(proforma_modal_data);
    }
    );
  }

  function filepathChanger(fullPath) {
    // return fullPath
    fullPath= fullPath.replace("https://0.0.0.0:8000/", process.env["REACT_APP_BACKEND_URL"]);
    return fullPath.replace("//media", "/media");
  }
  const handle_new_payment_flow = () => {

    let $file = document.querySelector('#uploadPaymentFlowFile')
    if ($file) {
      let full_file_name = getFileName($file.value)
      if ($file.files[0]) {
        var reader = new FileReader()
        reader.readAsDataURL($file.files[0])
        reader.onload = function () {
          dispatch(newPayFlow(props.payment_id, props.project_id, paymentModalData, reader.result, full_file_name))
          isFirstRunPayFLow.current = true;
        }
      }
      else {
        dispatch(newPayFlow(props.payment_id, props.project_id, paymentModalData, null, null))
        isFirstRunPayFLow.current = true;

      }
    }
    else {
      dispatch(newPayFlow(props.payment_id, props.project_id, paymentModalData, null, null))
      isFirstRunPayFLow.current = true;

    }
  }

  const handle_proforma_form = () => {
    proforma_modal_data.project_id = props.project_id;
    console.log(proforma_modal_data);
    dispatch(newProformaAction(proforma_modal_data));
    isFirstRun.current = true;
  }

  useEffect(() => {
    console.log(props.currentSubTitle);
    setTitleSubList(props.currentSubTitle)
  }, [props.currentSubTitle]);

  useEffect(() => {
    if (proformas && isFirstRun.current) {
      if (proformas.msg) {
        setcpp(proformas);
        alert(proformas.msg);
        setUyari(true);
        isFirstRun.current = false;
        setNewProformaModal(false)
        payflowactions(props.payment_id)

      }
    }
  }, [proformas, current_proc_proforma, uyari, props]); // Removed props since it's not used inside the effect

  useEffect(() => {
    if (flow_status.msg && isFirstRunPayFLow.current && (new_payment_flow || deleteModal)) {
      console.log(flow_status);
      setcfs(flow_status);
      alert(flow_status.msg);
      setUyari(true);

      isFirstRunPayFLow.current = false;
      if (new_payment_flow) {
        set_new_payment_flow(!new_payment_flow)
      }
      else if (deleteModal) {
        setDeleteModal(false)
      }
      payflowactions(props.payment_id)

    }
  }, [flow_status, current_flow_status, uyari, new_payment_flow, isFirstRunPayFLow, props]); // Removed props since it's not used inside the effect
  const newPaymentFlow = (fc = null, payflow_id = null) => {
    if (payflow_id) {

      const targetItem = props.payment_flows.data.find(item => item.id === payflow_id);
      const newPaymentModalData = {};
      newPaymentModalData.user_id = targetItem.user_id;
      newPaymentModalData.value = parseFloat(targetItem.value);
      newPaymentModalData.process_time = datetimeToStrws(targetItem.process_date);
      newPaymentModalData.proforma_id = targetItem.proforma_id;
      newPaymentModalData.id = targetItem.id;
      setPaymentModalData(newPaymentModalData);



    }
    else {
      const newPaymentModalData = {};
      setPaymentModalData(newPaymentModalData);
    }
    setcfs({ saved_data: {}, msg: null })
    set_new_payment_flow(!new_payment_flow)
  }

  const datetimeToStrws = date => {
    const formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
    return formattedDate;
  }

  const datetimeToStr = date => {
    const date1 = moment(new Date(date)).format('DD-MM-YYYY')
    return date1
  }
  const handleDeleteEvent = () => {
    dispatch(handle_new_payment_flow());
    // dispatch(onDeleteEvent(event))
    setDeleteModal(false)
    // toggle()
  }
  const setPayFlowDelete = (id) => {

    const newPaymentModalData = {};
    newPaymentModalData.delete_wf = true;
    newPaymentModalData.id = id;
    setPaymentModalData(newPaymentModalData);
    setDeleteModal(true);
  }
  return (
    <React.Fragment>
      {props.showPayment ? (
        <Row>
          <Col xs={12}>
            <div className="mt-4">
              <div className="d-flex flex-wrap">
                <h5 className="font-size-16 me-3">{titleSubList}</h5>
                <div className="ms-auto">
                  {(user_type == 'ADM' || user_type == 'PM') ? (
                    <>
                      <Link to="#" className="fw-medium btn btn-success m-1" onClick={newPaymentFlow}>
                        + Yeni Ödeme Akışı
                      </Link>
                      <Link to="#" className="fw-medium btn btn-success m-1" onClick={new_proforma_modal_action}>
                        + Yeni Proforma
                      </Link>
                      <Link to="#" className="fw-medium btn btn-primary m-1" onClick={proforma_list}>
                        + Proforma Listesi
                      </Link>
                      <Link to="#" className="fw-medium btn btn-primary m-1" onClick={paymentFlowReButton}>
                        + Ödeme Listesi
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}

                  {(user_type == 'CRA' || user_type == 'SC') ? (
                    <>
                      <Link to="#" className="fw-medium text-reset" onClick={newPaymentFlow}>
                        + Yeni Ödeme Akışı
                      </Link>
                      <Link to="#" className="fw-medium btn btn-primary m-1" onClick={paymentFlowReButton}>
                        + Ödeme Listesi
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div>
              {titleSubList == "Proforma Listesi" ? (
                <>
                  <Table className="table align-middle table-nowrap table-hover mb-0 ">
                    <thead>
                      <tr>
                        <th scope="col">Proforma</th>
                        <th scope="col">Değer</th>
                        <th scope="col">Durum</th>
                        <th scope="col">İşlemler</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.payment_flows.proformas ? (
                        <>
                          {
                            props.payment_flows.proformas.length > 0 && props.payment_flows.proformas ? (
                              props.payment_flows.proformas.map(proforma => (
                                <>
                                  {(user_type == 'ADM' || user_type == 'PM') ? (
                                    <tr key={proforma.id}>

                                      <td>{proforma.title}</td>
                                      <td>{proforma.value}</td>
                                      <td>{(proforma.status.toString()=="true")?'Aktif':'Pasif'}</td>
                                      <td>
                                        <div className="d-flex gap-3">

                                          {(user_type == 'ADM' || user_type == 'PM' || (user_type == 'CRA' && flow.user_id == AuthUserData.id) || (user_type == 'SC' && flow.user_id == AuthUserData.id)) ? (

                                            <>
                                              <Link className="text-success" to="#">
                                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => edit_proforma_modal_action(this, proforma.id)}></i>
                                              </Link>
                                            </>

                                          ) : (
                                            <><div>-</div></>
                                          )}
                                          {/* <Link className="text-danger" to="#"> */}
                                          {/*   <i */}
                                          {/*     className="mdi mdi-delete font-size-18" */}
                                          {/*     id="deletetooltip" */}
                                          {/*     onClick={() => handleDeleteUser(user)}></i> */}
                                          {/* </Link> */}
                                        </div>
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  <span>Henüz proforma bulunmamaktadır.</span>
                                </td>
                              </tr>
                            )
                          }
                        </>

                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <span>Yükleniyor, lütfen bekleyin...</span>
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </Table>
                </>
              )
                : (
                  <>
                    <Table className="table align-middle table-nowrap table-hover mb-0 ">
                      <thead>
                        <tr>
                          <th scope="col">Proforma</th>
                          <th scope="col">Kullanıcı</th>
                          <th scope="col">Değer</th>
                          <th scope="col">Dosya</th>
                          <th scope="col">Tarih</th>
                          <th scope="col">İşlemler</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.payment_flows.data ? (
                          <>
                            {
                              props.payment_flows.data.length > 0 && props.payment_flows.data ? (
                                props.payment_flows.data.map(flow => (
                                  <>
                                    {((flow.user_id == AuthUserData.id && (user_type == 'CRA' || user_type == 'SC')) || user_type == 'ADM' || user_type == 'PM' || user_type == 'SPS') ? (
                                      <tr key={flow.id}>

                                        <td>{flow.proforma_detail.title}({flow.proforma_detail.value})</td>
                                        <td>{flow.user_detail.name}({flow.user_detail.email})</td>
                                        <td>{flow.value}</td>
                                        <td>
                                          <a href={filepathChanger("https://0.0.0.0:8000/" + flow.file)}>{flow.file_name}</a>
                                        </td>
                                        <td>{datetimeToStr(flow.process_date)}</td>
                                        <td>
                                          <div className="d-flex gap-3">

                                            {(user_type == 'ADM' || user_type == 'PM' || (user_type == 'CRA' && flow.user_id == AuthUserData.id) || (user_type == 'SC' && flow.user_id == AuthUserData.id)) ? (

                                              <>
                                                <Link className="text-success" to="#">
                                                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => newPaymentFlow(this, flow.id)}></i>
                                                </Link>
                                                <Link className="text-danger" to="#">
                                                  <i className="mdi mdi-delete font-size-18" id="edittooltip" onClick={() => setPayFlowDelete(flow.id)}></i>
                                                </Link>
                                              </>

                                            ) : (
                                              <><div>-</div></>
                                            )}
                                            {/* <Link className="text-danger" to="#"> */}
                                            {/*   <i */}
                                            {/*     className="mdi mdi-delete font-size-18" */}
                                            {/*     id="deletetooltip" */}
                                            {/*     onClick={() => handleDeleteUser(user)}></i> */}
                                            {/* </Link> */}
                                          </div>
                                        </td>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    <span>Henüz ödeme akışı bulunmamaktadır.</span>
                                  </td>
                                </tr>
                              )
                            }
                          </>

                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <span>Yükleniyor, lütfen bekleyin...</span>
                            </td>
                          </tr>
                        )}

                      </tbody>
                    </Table>
                  </>
                )}
            </div>

          </Col>
        </Row>
      ) : (
        <></>
      )}

      {/* NEW */}
      <Modal isOpen={new_proforma_modal_show} toggle={new_proforma_modal_action}>
        <ModalHeader toggle={new_proforma_modal_action} tag="h4">
          Proforma Ekle
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={(event, errors, values) => handle_proforma_form()}>

            <Row form>
              <Col xs={12}>
                <div className="mb-3">
                  <AvField
                    name="title"
                    label="Proforma Başlık"
                    type="text"
                    errorMessage="Invalid name"
                    onChange={proforma_data_change_title}
                    validate={{
                      required: { value: true },
                    }}
                    value={proforma_modal_data.title ? proforma_modal_data.title : ''}
                  />
                </div>
                <div className='mb-3'><label>
                  <input
                    type="radio"
                    class="m-2"
                    name="status"
                    value="true"
                    onChange={(e) => proformaSetpaymentModalData({ ...proforma_modal_data, status: e.target.value === 'true' })}
                    checked={proforma_modal_data.status === true}
                  />
                  Aktif
                </label>
                  <label>
                    <input
                      type="radio"
                      class="m-2"
                      value="false"
                      name="status"
                      onChange={(e) => proformaSetpaymentModalData({ ...proforma_modal_data, status: e.target.value === true })}
                      checked={proforma_modal_data.status === false}
                    />
                    Pasif
                  </label>
                </div>

                <div className="mb-3">
                  <AvField
                    name="budget"
                    label="Bütçe"
                    type="number"
                    errorMessage="Invalid value"
                    onChange={proforma_data_change_budget}
                    validate={{
                      required: { value: true },
                    }}
                    value={proforma_modal_data.value ? parseFloat(proforma_modal_data.value) : ''}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-workflow">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      {/*
      /////////////////
      New Work Flow
      /////////////////
      */}
      <Modal isOpen={new_payment_flow} toggle={newPaymentFlow}>
        <ModalHeader toggle={newPaymentFlow} tag="h4">
          Ödeme Akışı
          {paymentModalData.id ? (
            <span> Düzenle</span>
          ) : (
            <span> Ekle</span>
          )}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handle_new_payment_flow}>
            {props.payment_flows ? (
              <Row form>
                <Col xs={12}>
                  <div className="mb-3">
                    <AvField
                      name="proforma_id"
                      label="Proforma"
                      type="select"
                      onChange={handleModalProformaChange}
                      errorMessage="Yanlış Proforma"
                      validate={{
                        required: { value: true },
                      }}
                      value={paymentModalData.proforma_id} // Set the value attribute here
                    >
                      <option value="">Lütfen Seçiniz</option>
                      {props.payment_flows.proformas ? (
                        props.payment_flows.proformas.map((option, index) => (
                          <option key={index} value={option.id} disabled={option.status.toString()=="true"?false:true}>
                            {option.title}
                          </option>

                        ))
                      ) : (
                        <option value="">None</option>
                      )}
                    </AvField>

                  </div>
                  <div className="mb-3">
                    <AvField
                      name="user_id"
                      label="Kullanıcı"
                      type="select"
                      onChange={handleModalUserChange}
                      errorMessage="Yanlış Kullanıcı"
                      validate={{
                        required: { value: true },
                      }}
                      value={paymentModalData.user_id} // Set the value attribute here
                    >
                      <option value="">Lütfen Seçiniz</option>
                      {props.payment_flows.users ? (
                        props.payment_flows.users.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value="">None</option>
                      )}
                    </AvField>

                  </div>
                  <div className="mb-3">
                    <label>Ödeme Dosyası </label>
                    <input type="file" className="form-control-file" id="uploadPaymentFlowFile" defaultValue={paymentModalData.file ? paymentModalData.file : ''} />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="budget"
                      label="Bütçe"
                      type="number"
                      errorMessage="Invalid value"
                      onChange={handleModalBudgetChange}
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={paymentModalData.value ? paymentModalData.value : ''}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="create_datetime"
                      label="İşlem Tarihi"
                      type="date"
                      errorMessage="Hatalı Tarih"
                      onChange={handleModalCreateTimeChange}
                      format="yyyy-MM-dd"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={paymentModalData.process_time ? datetimeToStrws(paymentModalData.process_time) : ''} // Pass create_datetime to datetimeToStrws function
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <p>Saving, please wait...
                <div style={{ "width": "100%" }}>
                  <center>
                    <Circles
                      height="80"
                      width="80"
                      color="#4fa94d"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={showLoader}
                    />
                  </center>
                </div>
              </p>
            )}

            {show_save_button ? (
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-workflow">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
      {/*
                    ///////////////////////////////////////////////////
                                    */}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
    </React.Fragment>
  )
}

export default APP
