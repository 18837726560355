export const GET_PROJECTS_FOLDER = "GET_PROJECTS_FOLDER"
export const GET_PROJECTS_FOLDER_SUCCESS = "GET_PROJECTS_FOLDER_SUCCESS"
export const GET_PROJECTS_FOLDER_FAIL = "GET_PROJECTS_FOLDER_FAIL"

export const CREATE_PROJECTS_FOLDER = "CREATE_PROJECTS_FOLDER"
export const CREATE_PROJECTS_FOLDER_SUCCESS = "CREATE_PROJECTS_FOLDER_SUCCESS"

export const GET_FOLDERS_FILES = "GET_FOLDERS_FILES"
export const GET_FOLDERS_FILES_SUCCESS = "GET_FOLDERS_FILES_SUCCESS"

export const GET_FILES_WORKFLOW = "GET_FILES_WORKFLOW"
export const GET_WORKFLOW_SUCCESS = "GET_WORKFLOW_SUCCESS"
export const GET_WORKFLOW_FAILED = "GET_WORKFLOW_FAILED"
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE"
export const CREATE_OR_UPDATE_WORKFLOW = "CREATE_OR_UPDATE_WORKFLOW"

export const DELETE_FILE = "DELETE_FILE"
export const CREATE_FILE = "CREATE_FILE"

export const FORM_DONE = "FORM_DONE"

export const CREATE_PERMISSION = "CREATE_PERMISSION"
export const DELETE_PERMISSION = "DELETE_PERMISSION"

// TODO:
export const GLOBAL_FAIL = "GLOBAL_FAIL"

export const CREATE_PROFORMA = "CREATE_PROFORMA"
