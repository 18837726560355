import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Workflows from './Workflows'
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Collapse,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  createPermissionAction,
  deletePermissionAction,
  formDoneStatusAction,
  deleteFile,
  fileWorkFlowStatus,
  createFile,
  clearErrorMessage,
} from '../../store/bilgilendirme/actions'
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'
import { Circles } from 'react-loader-spinner'

const RecentFile = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    console.log('RecentFile unmounted'); // Add this line
    dispatch(clearErrorMessage());
  }, []);

  const { files, forum_done, workflows, showWorkflowPane,errorMessage } = useSelector(state => ({
    files: state.Bilgilendirme.files,
    forum_done: state.Bilgilendirme.forum_done,
    workflows: state.Bilgilendirme.workflows,
    showWorkflowPane: state.Bilgilendirme.showWorkflowPane ? state.Bilgilendirme.showWorkflowPane : false,
    errorMessage: state.Bilgilendirme.errorMessage ? state.Bilgilendirme.errorMessage : "",
  }))

  const [clickedRow, setClickedRow] = useState(null);



  const [current_folders_files, setCurrentFoldersFiles] = useState([])
  const [current_workflows, setCurrentWorkflows] = useState([])
  const [file_id, setActiveFile] = useState(null)
  const [show_save_button, setShowSaveButton] = useState(true)
  const [showLoader, setshowLoader] = useState(false)
  const [workflowShowData, workflowShowSet] = useState(false)
  const [currentErrorMessage, setCurrentErrorMessage] = useState("")

  useEffect(() => {
    setCurrentFoldersFiles([])


    let file = files.find((element, _index, _array) => {
      if (element.folder_id == props.folder_id) {
        return element
      }
    })

    if (file) {
      setCurrentFoldersFiles(file.files)
    }
    workflowShowSet(false)
    setCurrentErrorMessage("");

  }, [files,errorMessage, props])
  useEffect(() => {
    if (errorMessage) {
      setCurrentErrorMessage(errorMessage)
    }
  }, [errorMessage])
  useEffect(() => {
    if (currentErrorMessage) {
      alert(currentErrorMessage);
      setCurrentErrorMessage("");
    }
  }, [currentErrorMessage])



  const delete_file = file_id => {
    dispatch(deleteFile(file_id, props.folder_id))

    workflowShowSet(false)
  }

  // helper things

  function getFileName(fullPath) {
    if (fullPath) {
      var startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')
      var filename = fullPath.substring(startIndex)
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1)
      }
      return filename
    }
  }
  function filepathChanger(fullPath) {
    // return fullPath
    return fullPath.replace("https://0.0.0.0:8000/", process.env["REACT_APP_BACKEND_URL"]);
  }

  // New file things
  const [is_uploading, setIsUploading] = useState(false)

  const [new_file_modal, setNewFolderModal] = useState(false)
  const set_new_file_modal_toggle = () => {
    setNewFolderModal(!new_file_modal)
  }

  const handle_new_file_modal = (_, input_data) => {
    setShowSaveButton(false)
    let $file = document.querySelector('#uploadFile')
    let full_file_name = getFileName($file.value)

    setshowLoader(true);
    var reader = new FileReader()
    reader.readAsDataURL($file.files[0])
    reader.onload = function () {
      let c = dispatch(
        createFile(props.folder_id, input_data.file_type, input_data.version_note, reader.result, full_file_name)
      );
    }
    reader.onerror = function (error) {
      alert('Error: ', error)
    }
    setIsUploading(true)
    workflowShowSet(false)
  }

  useEffect(() => {
    if (forum_done) {
      console.log("forum_done");
      setNewFolderModal(false)
      dispatch(formDoneStatusAction(false))

    // dispatch(clearErrorMessage())
      setShowSaveButton(true)
      setIsUploading(false)
      setshowLoader(false)
      workflowShowSet(false)
    }
  }, [forum_done])



  const toggle_permissions = (permission, new_value, file_id) => {
    if (new_value) {
      dispatch(createPermissionAction(file_id, props.folder_id, permission))
    } else {
      dispatch(deletePermissionAction(file_id, props.folder_id, permission))
    }
  }
  const workflowsToggle = (permission, new_value, file_id) => {
    setClickedRow(file_id === clickedRow ? null : file_id);
    dispatch(fileWorkFlowStatus(file_id));
    setActiveFile(file_id);

  }

  useEffect(() => {

    if (workflows) {
      setCurrentWorkflows(workflows)
    }
    if (showWorkflowPane) {
      workflowShowSet(showWorkflowPane)
    }
  }, [workflows, props])

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])
  const [user_type, setUserType] = useState('DCT')

  return (
    <React.Fragment>
      <div className="mt-4">
        <div className="d-flex flex-wrap">
          <h5 className="font-size-16 me-3">Dosyalar</h5>

          <div className="ms-auto">
            {(user_type == 'ADM' || user_type == 'SPS' || user_type == 'PM' || user_type == 'CRA') &&
              props.folder_id ? (
              <Link to="#" className="fw-medium text-reset" onClick={set_new_file_modal_toggle}>
                + Dosya Yükle
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
        <hr className="mt-2" />
        <div className="table-responsive file-manager" style={{ paddingBottom: 250 }}>
          {props.folder_id ? (
            <>
              <Table className="table align-middle table-nowrap table-hover mb-0 ">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Tür</th>
                    <th scope="col">Version Notu</th>
                    <th scope="col">Tarih</th>
                    <th scope="col">Bilgilendirilen Kişiler</th>
                  </tr>
                </thead>
                <tbody>
                  {current_folders_files.map((file, _) => (
                    <tr
                      key={file.id}
                      className={clickedRow === file.id ? 'clicked-row' : ''}
                      onClick={workflowsToggle.bind(this, 'SPS', !file.permitted_user_types.includes('SPS'), file.id)}
                    >
                      <td>
                        <a target="_blank" href={filepathChanger(file.path)} className="text-dark fw-medium">
                          <i className="mdi mdi-file-document font-size-16 align-middle text-primary me-2"></i>
                          {file.name}
                        </a>
                      </td>
                      <td>{file.file_type}</td>
                      <td>{file.version_note}</td>
                      <td>{file.create_datetime}</td>

                      {file.has_edit_permission ? (
                        <td>
                          <UncontrolledDropdown className="mb-2" direction="left">
                            <DropdownToggle color="white" className="btn font-size-16 btn-link text-muted mt-n2">
                              <i className="mdi mdi-dots-horizontal"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">
                              <Link className="dropdown-item" to="#">
                                <i className="bx bx-comment-check" /> PM
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={toggle_permissions.bind(this, 'SPS', !file.permitted_user_types.includes('SPS'), file.id)}
                              >
                                {file.permitted_user_types.includes('SPS') ? (
                                  <>
                                    <i className="bx bx-comment-check" />{' '}
                                  </>
                                ) : (
                                  <></>
                                )}
                                Sponsor
                              </Link>

                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={toggle_permissions.bind(this, 'CRA', !file.permitted_user_types.includes('CRA'), file.id)}
                              >
                                {file.permitted_user_types.includes('CRA') ? (
                                  <>
                                    <i className="bx bx-comment-check" />{' '}
                                  </>
                                ) : (
                                  <></>
                                )}
                                CRA
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={toggle_permissions.bind(this, 'SC', !file.permitted_user_types.includes('SC'), file.id)}
                              >
                                {file.permitted_user_types.includes('SC') ? (
                                  <>
                                    <i className="bx bx-comment-check" />{' '}
                                  </>
                                ) : (
                                  <></>
                                )}
                                SC
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={toggle_permissions.bind(this, 'DCT', !file.permitted_user_types.includes('DCT'), file.id)}
                              >
                                {file.permitted_user_types.includes('DCT') ? (
                                  <>
                                    <i className="bx bx-comment-check" />{' '}
                                  </>
                                ) : (
                                  <></>
                                )}
                                DOCTOR
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link className="dropdown-item" to="#" onClick={delete_file.bind(this, file.id)}>
                                Dosyayı Sil
                              </Link>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Workflows workflows={current_workflows} showWorkflowPanel={workflowShowData} file_id={file_id}/>
            </>
          ) : (
            <span>Klasör Seçin</span>
          )}
        </div>
      </div>
      {/*
      /////////////////
      New Klasör
      /////////////////
      */}
      <Modal isOpen={new_file_modal} toggle={set_new_file_modal_toggle}>
        <ModalHeader toggle={set_new_file_modal_toggle} tag="h4">
          Yeni Klasör Yarat
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handle_new_file_modal}>
            {!is_uploading ? (
              <Row form>
                <Col xs={12}>
                  <div className="mb-3">
                    <AvField
                      name="file_type"
                      label="Dosya Türü"
                      type="text"
                      errorMessage="Yanlış İsim"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>

                <div className="mb-3">
                  <AvField
                    name="version_note"
                    label="Versiyon Notu"
                    type="text"
                    errorMessage="Yanlış İsim"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label>Dosya</label>
                  <br />
                  <input type="file" className="form-control-file" id="uploadFile" />
                </div>
              </Row>
            ) : (
              <p>Uploading, please wait...
                <div style={{ "width": "100%" }}>
                  <center>
                    <Circles
                      height="80"
                      width="80"
                      color="#4fa94d"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={showLoader}
                    />
                  </center>
                </div>
              </p>
            )}

            {show_save_button ? (
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
      {/*
                    ///////////////////////////////////////////////////
                                    */}

    </React.Fragment>
  )
}

export default RecentFile
