import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

// import Component
import FileLeftBar from './FileLeftBar'
import RecentFile from './RecentFile'
import { useSelector } from 'react-redux'

import { getFoldersFilesAction, getFoldersFilesWorkFlowAction } from '../../store/bilgilendirme/actions'
import { useDispatch } from 'react-redux'

const Index = props => {
  const dispatch = useDispatch()



  const { projeler, folders} = useSelector(state => ({
    projeler: state.contacts.projeler,
    folders: state.Bilgilendirme.folders,
  }))

  // STATES
  const [current_proje, setCurrentProje] = useState({})
  const [current_folder, setCurrentFolder] = useState('')

  // set current proje

  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  useEffect(() => {
    for (let project_folders of folders) {
      let project_folder_ids = []

      for (let f of project_folders.folders) {
        project_folder_ids.push(f.id)
      }

      if (project_folder_ids.includes(current_folder) === false) {
        setCurrentFolder('')
      }
    }
  }, [current_proje])

  useEffect(() => {
    if (current_folder != '') {
      dispatch(getFoldersFilesAction(current_folder))
    }
  }, [current_folder])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - Bilgilendirme</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Bilgilendirme" breadcrumbItem={current_proje ? current_proje.name : ''} />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                {/* FileRightBar  */}
                <FileLeftBar proje={current_proje} setCurrentFolder={setCurrentFolder} />
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <RecentFile proje={current_proje} folder_id={current_folder}  />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Index
