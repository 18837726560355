import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECTS_USERS_SUCCESS,
  ADD_USER_TO_PROJECT_LAST_RESPONSE,
  ADD_USER_TO_PROJECT_LAST_RESPONSE_HANDLED,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAIL,
  CREATE_NEW_USER_RESET_RESULT,
  GET_PROJECT_BUDGET_ABSTRACT_SUCCESS,
  GET_PROJECT_BUDGET_ABSTRACT,
} from "./actionTypes"


const INIT_STATE = {
  projeler: [],
  budget_abs: {},
  projects_get: false,
  add_user_to_project_last_response: null,
  create_new_user_response: { result: null, reason: null },
  error: {},
}
const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_BUDGET_ABSTRACT_SUCCESS:
      return {
        ...state,
        budget_abs: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projeler: action.payload,
        projects_get: true,
      }

    case CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        create_new_user_response: { result: "OK", reason: null }
      }

    case CREATE_NEW_USER_FAIL:
      return {
        ...state,
        create_new_user_response: { result: "FAIL", reason: action.payload }
      }

    case CREATE_NEW_USER_RESET_RESULT:
      return {
        ...state,
        create_new_user_response: { result: null, reason: null },
      }
    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projeler: [action.payload, ...state.projeler],
      }

    case ADD_USER_TO_PROJECT_LAST_RESPONSE_HANDLED:
      return {
        ...state,
        add_user_to_project_last_response: null,
      }
    case ADD_USER_TO_PROJECT_LAST_RESPONSE:
      return {
        ...state,
        add_user_to_project_last_response: action.payload
      }

    case GET_PROJECTS_USERS_SUCCESS:
      return {
        ...state,
        projeler: state.projeler.map(proje =>
          proje.id.toString() === action.payload.id.toString()
            ? { ...proje, num_of_assigned_users: action.payload.users.length, users: action.payload.users }
            : proje
        ),
      }

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projeler: state.projeler.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projeler: state.projeler.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
