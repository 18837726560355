export const DEFAULT_SURECLER = [
  { id: 1, name: 'Proje Hazırlık Süreci' },
  { id: 2, name: 'Proje Ekibi' },
  { id: 3, name: 'Tedarik Süreci' },
  { id: 4, name: 'Çalışma Ödemeleri' },
]

export const DEFAULT_KATEGORILER = [
  { id: 1, name: 'Çeviri', surec_id: 1 },
  { id: 2, name: 'Protokol Hazırlama', surec_id: 1 },
  { id: 3, name: 'Etik Kurul Ücreti', surec_id: 1 },
  { id: 4, name: 'Ön merkez Ziyareti', surec_id: 1 },
  { id: 5, name: 'Proje Yönetimi', surec_id: 1 },
  { id: 6, name: 'Yetkililer', surec_id: 2 },
  { id: 7, name: 'Sarf Malzeme', surec_id: 3 },
  { id: 8, name: 'Medikal Kit Gönderim Bilgisi', surec_id: 3 },
  { id: 9, name: 'Numune Taşıma Kutusu', surec_id: 3 },
  { id: 10, name: 'Merkezlere Ait Rapor ve Doküman', surec_id: 3 },
  { id: 11, name: 'Hastalara Ait Rapor ve Doküman', surec_id: 3 },
  { id: 12, name: 'Tıbbi Örnek Alımı İçin Kurye Süreci', surec_id: 3 },
  { id: 13, name: 'İlaç ', surec_id: 3 },
  { id: 14, name: 'Cihaz', surec_id: 3 },
  { id: 15, name: 'Malzeme', surec_id: 3 },
  { id: 16, name: 'Araştırıcı Toplantısı', surec_id: 4 },
  { id: 17, name: 'Hasta Yol Ücretleri', surec_id: 4 },
  { id: 27, name: 'Hasta Sağlık Sigortası', surec_id: 4 },
  { id: 18, name: 'Döner Sermaye', surec_id: 4 },
  { id: 19, name: 'Araştırıcı Hekim Ücreti', surec_id: 4 },
  { id: 20, name: 'Hasta Başına Tahlil Hizmetleri', surec_id: 4 },
  { id: 21, name: 'Dışarıdan Hizmet Alım Ücreti', surec_id: 4 },
  { id: 22, name: 'Monitörizasyon Maliyeti-Şehirİçi', surec_id: 4 },
  { id: 23, name: 'Monitörizasyon Maliyeti-Şehirler Arası', surec_id: 4 },
  { id: 24, name: 'Etik Kurul Ücreti', surec_id: 4 },
  { id: 25, name: 'Sağlık Bakanlığı Onay Ücreti', surec_id: 4 },
  { id: 28, name: 'Şehiriçi Vizit Bütçesi', surec_id: 4 },
  { id: 29, name: 'Şehirler Arası Vizit Bütçesi', surec_id: 4 },
  { id: 26, name: 'Diğer', surec_id: 4 },
  // 27, 28, 29 defiend up
]

export const DEFAULT_KALEMLER = [
  { id: 1, name: 'Çeviri', kategori_id: 1 },
  { id: 2, name: 'Protokol Hazırlama', kategori_id: 2 },
  { id: 3, name: 'Etik Kurul Ücreti', kategori_id: 3 },
  { id: 4, name: 'Uçak', kategori_id: 4 },
  { id: 5, name: 'Havaş', kategori_id: 4 },
  { id: 6, name: 'Taksi', kategori_id: 4 },
  { id: 7, name: 'Konaklama', kategori_id: 4 },
  { id: 8, name: 'Gıda', kategori_id: 4 },
  { id: 9, name: 'Günlük İşçilik', kategori_id: 4 },
  { id: 10, name: 'Proje Yönetimi', kategori_id: 5 },
  { id: 11, name: 'Proje Yöneticileri', kategori_id: 6 },
  { id: 12, name: 'Klinik Araştırma Uzmanları', kategori_id: 6 },
  { id: 13, name: 'Saha Görevlileri', kategori_id: 6 },
  { id: 14, name: 'Araştırıcı Hekimler', kategori_id: 6 },
  { id: 15, name: 'Numune Taşıma Kutusu', kategori_id: 9 },
  { id: 16, name: 'Paper CRF', kategori_id: 11 },
  { id: 17, name: 'SOP Index', kategori_id: 10 },
  { id: 18, name: 'ISF Dosyası', kategori_id: 10 },
  { id: 19, name: 'Kod Kırma Zarfı', kategori_id: 11 },
  { id: 20, name: 'BOF', kategori_id: 11 },
  { id: 21, name: 'Tıbbı Numune Gönderim', kategori_id: 12 },
  { id: 22, name: 'IMP', kategori_id: 13 },
  { id: 23, name: 'Tartı', kategori_id: 14 },
  { id: 24, name: 'Tansiyon Cihazı', kategori_id: 14 },
  // { id: 25, name: 'Glukometre', kategori_id: 14 },
  { id: 26, name: 'Sponsor Firma Katılan Kişi Sayısı', kategori_id: 16 },
  { id: 27, name: 'Katılacak Araştırıcı Hekim Sayısı', kategori_id: 16 },
  { id: 28, name: 'Katılacak Asistan Sayısı', kategori_id: 16 },
  { id: 29, name: 'Katılacak Proje Yönetici Sayısı', kategori_id: 16 },
  { id: 30, name: 'Katılacak Klinik Araştırma Uzmanı Sayısı', kategori_id: 16 },
  { id: 31, name: 'Hasta Yol Ücretleri', kategori_id: 17 },
  { id: 32, name: 'Döner Sermaye', kategori_id: 18 },
  { id: 33, name: 'Araştırıcı Hekim Ücreti', kategori_id: 19 },
  { id: 34, name: 'Tıbbi Tahlil 1', kategori_id: 20 },
  { id: 35, name: 'EKG', kategori_id: 21 },
  { id: 36, name: 'EEG', kategori_id: 21 },
  { id: 37, name: 'EMR', kategori_id: 21 },
  { id: 38, name: 'Taksi', kategori_id: 22 },
  { id: 39, name: 'Gıda', kategori_id: 22 },
  { id: 40, name: 'Taksi', kategori_id: 23 },
  { id: 41, name: 'Gıda', kategori_id: 23 },
  { id: 42, name: 'Konaklama', kategori_id: 23 },
  { id: 43, name: 'Uçak', kategori_id: 23 },
  { id: 44, name: 'Havaş', kategori_id: 23 },
  { id: 45, name: 'Etik Kurul Ücreti', kategori_id: 24 },
  { id: 46, name: 'Sağlık Bakanlığı Onay Ücreti', kategori_id: 25 },
  { id: 47, name: 'Kuru Buz', kategori_id: 7 },
  { id: 48, name: 'Glukometre İğnesi', kategori_id: 7 },
  { id: 49, name: 'Medikal Kit', kategori_id: 8 },
  { id: 50, name: 'Santrifuj', kategori_id: 14 },
  { id: 51, name: 'MR', kategori_id: 21 },
  { id: 52, name: 'Hasta Sağlık Sigortası', kategori_id: 27 },
  { id: 53, name: 'Vizit Ulaşım - Ototbüs Ücreti', kategori_id: 28 },
  { id: 54, name: 'Vizit Ulaşım - Minibüs Ücreti', kategori_id: 28 },
  { id: 55, name: 'Vizit Gıda Ücreti', kategori_id: 28 },
  { id: 56, name: 'Vizit Ulaşım - Ototbüs Ücreti', kategori_id: 29 },
  { id: 57, name: 'Vizit Ulaşım - Minibüs Ücreti', kategori_id: 29 },
  { id: 58, name: 'Vizit Gıda Ücreti', kategori_id: 29 },
]

export const DEFAULT_CARPANLAR = [
  { id: 1, name: "Sayfa Sayısı", kategori_id: 1 },
  { id: 2, name: "Sayfa Ücreti", kategori_id: 1 },
  // { id: 3, name: "Merkez Sayısı", kategori_id: 2 },
  // { id: 4, name: "Sayfa Ücreti", kategori_id: 2 },
  { id: 5, name: "Etik Kurul Tutarı", kategori_id: 3 },
  { id: 6, name: "Merkez Sayısı", kategori_id: 3, pre_defination: "merkez_sayisi" },
  { id: 7, name: "Kullanım Sayısı",	kategori_id: 4 },
  { id: 8, name: "Ücret", kategori_id: 4 },
  { id: 9, name: "Merkez Sayısı", kategori_id: 4, pre_defination: "merkez_sayisi" },
  { id: 10, name: "Proje Danışmanlık Ücreti", kategori_id: 5 },
  { id: 11, name: "Araştırma Çalışma Süresi", kategori_id: 5, pre_defination: "proje_suresi" },
  { id: 12, name: "Kişi Sayısı", kategori_id: 6 },
  { id: 13, name: "Aylık İşçilik Ücreti", kategori_id: 6 },
  { id: 14, name: "Çalışma Süresi", kategori_id: 6, pre_defination: "proje_suresi" },
  // { id: 15, name: "Hasta Başına Vizit Sayısı", kategori_id: 7, pre_defination: "hasta_basina_vizit_sayisi" },
  // { id: 16, name: "Hedef Hasta Sayısı", kategori_id: 7, pre_defination: "hasta_sayisi" },
  // { id: 17, name: "Çalışma Süresi", kategori_id: 7, pre_defination: "proje_suresi" },
  // { id: 18, name: "Medikal Kit Birim Tutarı", kategori_id: 8 },
  // { id: 19, name: "Hedef Hasta Sayısı", kategori_id: 8, pre_defination: "hasta_sayisi" },
  // { id: 20, name: "Medikal Kit Sayısı", kategori_id: 8 },
  // { id: 21, name: "Çalışma Süresi", kategori_id: 8, pre_defination: "proje_suresi" },
  { id: 22, name: "Aylık Kutu Kullanım Sayısı", kategori_id: 9 },
  { id: 23, name: "Birim Maliyeti", kategori_id: 9 },
  { id: 24, name: "Çalışma Süresi", kategori_id: 9, pre_defination: "proje_suresi" },
  { id: 25, name: "Merkez Sayısı", kategori_id: 10, pre_defination: "merkez_sayisi" },
  { id: 26, name: "Doküman Adedi", kategori_id: 10 },
  { id: 27, name: "Doküman Birim Maliyeti", kategori_id: 10 },
  { id: 28, name: "Hedef Hasta Sayısı", kategori_id: 11, pre_defination: "hasta_sayisi" },
  { id: 29, name: "Doküman Birim Maliyeti", kategori_id: 11 },
  { id: 30, name: "Merkez Başına Aylık Kullanım", kategori_id: 12 },
  { id: 31, name: "Çalışma Süresi", kategori_id: 12, pre_defination: "proje_suresi" },
  { id: 32, name: "Kurye Gönderim Ücreti", kategori_id: 12 },
  { id: 33, name: "Hedef Hasta Sayısı", kategori_id: 13, pre_defination: "hasta_sayisi" },
  { id: 34, name: "Çalışma Süresi", kategori_id: 13, pre_defination: "proje_suresi" },
  { id: 35, name: "Adet Fiyatı", kategori_id: 13 },
  { id: 36, name: "Cihaz Sayısı", kategori_id: 14 },
  { id: 37, name: "Merkez Sayısı", kategori_id: 14, pre_defination: "merkez_sayisi" },
  { id: 38, name: "Cihaz Alım Ücreti", kategori_id: 14 },
  { id: 39, name: "Gönderim Ücreti", kategori_id: 14 },
  { id: 40, name: "Aylık Kullanım Sayısı", kategori_id: 15 },
  { id: 41, name: "Birim Maliyeti", kategori_id: 15 },
  { id: 42, name: "Çalışma Süresi", kategori_id: 15, pre_defination: "proje_suresi" },
  { id: 43, name: "Kişi Sayısı", kategori_id: 16 },
  { id: 44, name: "Kişi Başı Uçak Bileti Ücreti", kategori_id: 16 },
  { id: 45, name: "Kişi Başı Taksi Ücreti", kategori_id: 16 },
  { id: 46, name: "Hasta Başına Vizit Sayısı", kategori_id: 17, pre_defination: "hasta_basina_vizit_sayisi" },
  { id: 47, name: "Hasta Sayısı", kategori_id: 17, pre_defination: "hasta_sayisi" },
  { id: 48, name: "Çalışma Süresi", kategori_id: 17, pre_defination: "proje_suresi" },
  { id: 49, name: "Yol Ücreti", kategori_id: 17 },
  { id: 50, name: "Hedef Hasta Sayısı", kategori_id: 18, pre_defination: "hasta_sayisi" },
  { id: 51, name: "Hasta Başına Ödeme Tutarı", kategori_id: 18 },
  { id: 52, name: "Çalışma Süresi", kategori_id: 18, pre_defination: "proje_suresi" },
  { id: 53, name: "Merkez Sayısı", kategori_id: 18, pre_defination: "merkez_sayisi" },
  { id: 54, name: "Hekim Başına Aylık Ücret", kategori_id: 19 },
  { id: 55, name: "Araştırıcı Hekim Sayısı", kategori_id: 19 },
  { id: 56, name: "Çalışma Süresi", kategori_id: 19, pre_defination: "proje_suresi" },
  { id: 57, name: "Tahlil Ücreti", kategori_id: 20 },
  { id: 58, name: "Hedef Hasta Sayısı", kategori_id: 20, pre_defination: "hasta_sayisi" },
  { id: 59, name: "Hasta Başına Vizit Sayısı", kategori_id: 20, pre_defination: "hasta_basina_vizit_sayisi" },
  { id: 60, name: "Çalışma Süresi", kategori_id: 20, pre_defination: "proje_suresi" },
  { id: 61, name: "Hasta Başına Aylık Kullanım Ücreti", kategori_id: 21 },
  { id: 62, name: "Hedef Hasta Sayısı", kategori_id: 21, pre_defination: "hasta_sayisi" },
  { id: 63, name: "Merkez Sayısı", kategori_id: 21, pre_defination: "merkez_sayisi" },
  { id: 64, name: "Çalışma Süresi", kategori_id: 21, pre_defination: "proje_suresi" },
  { id: 65, name: "Birim Ücreti", kategori_id: 22 },
  { id: 66, name: "Adet Sayısı", kategori_id: 22 },
  { id: 67, name: "Merkez Sayısı", kategori_id: 22 },
  { id: 68, name: "Çalışma Süresi", kategori_id: 22 },
  { id: 69, name: "Birim Ücreti", kategori_id: 23 },
  { id: 70, name: "Adet Sayısı", kategori_id: 23 },
  { id: 71, name: "Merkez Sayısı", kategori_id: 23 },
  { id: 72, name: "Çalışma Süresi", kategori_id: 23 },
  { id: 73, name: "Birim Maliyeti", kategori_id: 24 },
  { id: 74, name: "Merkez Sayısı", kategori_id: 24, pre_defination: "merkez_sayisi" },
  { id: 75, name: "Hedef Hasta Sayısı", kategori_id: 24, pre_defination: "hasta_sayisi " },
  { id: 76, name: "Çalışma Süresi", kategori_id: 24, pre_defination: "proje_suresi" },
  { id: 77, name: "Birim Maliyeti", kategori_id: 25 },
  { id: 78, name: "Merkez Sayısı", kategori_id: 25, pre_defination: "merkez_sayisi" },
  { id: 79, name: "Hedef Hasta Sayısı", kategori_id: 25, pre_defination: "hasta_sayisi " },
  { id: 80, name: "Çalışma Süresi", kategori_id: 25, pre_defination: "proje_suresi" },
  { id: 81, name: "Birim Maliyeti", kategori_id: 26 },
  { id: 82, name: "Merkez Sayısı", kategori_id: 26, pre_defination: "merkez_sayisi" },
  { id: 83, name: "Hedef Hasta Sayısı", kategori_id: 26, pre_defination: "hasta_sayisi " },
  { id: 84, name: "Çalışma Süresi", kategori_id: 26, pre_defination: "proje_suresi" },
  { id: 85, name: "Aylık Sipariş Sayısı", kategori_id: 7 },
  { id: 86, name: "Sipariş Başına Ücret", kategori_id: 7 },
  { id: 87, name: "Proje Süresi", kategori_id: 7, pre_defination: "proje_suresi" },
  { id: 88, name: "Merkez Sayısı", kategori_id: 7, pre_defination: "merkez_sayisi" },
  { id: 89, name: "Proje Süresi", kategori_id: 8, pre_defination: "proje_suresi" },
  { id: 90, name: "Hedef Hasta Sayısı", kategori_id: 8, pre_defination: "hasta_sayisi" },
  { id: 91, name: "Gönderim + Alım Ücreti", kategori_id: 8 },
  { id: 92, name: "Hasta Başına Sigorta Ücreti", kategori_id: 27 },
  { id: 93, name: "Hasta Sayısı", kategori_id: 27 },
  { id: 94, name: "Çalışma Süresi", kategori_id: 27 },
  { id: 95, name: "Vizit Başına Kullanım Sayısı", kategori_id: 28 },
  { id: 96, name: "Kullanım Ücreti", kategori_id: 28 },
  { id: 97, name: "Merkez Sayısı", kategori_id: 28, pre_defination: "merkez_sayisi"  },
  { id: 98, name: "Çalışma Ücreti", kategori_id: 28 },
  { id: 99, name: "Vizit Başına Kullanım Sayısı", kategori_id: 29 },
  { id: 100, name: "Kullanım Ücreti", kategori_id: 29 },
  { id: 101, name: "Merkez Sayısı", kategori_id: 29, pre_defination: "merkez_sayisi"  },
  { id: 102, name: "Çalışma Ücreti", kategori_id: 29 },
  { id: 103, name: "Merkez Sayısı", kategori_id: 2, pre_defination: "merkez_sayisi"  },
  { id: 104, name: "Basım Adedi", kategori_id: 2 },
  { id: 105, name: "Birim Ücreti", kategori_id: 2 },
]
