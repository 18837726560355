import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projeler
import projeler from "./projeler/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./projeler/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//bilgilendirme
import Bilgilendirme from "./bilgilendirme/reducer";

//bilgilendirme
import PlanlamaVeTakvim from "./planlama-ve-takvim/reducer";

//operasyonel_takip
import OperasyonelTakip from "./operasyonel_takip/reducer";


export const getRootReducer = () => (
  combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    ecommerce,
    calendar,
    mails,
    crypto,
    invoices,
    projeler,
    tasks,
    contacts,
    Dashboard,
    DashboardSaas,
    Bilgilendirme,
    PlanlamaVeTakvim,
    OperasyonelTakip,
  }))

var rootReducer = getRootReducer()

export default rootReducer
