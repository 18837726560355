import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { CREATE_PERMISSION, CREATE_FILE, DELETE_FILE, GET_PROJECTS_FOLDER, CREATE_PROJECTS_FOLDER, GET_FOLDERS_FILES, GET_FILES_WORKFLOW, GET_WORKFLOW_SUCCESS,CREATE_OR_UPDATE_WORKFLOW, DELETE_PERMISSION } from "./actionTypes"

import {
  getProjectsFolderSuccessAction,
  getProjectsFolderFailAction,
  globalFailAction,
  getProjectsFolderAction,
  getFoldersFilesActionSuccess,
  getFoldersFileWorkFlowActionSuccess,
  getFoldersFileWorkFlowActionFailed,
  getFoldersFilesAction,
  formDoneStatusAction,
  deletePermissionAction,
} from "./actions"

//Include Both Helper File with needed methods
import { getProjectsFolderRequest, createProjectsFolderRequest, getFoldersFilesRequest, deleteFileRequest, createFileRequest, createPermissionRequest, deletePermissionRequest, getFoldersFileWorkFlowRequest,createWorkflowRequest } from "../../helpers/backend"


function* fetchProjectFolder({ payload }) {
  try {
    const response = yield call(getProjectsFolderRequest, payload)
    yield put(getProjectsFolderSuccessAction(payload.id, response))
  } catch (error) {
    yield put(getProjectsFolderFailAction(error))
  }
}

function* createWorkflow({payload }) {

  try {
    const workflow = {
      id: payload.id,
      assigned_user_id: payload.assigned_user_id,
      create_datetime: payload.create_datetime,
      delete_wf: payload.delete_wf,
    }
    const response = yield call(createWorkflowRequest, payload.file_id, workflow)
      console.log("response",response)
    if(response.status === true){
    const response = yield call(getFoldersFileWorkFlowRequest, payload.file_id)
    yield put(getFoldersFileWorkFlowActionSuccess(payload.file_id, response))
    }
    // yield put(getProjectsFolderAction(proj))
    // TODO:
  }
  catch (error) {
    console.log("error",error.response.data)
    yield put(getFoldersFileWorkFlowActionFailed(payload.file_id, error.response.data))
  }
}

function* createProjectsFolder({ payload }) {
  try {
    const response = yield call(createProjectsFolderRequest, payload.project_id, payload.name)
    const proj = {
      id: payload.project_id,
    }
    yield put(getProjectsFolderAction(proj))
    // TODO:
  }
  catch (error) {
    yield put(globalFailAction("Unexpected error while create projects folder: " + error.message)) // TODO:
  }
}


function* fetchFoldersFiles({ payload }) {
  try {
    const response = yield call(getFoldersFilesRequest, payload.folder_id)
    yield put(getFoldersFilesActionSuccess(payload.folder_id, response))
  }
  catch (error) {
    yield put(globalFailAction("Unexpected error while fetching folders. " + error.message)) // TODO:
  }
}

function* fetchFileWorkFlow({ payload }) {
  try {
    const response = yield call(getFoldersFileWorkFlowRequest, payload.file_id)
    yield put(getFoldersFileWorkFlowActionSuccess(payload.file_id, response))
  }
  catch (error) {
    yield put(globalFailAction("Unexpected error while fetching folders. " + error.message)) // TODO:
  }
}

function* deleteFile({ payload }) {
  try {
    yield call(deleteFileRequest, payload.file_id)
    yield put(getFoldersFilesAction(payload.folder_id))
  } catch (error) {
    yield put(globalFailAction("Unexpected error while delete file. " + error.message)) // TODO:
  }
}


function* createFile({ payload }) {
  try {
    yield call(
      createFileRequest,
      payload.folder_id,
      payload.file_type,
      payload.version_note,
      payload.file,
      payload.name
    )
    yield put(getFoldersFilesAction(payload.folder_id))
    yield put(formDoneStatusAction(true))
    yield put(formDoneStatusAction(true))
  }
  catch (error) {
    yield put(globalFailAction("Error on create file. " + error.message)) // TODO:
  }
}

function* createPermission({ payload }) {
  try {
    yield call(
      createPermissionRequest,
      payload.file_id,
      payload.user_type,
    )
    yield put(getFoldersFilesAction(payload.folder_id))
  }
  catch (error) {
    yield put(globalFailAction("Error on create permission. " + error.message)) // TODO:
  }
}

function* deletePermission({ payload }) {
  try {
    yield call(
      deletePermissionRequest,
      payload.file_id,
      payload.user_type,
    )
    yield put(getFoldersFilesAction(payload.folder_id))
  }
  catch (error) {
    yield put(globalFailAction("Error on create permission. " + error.message)) // TODO:
  }
}


function* contactsSaga() {
  yield takeEvery(GET_PROJECTS_FOLDER, fetchProjectFolder)
  yield takeEvery(CREATE_PROJECTS_FOLDER, createProjectsFolder)
  yield takeEvery(CREATE_OR_UPDATE_WORKFLOW, createWorkflow)
  yield takeEvery(GET_FOLDERS_FILES, fetchFoldersFiles)
  yield takeEvery(GET_FILES_WORKFLOW, fetchFileWorkFlow)
  yield takeEvery(DELETE_FILE, deleteFile)
  yield takeEvery(CREATE_FILE, createFile)
  yield takeEvery(CREATE_PERMISSION, createPermission)
  yield takeEvery(DELETE_PERMISSION, deletePermission)
}



export default contactsSaga;
