import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap'

//import action
import { getChartsData as onGetChartsData } from '../../store/actions'

// Pages Components
import MonthlyEarning from './MonthlyEarning'
import SocialSource from './SocialSource'
import ActivityComp from './ActivityComp'
import TopCities from './TopCities'
import LatestTranaction from './LatestTranaction'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

//i18n
import { withTranslation } from 'react-i18next'

//redux
import { useDispatch } from 'react-redux'

const Dashboard = props => {
  const reports = [
    { title: 'Orders', iconClass: 'bx-copy-alt', description: '1,235' },
    { title: 'Revenue', iconClass: 'bx-archive-in', description: '$35, 723' },
    {
      title: 'Average Price',
      iconClass: 'bx-purchase-tag-alt',
      description: '$16.2',
    },
  ]

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData('yearly'))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbItem={props.t('Dashboard')} />

          <Row>
            <Col xl="4">
              <MonthlyEarning />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={'_col_' + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">{report.title}</p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  'bx ' + report.iconClass + ' font-size-24'
                                }></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
