import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

// import Component
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { useDispatch } from 'react-redux'

import { CardText, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

import { Input, CardTitle } from 'reactstrap'

import { getSurecTakiplerAction, getPatientGroupAction, setPatientGroupAction } from '../../store/actions'
import { getVisitFollowAction } from '../../store/actions'

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ; (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
const downloadPDF = (divid, docname) => async () => {
  const pdf = new jsPDF("portrait", "pt", "a4");
  const data = await html2canvas(document.querySelector("#" + divid), {
    scale: 2,
    onclone: (document, element) => {
      element.style.setProperty("zoom", (1 / window.devicePixelRatio * 175) + "%")
    }
  });
  const img = data.toDataURL("image/png");
  const imgProperties = pdf.getImageProperties(img);
  const pdfWidth = pdf.internal.pageSize.getWidth() - 45;
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  pdf.addImage(img, "PNG", 20, 20, pdfWidth, pdfHeight);
  pdf.save(docname + ".pdf");
}
const generateDocument = () => {
  loadFile(doc, function (error, content) {
    if (error) {
      throw error
    }
    const zip = new PizZip(content)
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    })
    try {
      doc.render(get_doc_fields())
    } catch (error) {
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object.getOwnPropertyNames(value).reduce(function (error, key) {
            error[key] = value[key]
            return error
          }, {})
        }
        return value
      }
      console.log(JSON.stringify({ error: error }, replaceErrors))

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error) {
            return error.properties.explanation
          })
          .join('\n')
        console.log('errorMessages', errorMessages)
      }
      throw error
    }
    // SAVE DOWNLOAD INFO
    dispatch(newVisitFollowAction(current_proje.id, selected_center.id))
    // SAVE DOWNLOAD INFO END
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }) //Output the document using Data-URI
    saveAs(out, 'output.docx')
  })
}

const Index = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')
  console.log(user_type);

  // STORE
  const { projeler, patient_group, operasyonel_takip, visit_follows } = useSelector(state => ({
    projeler: state.contacts.projeler,
    patient_group: state.OperasyonelTakip.patient_group,
    operasyonel_takip: state.OperasyonelTakip.process_follows,
    visit_follows: state.OperasyonelTakip.visit_follows,
  }))

  // TAB
  const [activeTab, setActiveTab] = useState('1')
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // States
  const [current_proje, setCurrentProje] = useState({})
  const [current_patient_group, set_current_patient_group] = useState({})

  // visit
  useEffect(() => {
    if (current_proje && current_proje.id) dispatch(getVisitFollowAction(current_proje.id))
  }, [current_proje])

  const [arranged_visit_follows, set_arranged_visit_follow] = useState([])
  useEffect(() => {
    let grouped = groupBy(visit_follows, 'center')
    let arranged = []

    for (const i of Object.keys(grouped)) {
      arranged.push({
        center_name: grouped[i][0].center_name,
        count: grouped[i].length,
      })
    }

    set_arranged_visit_follow(arranged)
  }, [visit_follows])

  // set current proje

  useEffect(() => {
    if (patient_group) {
      set_current_patient_group(patient_group)
    }
  }, [patient_group, current_proje])

  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  useEffect(() => {
    if (current_proje !== undefined && current_proje.id !== undefined) {
      dispatch(getPatientGroupAction(current_proje.id))
    }
  }, [current_proje])

  useEffect(() => {
    if (current_proje !== undefined && current_proje.id !== undefined) {
      dispatch(getSurecTakiplerAction(current_proje.id))
    }
  }, [current_proje])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - İstatistik</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="İstatistik" breadcrumbItem={current_proje ? current_proje.name : ''} />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom nav-justified" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === '1',
                            })}
                            onClick={() => {
                              toggleTab('1')
                            }}>
                            Süreç İstatistikleri
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === '2',
                            })}
                            onClick={() => {
                              toggleTab('2')
                            }}>
                            Hasta İstatistikleri
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === '3',
                            })}
                            onClick={() => {
                              toggleTab('3')
                            }}>
                            Vizit İstatistikleri
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab} className="p-3 text-muted">
                        <TabPane tabId="1">
                          <div id="tabpane1">
                            {operasyonel_takip ? (
                              Object.keys(operasyonel_takip).map(surec_name =>
                                Object.keys(operasyonel_takip[surec_name]).map(kategori_name => (
                                  <React.Fragment>
                                    <CardTitle className="mt-4">
                                      {operasyonel_takip[surec_name][kategori_name].category.name}
                                    </CardTitle>
                                    {operasyonel_takip[surec_name][kategori_name].follows.map(follow => (
                                      <Row className="mt-3">
                                        <Col>
                                          <Input value={follow.name} />
                                        </Col>
                                        <Col>
                                          <Input value={follow.value} disabled />
                                        </Col>
                                      </Row>
                                    ))}
                                  </React.Fragment>
                                ))
                              )
                            ) : (
                              <></>
                            )}
                            {/* <Row className="mt-3">
                            <Col>
                              <Button type="button" color="success" onClick={generateDocument}>
                                Word Dosyasını İndir
                              </Button>
                            </Col>
                          </Row> */}
                          </div>
                          {user_type == 'PM' ? (
                            < Row >
                              <Col>
                                <Button type="button" color="success" onClick={downloadPDF("tabpane1", "Surec_Istatistikleri")}>
                                  PDF
                                </Button>
                              </Col>
                            </Row>) : (
                            <></>
                          )}
                        </TabPane>
                        <TabPane tabId="2">
                          <div id="tabpane2">
                            <Row className="mt-4">
                              <Col></Col>
                              <Col>
                                <CardTitle>Taranan Hasta Sayısı</CardTitle>
                              </Col>
                              <Col>
                                <CardTitle>Devam Eden Hasta Sayısı</CardTitle>
                              </Col>
                              <Col>
                                <CardTitle>Ayrılan Hasta Sayısı</CardTitle>
                              </Col>
                              <Col>
                                <CardTitle>Takip Edilen Hasta Sayısı</CardTitle>
                              </Col>
                            </Row>
                            {current_patient_group ? (
                              Object.keys(current_patient_group).map(pat_gr_id => (
                                <Row className="mt-3">
                                  <Col>
                                    <CardTitle>{current_patient_group[pat_gr_id].center_name}</CardTitle>
                                  </Col>
                                  <Col>
                                    <Input value={current_patient_group[pat_gr_id].screened_count} disabled />
                                  </Col>
                                  <Col>
                                    <Input value={current_patient_group[pat_gr_id].continuing_count} disabled />
                                  </Col>
                                  <Col>
                                    <Input value={current_patient_group[pat_gr_id].leaving_count} disabled />
                                  </Col>
                                  <Col>
                                    <Input value={current_patient_group[pat_gr_id].followed_count} disabled />
                                  </Col>
                                </Row>
                              )
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                          {user_type == 'PM' ? (
                            < Row >
                              <Col>
                                <Button type="button" color="success" onClick={downloadPDF("tabpane2", "Hasta_Istatistikleri")}>
                                  PDF
                                </Button>
                              </Col>
                            </Row>) : (
                            <></>
                          )}
                        </TabPane>
                        <TabPane tabId="3">

                          <div id="tabpane3">
                            <Row>
                              <Col></Col>
                              <Col>
                                <CardTitle>Kaynak Döküman Üreten Hasta Sayısı</CardTitle>
                              </Col>
                              <Col></Col>
                            </Row>

                            {arranged_visit_follows.map(visit_follow => (
                              <Row className="mt-4">
                                <Col>
                                  <CardTitle>{visit_follow.center_name}</CardTitle>
                                </Col>
                                <Col>
                                  <Input value={visit_follow.count} disabled />
                                </Col>
                                <Col></Col>
                              </Row>
                            ))}
                          </div>
                          {user_type == 'PM' ? (

                            < Row >
                              <Col>
                                <Button type="button" color="success" onClick={downloadPDF("tabpane3", "Vizit_Istatistikleri")}>
                                  PDF
                                </Button>
                              </Col>
                            </Row>) : (
                            <></>
                          )}
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div >
    </React.Fragment >
  )
}
export default Index
