import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'
import { NavLink, Link } from 'react-router-dom'
import { getProjects } from 'store/projeler/actions'

//i18n
import { withTranslation } from 'react-i18next'

const SidebarContentOrg = props => {
  const dispatch = useDispatch()

  // Projeler THINGS
  const { projeler, projects_get } = useSelector(state => ({
    projeler: state.projeler.projeler,
    projects_get: state.projeler.projects_get,
  }))

  useEffect(() => {
    if (projects_get == false) {
      dispatch(getProjects())
    }
  }, [dispatch, projeler])

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')

  const forceUpdate = React.useReducer(() => ({}))[1]

  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu('#side-menu')

      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByClassName('kadir-link')
      // const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (items[i].href.includes(pathName)) {
          matchingMenuItem = items[i]
        }
      }

      deactivateAll()
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, projeler])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        item.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function deactivateAll() {
    let items = document.getElementsByTagName('LI')

    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove('active')
      items[i].classList.remove('mm-show')
      items[i].classList.remove('mm-active')
    }

    items = document.getElementsByTagName('UL')
    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove('active')
      items[i].classList.remove('mm-show')
      items[i].classList.remove('mm-active')
      items[i].classList.remove('mm-collapsing')
      items[i].classList.add('mm-collapse')
    }

    items = document.getElementsByTagName('A')
    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove('active')
      items[i].classList.remove('mm-show')
      items[i].classList.remove('mm-active')
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul

          if (parent4 && !parent4.classList.contains('metismenu')) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {user_type == 'ADM' || user_type == 'PM' ? (
              <>
                <li className="menu-title">{props.t('Yönetim')} </li>
                <li>
                  <NavLink to="#">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t('Yönetim')}</span>
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <NavLink className="kadir-link" to="/projeler">
                        {props.t('Proje Yönetimi')}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <></>
            )}

            <li className="menu-title">{props.t('Projeler')}</li>
            {projeler.map(proje => (
              <li key={proje.id} className="">
                <a aria-current="page" className="has-arrow" aria-expanded="false">
                  <i className="bx bx-store"></i>
                  <span>{props.t(proje.name)}</span>
                </a>

                <ul className="sub-menu mm-collapse" aria-expanded="false">
                  <li>
                    <NavLink className="kadir-link" key={proje.id} to={'/bilgilendirme/'.concat(proje.id)}>
                      {props.t('Bilgilendirme')}
                    </NavLink>
                  </li>
                  <li className={user_type == 'ADM' || user_type == 'PM' ? '' : 'd-none'}>
                    <NavLink className="kadir-link" to={'/fiziblite/' + proje.id}>
                      {props.t('Fiziblite')}
                    </NavLink>
                  </li>
                  <li
                    className={
                      user_type == 'ADM' ||
                        user_type == 'PM' ||
                        user_type == 'SPS' ||
                        user_type == 'CRA' ||
                        user_type == 'DCT' ||
                        user_type == 'SC'
                        ? ''
                        : 'd-none'
                    }>
                    <NavLink className="kadir-link" key={proje.id} to={'/planlama-ve-takvim/'.concat(proje.id)}>
                      {props.t('Planlama ve Takvim')}
                    </NavLink>
                  </li>
                  <li>
                    <Link>
                      <span className="badge rounded-pill badge-soft-success float-end" key="t-new">
                        {props.t('>')}
                      </span>
                      <span key="t-email-templates">{props.t('Operasyonel Takip')}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link className="kadir-link" to={'/surec-takibi/'.concat(proje.id)}>
                          {props.t('Süreç Takibi')}
                        </Link>
                      </li>
                      <li
                        className={
                          user_type == 'ADM' ||
                            user_type == 'SPS' ||
                            user_type == 'PM' ||
                            user_type == 'CRA' ||
                            user_type == 'SC'
                            ? ''
                            : 'd-none'
                        }>
                        <Link className="kadir-link" to={'/visit-islemleri/'.concat(proje.id)}>
                          {props.t('Vizit İşlemleri')}{' '}
                        </Link>
                      </li>
                      <li>
                        <Link className="kadir-link" to={'/hasta-gruplari/'.concat(proje.id)}>
                          {props.t('Hasta Grupları')}{' '}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink className="kadir-link" key={proje.id} to={'/istatistik/'.concat(proje.id)}>
                      {props.t('İstatistik')}
                    </NavLink>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContentOrg.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const Sidebar = props => {
  // Projeler THINGS
  const { projeler, projects_get } = useSelector(state => ({
    projeler: state.projeler.projeler,
    projects_get: state.projeler.projects_get,
  }))

  return (
    <React.Fragment key={props.location.pathname + projeler.length}>
      <SidebarContentOrg {...props} key={props.location.pathname + projeler.length} />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Sidebar))
