import MetaTags from 'react-meta-tags'

import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  ModalBody,
} from 'reactstrap'
import { Card, CardBody, Container } from 'reactstrap'
import classnames from 'classnames'
import {
  getSurecTakiplerAction,
  setFollowAction,
  deleteProcessFollowAction,
  newProcessFollowAction,
} from '../../store/actions'
import { closeModalAction } from '../../store/planlama-ve-takvim/actions'

const TabData = props => {
  const dispatch = useDispatch()

  const { close_modal } = useSelector(state => ({
    close_modal: state.PlanlamaVeTakvim.close_modal,
  }))

  // states
  const [category_data, set_category_data] = useState(undefined)
  const [changed_follows, set_changed_follows] = useState({})

  const add_changed_follows = (follow_id, name, value) => {
    let new_changed_follows = { ...changed_follows }
    let data = { follow_id: follow_id, name: name, value: value }

    new_changed_follows[follow_id] = data
    set_changed_follows(new_changed_follows)
  }

  useEffect(() => {
    set_category_data(props.category_data)
  }, [props.category_data])

  //
  const set_follow = (category_id, follow_id, name, value) => {
    // dispatch(setFollowAction(follow_id, name, value, props.current_proje.id))
    let copy_data = { ...category_data }
    const found = copy_data[category_id].follows.find(element => element.id == follow_id)
    found.name = name
    found.value = value
    set_category_data(copy_data)
    add_changed_follows(follow_id, name, value)
  }

  const save_changed = () => {
    Object.keys(changed_follows).map(cf_id => {
      let changed_follow = changed_follows[cf_id]
      dispatch(
        setFollowAction(
          changed_follow.follow_id,
          changed_follow.name,
          changed_follow.value,
          props.current_proje.id
        )
      )
      set_changed_follows({})
    })
  }

  //

  const delete_follow = follow_id => {
    if (confirm('Silmek istediğinize emin misiniz?')) {
      dispatch(deleteProcessFollowAction(follow_id, props.current_proje.id))
    }
  }

  // model
  const [modal_opened, set_modal_opened] = useState(false)
  const [new_follow_name, set_new_follow_name] = useState('')
  const [new_follow_category_id, set_new_follow_category_id] = useState('')
  const handle_new_follow = () => {
    dispatch(
      newProcessFollowAction(
        new_follow_name,
        new_follow_category_id,
        props.current_proje.id
      )
    )
    set_new_follow_name('')
  }
  useEffect(() => {
    if (close_modal === true) {
      set_modal_opened(false)
      dispatch(closeModalAction(false))
    }
  }, [close_modal])

  // render

  if (category_data === undefined || Object.keys(category_data).length === 0) return <></>
  return (
    <React.Fragment>
      {Object.keys(category_data).map(cat_id => (
        <div key={cat_id} className="row">
          <Row>
            <Col sm={8}>
              <div className="mt-3">
                <Label htmlFor="formFile" className="form-label">
                  {category_data[cat_id].category.name}
                </Label>

                {category_data[cat_id].follows.map(follow_data => (
                  <Row key={follow_data.id} className="mb-3">
                    <Col md={6}>
                      <div>
                        <Input
                          className="form-control"
                          type="text"
                          id="formFile"
                          value={follow_data.name}
                          onChange={e =>
                            set_follow(
                              cat_id,
                              follow_data.id,
                              e.target.value,
                              follow_data.value
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <i
                        className="mdi mdi-delete"
                        style={{ cursor: 'pointer', fontSize: '23px' }}
                        onClick={delete_follow.bind(this, follow_data.id)}></i>
                    </Col>
                    <Col md={3}>
                      <div>
                        <Input
                          className="form-control"
                          type="number"
                          id="formFile"
                          value={follow_data.value}
                          onChange={e =>
                            set_follow(
                              cat_id,
                              follow_data.id,
                              follow_data.name,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
              <Row>
                <Col md={2}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      set_modal_opened(!modal_opened)
                      set_new_follow_category_id(cat_id)
                    }}>
                    Ekle
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ))}
      <Col md={2} className="mt-5">
        <button
          className="btn btn-success"
          type="button"
          style={{ cursor: 'pointer' }}
          onClick={save_changed}
          disabled={Object.keys(changed_follows).length === 0 ? true : false}>
          kaydet
        </button>
      </Col>

      {/* NEW */}

      <Modal
        isOpen={modal_opened}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          set_modal_opened(!modal_opened)
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              set_modal_opened(!modal_opened)
            }}>
            Yeni Surec Takip
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <Label>İsim</Label>
              <Input
                type="text"
                className="form-control"
                value={new_follow_name}
                onChange={e => {
                  set_new_follow_name(e.target.value)
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                set_modal_opened(!modal_opened)
              }}>
              Kapat
            </Button>
            <Button type="button" color="primary" onClick={handle_new_follow}>
              Ekle
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const APP = props => {
  const dispatch = useDispatch()

  // STORE
  const { projeler, operasyonel_takip } = useSelector(state => ({
    projeler: state.contacts.projeler,
    operasyonel_takip: state.OperasyonelTakip.process_follows,
  }))

  // States
  const [current_proje, setCurrentProje] = useState({})
  const [customIconActiveTab, setcustomIconActiveTab] = useState('1')

  const toggleCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }

  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  useEffect(() => {
    if (current_proje !== undefined && current_proje.id !== undefined) {
      dispatch(getSurecTakiplerAction(current_proje.id))
    }
  }, [current_proje])

  ///

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - Süreç Takibi</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Süreç Takibi"
            /* TODO  */
            breadcrumbItem={current_proje ? current_proje.name : ''}
          />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                {/* FileRightBar  */}

                <div className="w-100">
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: customIconActiveTab === '1',
                            })}
                            onClick={() => {
                              toggleCustom('1')
                            }}>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Başlatma Süreci</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: customIconActiveTab === '2',
                            })}
                            onClick={() => {
                              toggleCustom('2')
                            }}>
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Devamlılık Süreci</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: customIconActiveTab === '3',
                            })}
                            onClick={() => {
                              toggleCustom('3')
                            }}>
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Sonlandırma Süreci</span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      {/* CONTENT */}

                      <TabContent
                        activeTab={customIconActiveTab}
                        className="p-3 text-muted">
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <TabData
                                current_proje={current_proje}
                                category_data={operasyonel_takip.STR}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <TabData
                              current_proje={current_proje}
                              category_data={operasyonel_takip.PRS}
                            />
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <TabData
                              current_proje={current_proje}
                              category_data={operasyonel_takip.END}
                            />
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default APP
