import classnames from 'classnames'
import React, { useEffect, useState, useRef } from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Col, Row, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Card, CardBody, CardText, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import Flow from './Flow'
import {
  getProjectsCentersAction,
  getCentersPaymentsAction,
  closeModalAction,
  newCentersPaymentsAction,
  deleteCentersPaymentsAction,
  getPaymentFlowsAction,
} from '../../store/planlama-ve-takvim/actions'

function getFileName(fullPath) {
  if (fullPath) {
    var startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')
    var filename = fullPath.substring(startIndex)
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1)
    }
    return filename
  }
}

const APP = props => {
  const [current_centers, set_current_centers] = useState(null)
  const clickedButtonRef = useRef({});

  const dispatch = useDispatch()

  const [showPaymentFlow, setShowPaymentTab] = useState(false)
  const [current_payment_id, setCurrentPaymentId] = useState("")
  // TODO:
  const { projects_centers, close_modal, centers_payments, payment_flows } = useSelector(state => ({
    projects_centers: state.PlanlamaVeTakvim.projects_centers,
    close_modal: state.PlanlamaVeTakvim.close_modal,
    centers_payments: state.PlanlamaVeTakvim.centers_payments,
    payment_flows: state.PlanlamaVeTakvim.payment_flows ? state.PlanlamaVeTakvim.payment_flows : [],
  }))
  const [current_payment_flows, set_current_payment_flow] = useState([])
  const [currentSubTitle, setCurrentSubTitle] = useState("")

  const get_centers = () => {
    if (props.current_proje && props.current_proje.id) {
      dispatch(getProjectsCentersAction(props.current_proje.id))
    }
  }

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  useEffect(() => {
    if (payment_flows) {
      set_current_payment_flow(payment_flows)
    }
  }, [payment_flows])

  const [user_type, setUserType] = useState('DCT')

  useEffect(() => {
    get_centers()
    if (props.current_proje) {
      setShowPaymentTab(false)
      set_current_centers(projects_centers[props.current_proje.id])

    }
  }, [props.current_proje])


  useEffect(() => {
    if (props.current_proje) {
      setShowPaymentTab(false)
      set_current_centers(projects_centers[props.current_proje.id])
    }
  }, [projects_centers, props.current_proje])

  useEffect(() => {
    if (current_centers && current_centers.length > 0) {
      toggleVertical(current_centers[0].id)
    }
  }, [current_centers])

  ///
  const [is_uploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (close_modal === true) {
      set_modal_opened(false)
      dispatch(closeModalAction(false))
      setIsUploading(false)
    }
  }, [close_modal])

  ///
  const [active_center_payments, set_active_center_payments] = useState([])
  const [active_center_id, set_active_center_id] = useState(null)
  const [new_payment_name, set_new_payment_name] = useState(null)

  useEffect(() => {
    if (centers_payments) {
      set_active_center_payments(centers_payments[active_center_id])
      setShowPaymentTab(false)
    }
  }, [centers_payments])

  const toggleVertical = tab => {
    if (active_center_id !== tab) {
      set_active_center_id(tab)
    }
  }

  const get_centers_payments = () => {
    if (active_center_id) {
      dispatch(getCentersPaymentsAction(active_center_id))
    }
  }

  useEffect(() => {
    get_centers_payments()
  }, [active_center_id])

  const [modal_opened, set_modal_opened] = useState(false)

  const handle_new_payment = () => {
    let $file = document.querySelector('#uploadFile')
    let full_file_name = getFileName($file.value)

    var reader = new FileReader()
    reader.readAsDataURL($file.files[0])
    reader.onload = function () {
      dispatch(newCentersPaymentsAction(active_center_id, new_payment_name, reader.result, full_file_name))
    }
    setIsUploading(true)
  }

  const delete_payment = (center_id, payment_id) => {
    if (confirm('Emin misiniz?')) {
      dispatch(deleteCentersPaymentsAction(center_id, payment_id))
    }
  }
  const get_payment_flows = (payment_id, proje_id) => {
    dispatch(getPaymentFlowsAction(payment_id, proje_id))
    if (payment_flows) {
      setCurrentPaymentId(payment_id)
      setShowPaymentTab(true)
      set_current_payment_flow(payment_flows)
    }
  }

  useEffect(() => {

    if (payment_flows) {
      set_current_payment_flow(payment_flows)
      setCurrentSubTitle("Ödeme Akışları")
    }
  }, [payment_flows, props])


  return (
    <React.Fragment>
      <Row>
        {current_centers && current_centers.length ? (
          <></>
        ) : (
          <>
            <h4 className="mt-3">Lütfen öncelikle merkezleri oluşturun</h4>
          </>
        )}
        <Col xs={12}>
          <Row>
            <Col md="3">
              <Nav pills className="flex-column">
                {current_centers ? (
                  current_centers.map(center => (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          'mb-2': true,
                          active: active_center_id === center.id,
                        })}
                        onClick={() => {
                          toggleVertical(center.id)
                        }}>
                        {center.name}
                      </NavLink>
                    </NavItem>
                  ))
                ) : (
                  <></>
                )}
              </Nav>
            </Col>
            <Col md="9">
              {current_centers ? (
                current_centers.map((center, index) => (
                  <TabContent activeTab={active_center_id} className="text-muted mt-4 mt-md-0">
                    <TabPane tabId={center.id}>
                      <h4>{center.name}</h4>
                      {active_center_payments ? (
                        active_center_payments.map(payment => (
                          <Row className="mt-3">
                            <Col xs={4}>
                              <Input type="text" value={payment.name} disabled />
                            </Col>
                            <Col xs={4} className="d-flex align-items-center">
                              <a href={payment.file}>{payment.file_name}</a>
                            </Col>
                            <Col xs={4} className="d-flex justify-content-center">
                              {user_type == 'ADM' || user_type == 'PM' || user_type == 'CRA' || user_type == 'SC' || user_type == 'SPS' ? (
                                <Button ref={ref => clickedButtonRef.current[payment.id] = ref} onClick={get_payment_flows.bind(this, payment.id, props.current_proje.id)} id={`button-${payment.id}`} color="primary">
                                  Ödeme Akışı
                                </Button>
                              ) : (
                                ''
                              )}

                              <Button onClick={delete_payment.bind(this, center.id, payment.id)} color="danger">
                                Sil
                              </Button>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <></>
                      )}
                      <Row className="mt-3">
                        <Col>
                          {user_type == 'ADM' || user_type == 'PM' || user_type == 'CRA' ? (
                            <Button
                              color="success"
                              onClick={() => {
                                set_modal_opened(!modal_opened)
                              }}>
                              Ekle
                            </Button>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                ))
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {props.current_proje ? (
            <>
              <Row className="mt-3">
                <Col>
                  <Flow payment_flows={current_payment_flows} showPayment={showPaymentFlow} project_id={props.current_proje.id} payment_id={current_payment_id} clickedButtonRef={clickedButtonRef.current} currentSubTitle={currentSubTitle} />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {/* NEW */}

      <Modal
        isOpen={modal_opened}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          set_modal_opened(!modal_opened)
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              set_modal_opened(!modal_opened)
            }}>
            Yeni Merkez
          </ModalHeader>

          <ModalBody>
            {!is_uploading ? (
              <>
                <div className="mb-3">
                  <Label>Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    value={new_payment_name}
                    onChange={e => {
                      set_new_payment_name(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input type="file" className="form-control-file" id="uploadFile" />
                </div>
              </>
            ) : (
              <p>Uploading, please wait...</p>
            )}
          </ModalBody>
          <ModalFooter>
            {!is_uploading ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    set_modal_opened(!modal_opened)
                  }}>
                  Kapat
                </Button>
                <Button type="button" color="primary" onClick={handle_new_payment}>
                  Ekle
                </Button>
              </>
            ) : (
              <></>
            )}
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default APP
