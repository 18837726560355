export const GET_PROJECTS_VISITS = "GET_PROJECTS_VISITS"
export const GET_PROJECTS_VISITS_SUCCESS = "GET_PROJECTS_VISITS_SUCCESS"

export const DELETE_PROJECTS_VISITS = "DELETE_PROJECTS_VISITS"

export const UPDATE_PROJECTS_VISIT_DURATION = "UPDATE_PROJECTS_VISIT_DURATION"

export const NEW_PROJECTS_VISIT = "NEW_PROJECTS_VISIT"

// Monitorization

export const GET_PROJECTS_MONITORIZATIONS = "GET_PROJECTS_MONITORIZATIONS"
export const GET_PROJECTS_MONITORIZATIONS_SUCCESS = "GET_PROJECTS_MONITORIZATIONS_SUCCESS"

export const DELETE_PROJECTS_MONITORIZATIONS = "DELETE_PROJECTS_MONITORIZATIONS"

export const UPDATE_PROJECTS_MONITORIZATION_DURATION = "UPDATE_PROJECTS_MONITORIZATION_DURATION"

export const NEW_PROJECTS_MONITORIZATION = "NEW_PROJECTS_MONITORIZATION"

// Centers

export const GET_PROJECTS_CENTERS = "GET_PROJECTS_CENTERS"
export const GET_PROJECTS_CENTERS_SUCCESS = "GET_PROJECTS_CENTERS_SUCCESS"

export const DELETE_PROJECTS_CENTERS = "DELETE_PROJECTS_CENTERS"

export const UPDATE_PROJECTS_CENTER_NAME = "UPDATE_PROJECTS_CENTER_NAME"

export const NEW_PROJECTS_CENTER = "NEW_PROJECTS_CENTER"

// devices

export const GET_CENTERS_DEVICES = "GET_CENTERS_DEVICES"
export const GET_CENTERS_DEVICES_SUCCESS = "GET_CENTERS_DEVICES_SUCCESS"
export const NEW_CENTERS_DEVICES = "NEW_CENTERS_DEVICES"
export const UPDATE_DEVICE_DATA = "UPDATE_DEVICE_DATA"
export const DELETE_CENTERS_DEVICES = "DELETE_CENTERS_DEVICES"

export const GET_CENTERS_PAYMENTS = "GET_CENTERS_PAYMENTS"
export const GET_CENTERS_PAYMENTS_SUCCESS = "GET_CENTERS_PAYMENTS_SUCCESS"
export const NEW_CENTERS_PAYMENTS = "NEW_CENTERS_PAYMENTS"
export const DELETE_CENTERS_PAYMENTS = "DELETE_CENTERS_PAYMENTS"


export const CLOSE_MODAL = "CLOSE_MODAL"

export const GET_CENTERS_PAYMENTS_FLOW = "GET_CENTERS_PAYMENTS_FLOW"
export const GET_CENTERS_PAYMENTS_FLOW_SUCCESS = "GET_CENTERS_PAYMENTS_FLOW_SUCCESS"

export const NEW_PROFORMA = "NEW_PROFORMA"
export const NEW_PROFORMA_SUCCESS = "NEW_PROFORMA_SUCCESS"

export const NEW_PAY_FLOW = "NEW_PAY_FLOW"
export const NEW_PAY_FLOW_SUCCESS = "NEW_PAY_FLOW_SUCCESS"
