import {
  GET_SUREC_TAKIPLER,
  GET_SUREC_TAKIPLER_SUCCESS,
  SET_PROCESS_FOLLOW,
  DELETE_PROCESS_FOLLOW,
  NEW_PROCESS_FOLLOW,
  GET_PATIENT_GROUP,
  GET_PATIENT_GROUP_SUCCESS,
  SET_PATIENT_GROUP,
  NEW_VISIT_FOLLOW,
  GET_VISIT_FOLLOW,
  GET_VISIT_FOLLOW_SUCCESS,
} from "./actionTypes"


export const getSurecTakiplerAction = (project_id) => ({
  type: GET_SUREC_TAKIPLER,
  payload: { project_id: project_id },
})

export const getSurecTakiplerSuccessAction = (project_id, response) => ({
  type: GET_SUREC_TAKIPLER_SUCCESS,
  payload: { project_id: project_id, response: response },
})


export const setFollowAction = (follow_id, name, value, project_id) => ({
  type: SET_PROCESS_FOLLOW,
  payload: {
    name: name,
    value: value,
    follow_id: follow_id,
    project_id: project_id,
  }
})

export const deleteProcessFollowAction = (follow_id, project_id) => ({
  type: DELETE_PROCESS_FOLLOW,
  payload: {
    follow_id: follow_id,
    project_id: project_id,
  }
})

export const newProcessFollowAction = (name, category_id, project_id) => ({
  type: NEW_PROCESS_FOLLOW,
  payload: {
    name: name,
    project_id: project_id,
    category_id: category_id,
  }
})

export const getPatientGroupAction = (project_id) => ({
  type: GET_PATIENT_GROUP,
  payload: {
    project_id: project_id,
  }
})


export const getPatientGroupSuccessAction = (project_id, response) => ({
  type: GET_PATIENT_GROUP_SUCCESS,
  payload: {
    response: response,
    project_id: project_id,
  }
})

export const setPatientGroupAction = (patient_group_id, project_id, changed_vals) => ({
  type: SET_PATIENT_GROUP,
  payload: {
    patient_group_id: patient_group_id,
    project_id: project_id,
    changed_vals: changed_vals,
  }
})


export const newVisitFollowAction = (project_id, center_id) => ({
  type: NEW_VISIT_FOLLOW,
  payload : {
    project_id,
    center_id,
  }

})

export const getVisitFollowAction = (project_id) => ({
  type: GET_VISIT_FOLLOW,
  payload : {
    project_id,
  }
})


export const getVisitFollowSuccessAction = (project_id, response) => ({
  type: GET_VISIT_FOLLOW_SUCCESS,
  payload: {
    project_id,
    response,
  }
})
