import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'
import { NavLink, Link } from 'react-router-dom'
import { getProjects } from 'store/projeler/actions'

//i18n
import { withTranslation } from 'react-i18next'

import MetaTags from 'react-meta-tags'

//Import Images
import error from '../../assets/images/error-img.png'

import { useHistory } from "react-router-dom";
const HomePage = () => {
  const dispatch = useDispatch()

  const { projeler, projects_get } = useSelector(state => ({
    projeler: state.projeler.projeler,
    projects_get: state.projeler.projects_get,
  }))

  let history = useHistory();

  useEffect(() => {
    if (projects_get == false) {
      dispatch(getProjects())
    }
    else {
      if (projeler.length > 0) {
        setTimeout(function(){
          history.push("/bilgilendirme/"+projeler[0].id)
        }, 1000);

      }
    }
  }, [dispatch, projeler])


  return <h1>wait</h1>

}

export default HomePage
