import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Col, Row, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import {
  getProjectsCentersAction,
  deleteProjectsCentersAction,
  newProjectsCenterAction,
  closeModalAction,
} from '../../store/planlama-ve-takvim/actions'

const APP = props => {
  const [current_centers, set_current_centers] = useState(null)

  const dispatch = useDispatch()

  // TODO:
  const { projects_centers, close_modal } = useSelector(state => ({
    projects_centers: state.PlanlamaVeTakvim.projects_centers,
    close_modal: state.PlanlamaVeTakvim.close_modal,
  }))

  const get_centers = () => {
    if (props.current_proje && props.current_proje.id) {
      dispatch(getProjectsCentersAction(props.current_proje.id))
    }
  }

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')

  useEffect(() => {
    get_centers()
    if (props.current_proje) {
      set_current_centers(projects_centers[props.current_proje.id])
    }
  }, [props.current_proje])


  useEffect(() => {
    if (props.current_proje) {
      set_current_centers(projects_centers[props.current_proje.id])
    }
  }, [projects_centers, props.current_proje])

  const delete_center = center_id => {
    if (confirm('Emin misiniz?')) {
      dispatch(deleteProjectsCentersAction(center_id, props.current_proje.id))
      get_centers()
    }
  }

  ///

  const [modal_opened, set_modal_opened] = useState(false)
  const [new_center_name, set_new_center_name] = useState('')

  const handle_new_center = () => {
    dispatch(newProjectsCenterAction(props.current_proje.id, new_center_name))
  }

  useEffect(() => {
    if (close_modal === true) {
      set_modal_opened(false)
      dispatch(closeModalAction(false))
    }
  }, [close_modal])

  return (
    <React.Fragment>
      <Row>
        <Col xs={4} className="d-flex justify-content-center">
          <Label>Merkez Adı</Label>
        </Col>
        <Col xs={4} />
      </Row>

      {current_centers ? (
        current_centers.map((center, index) => (
          <Row className="mt-3">
            <Col xs={4}>
              <Input type="text" value={`${center.name}`} disabled />
            </Col>
            <Col xs={4} className="d-flex justify-content-center">
              <Button onClick={delete_center.bind(this, center.id)} color="primary">
                Sil
              </Button>
            </Col>
          </Row>
        ))
      ) : (
        <></>
      )}
      <Row className="mt-3">
        <Col>
          {user_type == 'ADM' || user_type == 'PM' || user_type == 'CRA' ? (
            <Button
              color="success"
              onClick={() => {
                set_modal_opened(!modal_opened)
              }}>
              Ekle
            </Button>
          ) : (
            ''
          )}
        </Col>
      </Row>

      {/* NEW */}

      <Modal
        isOpen={modal_opened}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          set_modal_opened(!modal_opened)
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              set_modal_opened(!modal_opened)
            }}>
            Yeni Merkez
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <Label>Name</Label>
              <Input
                type="text"
                className="form-control"
                value={new_center_name}
                onChange={e => {
                  set_new_center_name(e.target.value)
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                set_modal_opened(!modal_opened)
              }}>
              Kapat
            </Button>
            <Button type="button" color="primary" onClick={handle_new_center}>
              Ekle
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default APP
