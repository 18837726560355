import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  postLogin,
  getMe,
} from "../../../helpers/backend"

import {
  extend_local_storage,
  get_token,
} from "../../../helpers/storage"

function* loginUser({ payload: { user, history } }) {
  let response;
  try {

    response = yield call(
      postLogin,
      {
        username: user.email,
        password: user.password,
      }
    )
    extend_local_storage("authUser", response)
    yield put(loginSuccess(response))
    const x = yield call(getMe)
    extend_local_storage("authUser", x)
    history.push("/")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* loginUser2({ payload: { user, history } }) {
  try {

    const response = yield call(postFakeLogin, {
      email: user.email,
      password: user.password,
    })
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }


}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
    yield put(logoutUserSuccess())
    location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
