import {
  GET_PROJECTS_VISITS,
  GET_PROJECTS_VISITS_SUCCESS,
  DELETE_PROJECTS_VISITS,
  UPDATE_PROJECTS_VISIT_DURATION,
  NEW_PROJECTS_VISIT,
  GET_PROJECTS_MONITORIZATIONS,
  GET_PROJECTS_MONITORIZATIONS_SUCCESS,
  DELETE_PROJECTS_MONITORIZATIONS,
  UPDATE_PROJECTS_MONITORIZATION_DURATION,
  NEW_PROJECTS_MONITORIZATION,
  CLOSE_MODAL,
  GET_PROJECTS_CENTERS,
  GET_PROJECTS_CENTERS_SUCCESS,
  DELETE_PROJECTS_CENTERS,
  UPDATE_PROJECTS_CENTER_NAME,
  NEW_PROJECTS_CENTER,
  GET_CENTERS_DEVICES,
  GET_CENTERS_DEVICES_SUCCESS,
  NEW_CENTERS_DEVICES,
  UPDATE_DEVICE_DATA,
  DELETE_CENTERS_DEVICES,
  GET_CENTERS_PAYMENTS,
  GET_CENTERS_PAYMENTS_SUCCESS,
  NEW_CENTERS_PAYMENTS,
  DELETE_CENTERS_PAYMENTS,
  GET_CENTERS_PAYMENTS_FLOW,
  GET_CENTERS_PAYMENTS_FLOW_SUCCESS,
  NEW_PROFORMA,
  NEW_PAY_FLOW,
  NEW_PAY_FLOW_SUCCESS,
  NEW_PROFORMA_SUCCESS,

} from "./actionTypes"


export const getProjectsVisitsAction = (project_id) => ({
  type: GET_PROJECTS_VISITS,
  payload: { project_id: project_id },
})


export const getProjectsVisitsSuccessAction = (project_id, visit) => ({
  type: GET_PROJECTS_VISITS_SUCCESS,
  payload: {
    visit: visit,
    project_id: project_id,
  }
})


export const deleteProjectsVisitsAction = (visit_id, project_id) => ({
  type: DELETE_PROJECTS_VISITS,
  payload: { visit_id: visit_id, project_id: project_id },
})


export const updateProjectsVisitDurationAction = (visit_id, project_id, new_duration) => ({
  type: UPDATE_PROJECTS_VISIT_DURATION,
  payload: { visit_id: visit_id, project_id: project_id, new_duration: new_duration },
})


export const newProjectsVisitAction = (project_id, duration) => ({
  type: NEW_PROJECTS_VISIT,
  payload: { project_id: project_id, duration: duration },
})


export const closeModalAction = (val) => ({
  type: CLOSE_MODAL,
  payload: { close: val },
})


// MONITORIZATION

export const getProjectsMonitorizationsAction = (project_id) => ({
  type: GET_PROJECTS_MONITORIZATIONS,
  payload: { project_id: project_id },
})


export const getProjectsMonitorizationsSuccessAction = (project_id, monitorization) => ({
  type: GET_PROJECTS_MONITORIZATIONS_SUCCESS,
  payload: {
    monitorization: monitorization,
    project_id: project_id,
  }
})


export const deleteProjectsMonitorizationsAction = (monitorization_id, project_id) => ({
  type: DELETE_PROJECTS_MONITORIZATIONS,
  payload: { monitorization_id: monitorization_id, project_id: project_id },
})


export const updateProjectsMonitorizationDurationAction = (monitorization_id, project_id, new_duration) => ({
  type: UPDATE_PROJECTS_MONITORIZATION_DURATION,
  payload: { monitorization_id: monitorization_id, project_id: project_id, new_duration: new_duration },
})


export const newProjectsMonitorizationAction = (project_id, duration) => ({
  type: NEW_PROJECTS_MONITORIZATION,
  payload: { project_id: project_id, duration: duration },
})

// Centers

export const getProjectsCentersAction = (project_id) => ({
  type: GET_PROJECTS_CENTERS,
  payload: { project_id: project_id },
})


export const getProjectsCentersSuccessAction = (project_id, center) => ({
  type: GET_PROJECTS_CENTERS_SUCCESS,
  payload: {
    center: center,
    project_id: project_id,
  }
})


export const deleteProjectsCentersAction = (center_id, project_id) => ({
  type: DELETE_PROJECTS_CENTERS,
  payload: { center_id: center_id, project_id: project_id },
})


export const updateProjectsCentersDurationAction = (center_id, project_id, new_name) => ({
  type: UPDATE_PROJECTS_CENTER_NAME,
  payload: { center_id: center_id, project_id: project_id, new_name: new_name },
})


export const newProjectsCenterAction = (project_id, duration) => ({
  type: NEW_PROJECTS_CENTER,
  payload: { project_id: project_id, duration: duration },
})


// devices

export const getCentersDevicesAction = (center_id) => ({
  type: GET_CENTERS_DEVICES,
  payload: {
    center_id: center_id,
  }
})


export const getCentersDevicesActionSuccess = (center_id, devices) => ({
  type: GET_CENTERS_DEVICES_SUCCESS,
  payload: {
    center_id: center_id,
    devices: devices,
  }
})

export const newCentersDevicesAction = (center_id, name, file, file_name, countdown) => ({
  type: NEW_CENTERS_DEVICES,
  payload: {
    center_id: center_id,
    name: name,
    file: file,
    file_name: file_name,
    countdown: countdown,
  }
})
export const centersDevicesUpdateCountDownAction = (id, center_id, countdown) => ({
  type: UPDATE_DEVICE_DATA,
  payload: {
    center_id: center_id,
    id: id,
    countdown: countdown,
  }
})


export const deleteCentersDeviceAction = (center_id, device_id) => ({
  type: DELETE_CENTERS_DEVICES,
  payload: {
    center_id: center_id,
    device_id: device_id,
  }
})

// payments

export const getCentersPaymentsAction = (center_id) => ({
  type: GET_CENTERS_PAYMENTS,
  payload: {
    center_id: center_id,
  }
})


export const getCentersPaymentsActionSuccess = (center_id, payments) => ({
  type: GET_CENTERS_PAYMENTS_SUCCESS,
  payload: {
    center_id: center_id,
    payments: payments,
  }
})

export const newCentersPaymentsAction = (center_id, name, file, file_name) => ({
  type: NEW_CENTERS_PAYMENTS,
  payload: {
    center_id: center_id,
    name: name,
    file: file,
    file_name: file_name,
  }
})


export const deleteCentersPaymentAction = (center_id, payment_id) => ({
  type: DELETE_CENTERS_DEVICES,
  payload: {
    center_id: center_id,
    payment_id: payment_id,
  }
})
export const getPaymentFlowsAction = (payment_id, project_id) => ({
  type: GET_CENTERS_PAYMENTS_FLOW,
  payload: {
    payment_id: payment_id,
    project_id: project_id,
  }
})
export const getCentersPaymentFlowsSuccess = (payment_id, response) => ({
  type: GET_CENTERS_PAYMENTS_FLOW_SUCCESS,
  payload: {
    payment_id: payment_id,
    payment_flows: response,
  }
})
export const newPayFlow = (payment_id,proje_id,paymentModalData,file,file_full_name) => ({
  type: NEW_PAY_FLOW,
  payload: {payment_id,proje_id,paymentModalData,file,file_full_name},
})
export const newProformaAction = (req_data) => ({
  type: NEW_PROFORMA,
  payload: { project_id: req_data.project_id, title: req_data.title, value: req_data.value, status: req_data.status, id: req_data.id },
})
export const newProformaActionSuccess = (response) => ({
  type: NEW_PROFORMA_SUCCESS,
  payload: {
    response: response
  },
})


export const getCentersPaymentFlowReqSuccess = (payment_id, response) => ({
  type: NEW_PAY_FLOW_SUCCESS,
  payload: {
    payment_id: payment_id,
    payment_flows: response,
  }
})


export const deleteCentersPaymentsAction = (center_id, payment_id) => ({
  type: DELETE_CENTERS_PAYMENTS,
  payload: {
    center_id: center_id,
    payment_id: payment_id,
  }
})
