import MetaTags from 'react-meta-tags'
import React, { Component, useState, useEffect } from 'react'

import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'
import doc from '../../assets/visit_islemleri/vizit_islemleri_2.docx'
import Breadcrumbs from '../../components/Common/Breadcrumb'

import { Button } from 'reactstrap'
import {
  Card,
  CardBody,
  Container,
  CardTitle,
  Row,
  Col,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { newVisitFollowAction, getVisitFollowAction } from '../../store/actions'

import { getProjectsCentersAction } from '../../store/planlama-ve-takvim/actions'

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback)
}

const CustomInput = props => {
  return (
    <Row className="mb-3">
      <label className="col-md-4 col-form-label">{props.label}</label>
      <Col md={2}>
        <Input
          className="form-control"
          type="text"
          value={props.value}
          onChange={e => {
            props.func(e.target.value)
          }}
        />
      </Col>
    </Row>
  )
}

const App = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')

  const generateDocument = () => {
    loadFile(doc, function (error, content) {
      if (error) {
        throw error
      }
      const zip = new PizZip(content)
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      })
      try {
        doc.render(get_doc_fields())
      } catch (error) {
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (error, key) {
              error[key] = value[key]
              return error
            }, {})
          }
          return value
        }
        console.log(JSON.stringify({ error: error }, replaceErrors))

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation
            })
            .join('\n')
          console.log('errorMessages', errorMessages)
        }
        throw error
      }
      // SAVE DOWNLOAD INFO
      dispatch(newVisitFollowAction(current_proje.id, selected_center.id))
      // SAVE DOWNLOAD INFO END
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }) //Output the document using Data-URI
      saveAs(out, 'output.docx')
    })
  }

  //////////
  // app
  //
  const { projeler, visit_follows } = useSelector(state => ({
    projeler: state.contacts.projeler,
    visit_follows: state.OperasyonelTakip.visit_follows,
  }))

  const [current_proje, setCurrentProje] = useState({})
  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  // centers
  const { projects_centers } = useSelector(state => ({
    projects_centers: state.PlanlamaVeTakvim.projects_centers,
  }))

  const [selected_center, set_selected_center] = useState(null)
  const [current_centers, set_current_centers] = useState(null)
  const get_centers = () => {
    if (current_proje && current_proje.id) {
      dispatch(getProjectsCentersAction(current_proje.id))
    }
  }

  useEffect(() => {
    if (current_centers) {
      set_selected_center(current_centers[0])
    }
  }, [current_centers])

  useEffect(() => {
    get_centers()
    if (current_proje) {
      set_current_centers(projects_centers[current_proje.id])
    }
  }, [current_proje])

  //
  useEffect(() => {
    if (current_proje && current_proje.id) dispatch(getVisitFollowAction(current_proje.id))
  }, [current_proje])

  //
  //
  const [merkez_vizit_tarihi, set_merkez_vizit_tarihi] = useState('')
  const [calisma_kodu, set_calisma_kodu] = useState('')
  const [proje_adi, set_proje_adi] = useState('')
  const [merkez_numarasi, set_merkez_numarasi] = useState('')
  const [hasta_adi_ve_soyadi_ilk_bas_harfleri, set_hasta_adi_ve_soyadi_ilk_bas_harfleri] = useState('')
  const [merkez_numarasi_ve_saglik_kurumunun_adi, set_merkez_numarasi_ve_saglik_kurumunun_adi] = useState('')
  const [hasta_yasi, set_hasta_yasi] = useState('')
  const [hastanin_cinsiyeti, set_hastanin_cinsiyeti] = useState('')
  const [merkez_ziyaretinde_kacinci_vizit_oldugu, set_merkez_ziyaretinde_kacinci_vizit_oldugu] = useState('')
  const [incelemenin_yapildigi_tibbi_brans, set_incelemenin_yapildigi_tibbi_brans] = useState('')

  const [hasta_ates_olcumu, set_hasta_ates_olcumu] = useState('')
  const [solunum_sayisi, set_solunum_sayisi] = useState('')
  const [nabiz_sayisi, set_nabiz_sayisi] = useState('')
  const [kucuk_tansiyon_sayisi, set_kucuk_tansiyon_sayisi] = useState('')
  const [buyuk_tansiyon_bilgisi, set_buyuk_tansiyon_bilgisi] = useState('')
  const [kilo_bilgisi, set_kilo_bilgisi] = useState('')
  const [boy_olcusu, set_boy_olcusu] = useState('')

  const [eski_imp_kullanim_sayisi, set_eski_imp_kullanim_sayisi] = useState('')
  const [eski_imp_barkod_numarasi, set_eski_imp_barkod_numarasi] = useState('')
  const [eski_imp_teslim_edilme_tarihi, set_eski_imp_teslim_edilme_tarihi] = useState('')
  const [yeni_imp_barkod_numarasi, set_yeni_imp_barkod_numarasi] = useState('')
  const [vizit_sayisi, set_vizit_sayisi] = useState('')
  const [yeni_imp_urun_adi, set_yeni_imp_urun_adi] = useState('')
  const [yeni_imp_kullanim_dozaj_bilgisi, set_yeni_imp_kullanim_dozaj_bilgisi] = useState('')
  const [yeni_imp_etiket_numarasi, set_yeni_imp_etiket_numarasi] = useState('')
  const [calisma_kodu_ve_numarasi, set_calisma_kodu_ve_numarasi] = useState('')

  const [tibbi_numune_saklama_bilgisi, set_tibbi_numune_saklama_bilgisi] = useState('')
  const [tibbi_numune_numarasi, set_tibbi_numune_numarasi] = useState('')
  const [tibbi_numunenin_kondugu_yerin_adi, set_tibbi_numunenin_kondugu_yerin_adi] = useState('')
  const [tibbi_numune_saklama_derecesi, set_tibbi_numune_saklama_derecesi] = useState('')

  const [calisma_disi_tedavinin_ismi, set_calisma_disi_tedavinin_ismi] = useState('')
  const [calisma_disi_tedaviye_baslama_tarihi, set_calisma_disi_tedaviye_baslama_tarihi] = useState('')
  const [calisma_disi_tedavi_bitis_tarihi, set_calisma_disi_tedavi_bitis_tarihi] = useState('')
  const [calisma_disi_alinan_ilacin_ismi, set_calisma_disi_alinan_ilacin_ismi] = useState('')
  const [calisma_disi_alinan_ilac_alma_nedeni, set_calisma_disi_alinan_ilac_alma_nedeni] = useState('')
  const [calisma_disi_alinan_ilaca_baslama_tarihi, set_calisma_disi_alinan_ilaca_baslama_tarihi] = useState('')
  const [calisma_disi_aldigi_ilacin_bitis_tarihi, set_calisma_disi_aldigi_ilacin_bitis_tarihi] = useState('')
  const [hastaya_ozgu_rahatsizlik_adlari, set_hastaya_ozgu_rahatsizlik_adlari] = useState('')

  const [gelecek_vizit_sayisi, set_gelecek_vizit_sayisi] = useState('')
  const [yeni_imp_kullanim_dozaji, set_yeni_imp_kullanim_dozaji] = useState('')
  const [tahlil_adlari, set_tahlil_adlari] = useState('')
  const [tahlil_sayisi, set_tahlil_sayisi] = useState('')
  const [
    kan_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
    set_kan_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
  ] = useState('')
  const [kan_tahlili_icin_stanrifuj_devir_duzeyi, set_kan_tahlili_icin_stanrifuj_devir_duzeyi] = useState('')
  const [
    idrar_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
    set_idrar_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
  ] = useState('')
  const [idrar_tahlili_icin_santrifuj_devir_duzeyi, set_idrar_tahlili_icin_santrifuj_devir_duzeyi] = useState('')

  const [hizmet_alinan_kargo_adi, set_hizmet_alinan_kargo_adi] = useState('')
  const [kargo_gonderim_tarihi, set_kargo_gonderim_tarihi] = useState('')
  const [paket_barkod_numarasi, set_paket_barkod_numarasi] = useState('')
  const [gonderilen_numune_sayisi, set_gonderilen_numune_sayisi] = useState('')

  const [birinci_arastirma_cihaz_adi_ve_kodu, set_birinci_arastirma_cihaz_adi_ve_kodu] = useState('')
  const [birinci_cihazin_oldugu_yerin_adi, set_birinci_cihazin_oldugu_yerin_adi] = useState('')
  const [ikinci_arastirma_cihaz_adi_ve_kodu, set_ikinci_arastirma_cihaz_adi_ve_kodu] = useState('')
  const [ikinci_cihazin_oldugu_yerin_adi, set_ikinci_cihazin_oldugu_yerin_adi] = useState('')
  const [ucuncu_arastirma_cihaz_adi_ve_kodu, set_ucuncu_arastirma_cihaz_adi_ve_kodu] = useState('')
  const [ucuncu_cihazin_oldugu_yerin_adi, set_ucuncu_cihazin_oldugu_yerin_adi] = useState('')
  const [dorduncu_arastirma_cihaz_adi_ve_kodu, set_dorduncu_arastirma_cihaz_adi_ve_kodu] = useState('')
  const [dorduncu_cihazin_oldugu_yerin_adi, set_dorduncu_cihazin_oldugu_yerin_adi] = useState('')
  const [besinci_arastirma_cihaz_adi_ve_kodu, set_besinci_arastirma_cihaz_adi_ve_kodu] = useState('')
  const [besinci_cihazin_oldugu_yerin_adi, set_besinci_cihazin_oldugu_yerin_adi] = useState('')

  let arastirma_viziti_icin_kullanilan_cihaz_bilgisi_fields = [
    {
      label: '1. Araştırma Cihaz Adı ve Kodu',
      value: birinci_arastirma_cihaz_adi_ve_kodu,
      doc_map: 'birinci_arastirma_cihaz_adi_ve_kodu',
      func: set_birinci_arastirma_cihaz_adi_ve_kodu,
    },
    {
      label: '1. Cihazın olduğu yerin adı',
      value: birinci_cihazin_oldugu_yerin_adi,
      doc_map: 'birinci_cihazin_oldugu_yerin_adi',
      func: set_birinci_cihazin_oldugu_yerin_adi,
    },
    {
      label: '2. Araştırma Cihaz Adı ve Kodu',
      value: ikinci_arastirma_cihaz_adi_ve_kodu,
      doc_map: 'ikinci_arastirma_cihaz_adi_ve_kodu',
      func: set_ikinci_arastirma_cihaz_adi_ve_kodu,
    },
    {
      label: '2. Cihazın olduğu yerin adı',
      value: ikinci_cihazin_oldugu_yerin_adi,
      doc_map: 'ikinci_cihazin_oldugu_yerin_adi',
      func: set_ikinci_cihazin_oldugu_yerin_adi,
    },
    {
      label: '3. Araştırma Cihaz Adı ve Kodu',
      value: ucuncu_arastirma_cihaz_adi_ve_kodu,
      doc_map: 'ucuncu_arastirma_cihaz_adi_ve_kodu',
      func: set_ucuncu_arastirma_cihaz_adi_ve_kodu,
    },
    {
      label: '3. Cihazın olduğu yerin adı',
      value: ucuncu_cihazin_oldugu_yerin_adi,
      doc_map: 'ucuncu_cihazin_oldugu_yerin_adi',
      func: set_ucuncu_cihazin_oldugu_yerin_adi,
    },
    {
      label: '4. Araştırma Cihaz Adı ve Kodu',
      value: dorduncu_arastirma_cihaz_adi_ve_kodu,
      doc_map: 'dorduncu_arastirma_cihaz_adi_ve_kodu',
      func: set_dorduncu_arastirma_cihaz_adi_ve_kodu,
    },
    {
      label: '4. Cihazın olduğu yerin adı',
      value: dorduncu_cihazin_oldugu_yerin_adi,
      doc_map: 'dorduncu_cihazin_oldugu_yerin_adi',
      func: set_dorduncu_cihazin_oldugu_yerin_adi,
    },
    {
      label: '5. Araştırma Cihaz Adı ve Kodu',
      value: besinci_arastirma_cihaz_adi_ve_kodu,
      doc_map: 'besinci_arastirma_cihaz_adi_ve_kodu',
      func: set_besinci_arastirma_cihaz_adi_ve_kodu,
    },
    {
      label: '5. Cihazın olduğu yerin adı',
      value: besinci_cihazin_oldugu_yerin_adi,
      doc_map: 'besinci_cihazin_oldugu_yerin_adi',
      func: set_besinci_cihazin_oldugu_yerin_adi,
    },
  ]

  let tibbi_ornekleri_gonderme_fields = [
    {
      label: 'Hizmet Alınan Kargo Ad',
      value: hizmet_alinan_kargo_adi,
      doc_map: 'hizmet_alinan_kargo_adi',
      func: set_hizmet_alinan_kargo_adi,
    },
    {
      label: 'Kargo Gönderim Tarihi',
      value: kargo_gonderim_tarihi,
      doc_map: 'kargo_gonderim_tarihi',
      func: set_kargo_gonderim_tarihi,
    },
    {
      label: 'Paket Barkod Numarası',
      value: paket_barkod_numarasi,
      doc_map: 'paket_barkod_numarasi',
      func: set_paket_barkod_numarasi,
    },
    {
      label: 'Gönderilen Numune Sayısı',
      value: gonderilen_numune_sayisi,
      doc_map: 'gonderilen_numune_sayisi',
      func: set_gonderilen_numune_sayisi,
    },
  ]

  let tibbi_numunelerin_alimi_fields = [
    {
      label: 'Merkez Numarası',
      value: merkez_numarasi, // NOTE: dobule
      doc_map: 'merkez_numarasi',
      func: set_merkez_numarasi,
    },
    {
      label: 'Gelecek Vizit Sayısı',
      value: gelecek_vizit_sayisi,
      doc_map: 'gelecek_vizit_sayisi',
      func: set_gelecek_vizit_sayisi,
    },
    {
      label: 'Yeni IMP Kullanım Dozajı',
      value: yeni_imp_kullanim_dozaji,
      doc_map: 'yeni_imp_kullanim_dozaji',
      func: set_yeni_imp_kullanim_dozaji,
    },
    {
      label: 'Merkez Vizit Sayısı',
      value: merkez_vizit_tarihi, // NOTE: double
      doc_map: 'merkez_vizit_tarihi',
      func: set_merkez_vizit_tarihi,
    },
    {
      label: 'Tahlil Adları',
      value: tahlil_adlari,
      doc_map: 'tahlil_adlari',
      func: set_tahlil_adlari,
    },
    {
      label: 'Tahlil Sayisi',
      value: tahlil_sayisi,
      doc_map: 'tahlil_sayisi',
      func: set_tahlil_sayisi,
    },
    {
      label: 'Kan Tahlili İçin Santrifuj Çalışma Dakika Bilgisi',
      value: kan_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
      doc_map: 'kan_tahlili_icin_santrifuj_calisma_dakika_bilgisi',
      func: set_kan_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
    },
    {
      label: 'Kan Tahlili İçin Santrifuj Devir Düzeyi',
      value: kan_tahlili_icin_stanrifuj_devir_duzeyi,
      doc_map: 'kan_tahlili_icin_stanrifuj_devir_duzeyi',
      func: set_kan_tahlili_icin_stanrifuj_devir_duzeyi,
    },
    {
      label: 'İdrar Tahlili İçin Santrifuj Çalışma Dakika Bilgisi',
      value: idrar_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
      doc_map: 'idrar_tahlili_icin_santrifuj_calisma_dakika_bilgisi',
      func: set_idrar_tahlili_icin_santrifuj_calisma_dakika_bilgisi,
    },
    {
      label: 'İdrar Tahlili İçin Santrifuj Devir Düzeyi',
      value: idrar_tahlili_icin_santrifuj_devir_duzeyi,
      doc_map: 'idrar_tahlili_icin_santrifuj_devir_duzeyi',
      func: set_idrar_tahlili_icin_santrifuj_devir_duzeyi,
    },
  ]

  let gonullu_hastanin_calisma_disi_aldigi_tedaviler_fields = [
    {
      label: 'Çalışma Dışı Tedavinin İsmi',
      value: calisma_disi_tedavinin_ismi,
      doc_map: 'calisma_disi_tedavinin_ismi',
      func: set_calisma_disi_tedavinin_ismi,
    },
    {
      label: 'Çalışma Dışı Tedaviye Başlama Tarihi',
      value: calisma_disi_tedaviye_baslama_tarihi,
      doc_map: 'calisma_disi_tedaviye_baslama_tarihi',
      func: set_calisma_disi_tedaviye_baslama_tarihi,
    },
    {
      label: 'Çalışma Dışı Tedavi Bitiş Tarihi',
      value: calisma_disi_tedavi_bitis_tarihi,
      doc_map: 'calisma_disi_tedavi_bitis_tarihi',
      func: set_calisma_disi_tedavi_bitis_tarihi,
    },
    {
      label: 'Çalışma Dışı Alınan İlacın İsmi',
      value: calisma_disi_alinan_ilacin_ismi,
      doc_map: 'calisma_disi_alinan_ilacin_ismi',
      func: set_calisma_disi_alinan_ilacin_ismi,
    },
    {
      label: 'Çalışma Dışı Alınan İlacı Alma Nedeni',
      value: calisma_disi_alinan_ilac_alma_nedeni,
      doc_map: 'calisma_disi_alinan_ilac_alma_nedeni',
      func: set_calisma_disi_alinan_ilac_alma_nedeni,
    },
    {
      label: 'Çalışma Dışı Alınan İlaca Başlama Tarihi',
      value: calisma_disi_alinan_ilaca_baslama_tarihi,
      doc_map: 'calisma_disi_alinan_ilaca_baslama_tarihi',
      func: set_calisma_disi_alinan_ilaca_baslama_tarihi,
    },
    {
      label: 'Çalışma Dışı Aldığı İlacın Bitiş Tarihi',
      value: calisma_disi_aldigi_ilacin_bitis_tarihi,
      doc_map: 'calisma_disi_aldigi_ilacin_bitis_tarihi',
      func: set_calisma_disi_aldigi_ilacin_bitis_tarihi,
    },
    {
      label: 'Hastaya Özgü Rahatsızlık Adları',
      value: hastaya_ozgu_rahatsizlik_adlari,
      doc_map: 'hastaya_ozgu_rahatsizlik_adlari',
      func: set_hastaya_ozgu_rahatsizlik_adlari,
    },
  ]

  let numune_saklama_bilgisi_fields = [
    {
      label: 'Tıbbi Numune Saklama Bilgisi',
      value: tibbi_numune_saklama_bilgisi,
      doc_map: 'tibbi_numune_saklama_bilgisi',
      func: set_tibbi_numune_saklama_bilgisi,
    },
    {
      label: 'Tıbbi Numune Numarası',
      value: tibbi_numune_numarasi,
      doc_map: 'tibbi_numune_numarasi',
      func: set_tibbi_numune_numarasi,
    },
    {
      label: 'Tıbbi Numunenin Konduğu Yerin Adı',
      value: tibbi_numunenin_kondugu_yerin_adi,
      doc_map: 'tibbi_numunenin_kondugu_yerin_adi',
      func: set_tibbi_numunenin_kondugu_yerin_adi,
    },
    {
      label: 'Tıbbi Numune Saklama Derecesi',
      value: tibbi_numune_saklama_derecesi,
      doc_map: 'tibbi_numune_saklama_derecesi',
      func: set_tibbi_numune_saklama_derecesi,
    },
  ]

  let calisma_ilacinin_barkod_numarasi_fields = [
    {
      label: 'Eski IMP Kullanım Sayısı',
      value: eski_imp_kullanim_sayisi,
      doc_map: 'eski_imp_kullanim_sayisi',
      func: set_eski_imp_kullanim_sayisi,
    },
    {
      label: 'Eski Imp Barkod Numarası',
      value: eski_imp_barkod_numarasi,
      doc_map: 'eski_imp_barkod_numarasi',
      func: set_eski_imp_barkod_numarasi,
    },
    {
      label: 'Eski IMP Teslim Edilme Tarihi',
      value: eski_imp_teslim_edilme_tarihi,
      doc_map: 'eski_imp_teslim_edilme_tarihi',
      func: set_eski_imp_teslim_edilme_tarihi,
    },
    {
      label: 'Yeni Imp Barkod Numarasi',
      value: yeni_imp_barkod_numarasi,
      doc_map: 'yeni_imp_barkod_numarasi',
      func: set_yeni_imp_barkod_numarasi,
    },
    {
      label: 'Vizit Sayısı',
      value: vizit_sayisi,
      doc_map: 'vizit_sayisi',
      func: set_vizit_sayisi,
    },
    {
      label: 'Yeni Imp Urun Adi',
      value: yeni_imp_urun_adi,
      doc_map: 'yeni_imp_urun_adi',
      func: set_yeni_imp_urun_adi,
    },
    {
      label: 'Yeni Imp Kullanim Dozaj Bilgisi',
      value: yeni_imp_kullanim_dozaj_bilgisi,
      doc_map: 'yeni_imp_kullanim_dozaj_bilgisi',
      func: set_yeni_imp_kullanim_dozaj_bilgisi,
    },
    {
      label: 'Yeni Imp Etiket Numarasi',
      value: yeni_imp_etiket_numarasi,
      doc_map: 'yeni_imp_etiket_numarasi',
      func: set_yeni_imp_etiket_numarasi,
    },
    {
      label: 'Çalışma Kodu ve Numarası',
      value: calisma_kodu_ve_numarasi,
      doc_map: 'calisma_kodu_ve_numarasi',
      func: set_calisma_kodu_ve_numarasi,
    },
  ]

  let gonullu_hasta_fields = [
    {
      label: 'Merkez Vizit Tarihi',
      value: merkez_vizit_tarihi,
      doc_map: 'merkez_vizit_tarihi',
      func: set_merkez_vizit_tarihi,
    },
    {
      label: 'Çalışma Kodu',
      value: calisma_kodu,
      doc_map: 'calisma_kodu',
      func: set_calisma_kodu,
    },
    {
      label: 'Proje Adı',
      value: proje_adi,
      doc_map: 'proje_adi',
      func: set_proje_adi,
    },
    {
      label: 'Merkez Numarasi',
      value: merkez_numarasi,
      doc_map: 'merkez_numarasi',
      func: set_merkez_numarasi,
    },
    {
      label: 'Hasta Adi ve Soyadi Baş Harfleri',
      value: hasta_adi_ve_soyadi_ilk_bas_harfleri,
      doc_map: 'hasta_adi_ve_soyadi_ilk_bas_harfleri',
      func: set_hasta_adi_ve_soyadi_ilk_bas_harfleri,
    },
    {
      label: 'Merkez Numarası ve Sağlık Kurumunun Adı',
      value: merkez_numarasi_ve_saglik_kurumunun_adi,
      doc_map: 'merkez_numarasi_ve_saglik_kurumunun_adi',
      func: set_merkez_numarasi_ve_saglik_kurumunun_adi,
    },
    {
      label: 'Hasta Yaşı',
      value: hasta_yasi,
      doc_map: 'hasta_yasi',
      func: set_hasta_yasi,
    },
    {
      label: 'Hastanın Cinsiyeti',
      value: hastanin_cinsiyeti,
      doc_map: 'hastanin_cinsiyeti',
      func: set_hastanin_cinsiyeti,
    },
    {
      label: 'Merkez Ziyaretinde Kacinci Vizit Oldugu',
      value: merkez_ziyaretinde_kacinci_vizit_oldugu,
      doc_map: 'merkez_ziyaretinde_kacinci_vizit_oldugu',
      func: set_merkez_ziyaretinde_kacinci_vizit_oldugu,
    },
    {
      label: 'Incelemenin Yapildigi Tibbi Brans',
      value: incelemenin_yapildigi_tibbi_brans,
      doc_map: 'incelemenin_yapildigi_tibbi_brans',
      func: set_incelemenin_yapildigi_tibbi_brans,
    },
  ]

  let vital_bilgiler_fields = [
    {
      label: 'Hasta Ateş Ölçümü',
      value: hasta_ates_olcumu,
      doc_map: 'hasta_ates_olcumu',
      func: set_hasta_ates_olcumu,
    },
    {
      label: 'Solunum Sayısı',
      value: solunum_sayisi,
      doc_map: 'solunum_sayisi',
      func: set_solunum_sayisi,
    },
    {
      label: 'Nabız Sayısı',
      value: nabiz_sayisi,
      doc_map: 'nabiz_sayisi',
      func: set_nabiz_sayisi,
    },
    {
      label: 'Küçük Tansiyon Sayısı',
      value: kucuk_tansiyon_sayisi,
      doc_map: 'kucuk_tansiyon_sayisi',
      func: set_kucuk_tansiyon_sayisi,
    },
    {
      label: 'Büyük Tansiyon Bilgisi',
      value: buyuk_tansiyon_bilgisi,
      doc_map: 'buyuk_tansiyon_bilgisi',
      func: set_buyuk_tansiyon_bilgisi,
    },
    {
      label: 'Kilo Bilgisi',
      value: kilo_bilgisi,
      doc_map: 'kilo_bilgisi',
      func: set_kilo_bilgisi,
    },
    {
      label: 'Boy Ölçüsü',
      value: boy_olcusu,
      doc_map: 'boy_olcusu',
      func: set_boy_olcusu,
    },
  ]

  let fields_list = [
    calisma_ilacinin_barkod_numarasi_fields,
    vital_bilgiler_fields,
    gonullu_hasta_fields,
    numune_saklama_bilgisi_fields,
    gonullu_hastanin_calisma_disi_aldigi_tedaviler_fields,
    tibbi_numunelerin_alimi_fields,
    tibbi_ornekleri_gonderme_fields,
    arastirma_viziti_icin_kullanilan_cihaz_bilgisi_fields,
  ]

  const get_doc_fields = () => {
    let ret = {}
    for (let i = 0; i < fields_list.length; i++) {
      for (let j = 0; j < fields_list[i].length; j++) {
        ret = { ...ret, ...{ [fields_list[i][j].doc_map]: fields_list[i][j].value } }
      }
    }
    let r = {
      ...ret,
      ...{
        is_tibbi_numune_saklama_bilgisi: tibbi_numune_saklama_bilgisi.length > 0,
        is_not_tibbi_numune_saklama_bilgisi: tibbi_numune_saklama_bilgisi.length == 0,
        is_not_calisma_disi_aldigi_tedavi: calisma_disi_tedavinin_ismi.length == 0,
        is_calisma_disi_aldigi_tedavi: calisma_disi_tedavinin_ismi.length > 0,
        is_calisma_disi_tedavi_bitis_tarihi: calisma_disi_tedavi_bitis_tarihi.length > 0,
        is_not_calisma_disi_tedavi_bitis_tarihi: calisma_disi_tedavi_bitis_tarihi.length == 0,
        is_not_calisma_disi_aldigi_ilac_var: calisma_disi_alinan_ilacin_ismi.length == 0,
        is_calisma_disi_aldigi_ilac_var: calisma_disi_alinan_ilacin_ismi.length > 0,
        is_calisma_disi_aldigi_ilacin_bitis_tarihi: calisma_disi_aldigi_ilacin_bitis_tarihi.length > 0,
        is_not_calisma_disi_aldigi_ilacin_bitis_tarihi: calisma_disi_aldigi_ilacin_bitis_tarihi.length == 0,
        is_hastaya_ozgu_rahatsizlik: hastaya_ozgu_rahatsizlik_adlari.length > 0,
        is_not_hastaya_ozgu_rahatsizlik: hastaya_ozgu_rahatsizlik_adlari.length == 0,
      },
    }
    return r
  }
  get_doc_fields()

  const [customIconActiveTab, setcustomIconActiveTab] = useState('1')
  const toggleCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - HastaGruplari</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Hasta Grupları"
            /* TODO  */
            breadcrumbItem={current_proje ? current_proje.name : ''}
          />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                {/* FileRightBar  */}

                <div className="w-100">
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: customIconActiveTab === '1',
                            })}
                            onClick={() => {
                              toggleCustom('1')
                            }}>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Yeni Vizit İşlemi</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: customIconActiveTab === '2',
                            })}
                            onClick={() => {
                              toggleCustom('2')
                            }}>
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">Vizit İşlemleri Takibi</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={customIconActiveTab} className="p-3 text-muted">
                        <TabPane tabId="1">
                          {current_centers && current_centers.length ? (
                            <Row>
                              <Col sm="12">
                                <CardTitle className="mt-3">Merkez Bilgisi</CardTitle>
                                <Row className="mb-3">
                                  <label className="col-md-4 col-form-label">Merkez</label>
                                  <Col md={4}>
                                    <select
                                      id="para_birimi"
                                      name="para_birimi"
                                      className="form-control"
                                      onChange={e => {
                                        set_selected_center(current_centers.find(center => center.id == e.target.value))
                                      }}>
                                      {current_centers ? (
                                        current_centers.map(center => <option value={center.id}>{center.name}</option>)
                                      ) : (
                                        <></>
                                      )}
                                    </select>
                                  </Col>
                                </Row>

                                {/* Gönüllü Hasta ve Çalışma Merkezi */}
                                <CardTitle className="mt-5">Gönüllü Hasta ve Çalışma Merkezi</CardTitle>
                                {gonullu_hasta_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Vital Bilgiler */}
                                <CardTitle className="mt-5">Vital Bilgiler</CardTitle>
                                {vital_bilgiler_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Çalışma İlacının Barkod Numarasi */}
                                <CardTitle className="mt-5">Çalışma İlacının Barkod Numarasi</CardTitle>
                                {calisma_ilacinin_barkod_numarasi_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Numune Saklama Bilgisi */}
                                <CardTitle className="mt-5">Çalışma İlacının Barkod Numarasi</CardTitle>
                                {numune_saklama_bilgisi_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Gönüllü hasta soruları */}
                                <CardTitle className="mt-5">Çalışma DıŞı aldığı tedavi</CardTitle>
                                {gonullu_hastanin_calisma_disi_aldigi_tedaviler_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Tibbi numunelerin alimi */}
                                <CardTitle className="mt-5">Tıbbi numunelerin alımı</CardTitle>
                                {tibbi_numunelerin_alimi_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Arastirma viziti icin kullanılan cihaz bilgisi */}
                                <CardTitle className="mt-5">Araştırma viziti için kullanılan cihaz bilgisi</CardTitle>
                                {arastirma_viziti_icin_kullanilan_cihaz_bilgisi_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}
                                {/* Tibbi örneklerin gönderimi */}
                                <CardTitle className="mt-5">Tıbbi örneklerin gönderimi</CardTitle>
                                {tibbi_ornekleri_gonderme_fields.map(data => (
                                  <CustomInput label={data.label} value={data.value} func={data.func} />
                                ))}

                                {user_type == 'ADM' || user_type == 'SC' ? (
                                  <Button type="button" color="success" onClick={generateDocument}>
                                    Word Dosyasını İndir
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <h4 className="mt-3">Lütfen öncelikle merkezleri oluşturun</h4>
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="2">
                          {/* asdf */}

                          <Table className="table mb-0">
                            <thead>
                              <tr>
                                <th>Tarih</th>
                                <th>Merkez Adı</th>
                              </tr>
                            </thead>
                            <tbody>
                              {visit_follows.map(visit_follow => (
                                <tr>
                                  <td>{visit_follow.create_datetime}</td>
                                  <td>{visit_follow.center_name}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          {/* asdf */}
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default App
