import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React from 'react'

import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { loginUser } from '../../store/actions'

// import images
import profile from 'assets/images/profile-img.png'
import logo from 'assets/images/logo-light.png'

//Import config

const Login = props => {
  const dispatch = useDispatch()

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    let c = dispatch(loginUser(values, props.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Hoş Geldiniz</h5>
                        <p>CRCE'ye devam etmek için kullanıcı girişi yapın.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4"></div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}>

                      {error ? <Alert color="danger">Unable to log in with provided credentials.</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value="admin@admin.com"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value="123"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      {/* <div className="form-check"> */}
                      {/*   <input */}
                      {/*     type="checkbox" */}
                      {/*     className="form-check-input" */}
                      {/*     id="customControlInline" */}
                      {/*   /> */}
                      {/*   <label className="form-check-label" htmlFor="customControlInline"> */}
                      {/*     Remember me */}
                      {/*   </label> */}
                      {/* </div> */}

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block" type="submit">
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center"> */}
                      {/*   <Link to="/forgot-password" className="text-muted"> */}
                      {/*     <i className="mdi mdi-lock me-1" /> */}
                      {/*     Forgot your password? */}
                      {/*   </Link> */}
                      {/* </div> */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center"> */}
              {/*   <p> */}
              {/*     Don&#39;t have an account ?{' '} */}
              {/*     <Link to="register" className="fw-medium text-primary"> */}
              {/*       {' '} */}
              {/*       Signup now{' '} */}
              {/*     </Link>{' '} */}
              {/*   </p> */}
              {/*   <p> */}
              {/*     © {new Date().getFullYear()} Skote. Crafted with{' '} */}
              {/*     <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
              {/*   </p> */}
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
