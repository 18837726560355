import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

// import Component
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'
import Vizitler from './Vizitler'
import Monitorization from './Monitorization'
import Centers from './Centers'
import Devices from './Devices'
import Payments from './Payments'
import { CardText, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

const Index = props => {
  // STORE
  const { projeler } = useSelector(state => ({
    projeler: state.contacts.projeler,
    folders: state.Bilgilendirme.folders,
  }))

  // STATES
  const [current_proje, setCurrentProje] = useState({})

  // set current proje

  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  const [customIconActiveTab, setcustomIconActiveTab] = useState('1')

  const toggleCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - Planlama ve Takvim</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Planlama ve Takvim" breadcrumbItem={current_proje ? current_proje.name : ''} />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">{/* FileRightBar  */}</div>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customIconActiveTab === '1',
                        })}
                        onClick={() => {
                          toggleCustom('1')
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Vizitler</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customIconActiveTab === '2',
                        })}
                        onClick={() => {
                          toggleCustom('2')
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Monitörizasyon</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customIconActiveTab === '3',
                        })}
                        onClick={() => {
                          toggleCustom('3')
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Merkezler</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customIconActiveTab === '4',
                        })}
                        onClick={() => {
                          toggleCustom('4')
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Cihaz Ve Kalibrasyon</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: customIconActiveTab === '5',
                        })}
                        onClick={() => {
                          toggleCustom('5')
                        }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Ödemeler</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={customIconActiveTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <Vizitler current_proje={current_proje} />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <Monitorization current_proje={current_proje} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <Centers current_proje={current_proje} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <Devices current_proje={current_proje} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <Payments current_proje={current_proje} />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Index
