import React from 'react'
import { Redirect } from 'react-router-dom'

// // Pages Component
import Chat from '../pages/Chat/Chat'

// // File Manager
import Bilgilendirme from '../pages/Bilgilendirme/index'

// // File Manager
import SurecTakibi from '../pages/OperasyonelTakip/SurecTakibi'
import HastaGruplari from '../pages/OperasyonelTakip/HastaGruplari'
import VisitIslemleri from '../pages/OperasyonelTakip/VizitIslemleri'

// Planlama ve Takvim
import PlanlamaVeTakvim from '../pages/PlanlamaVeTakvim'

import Istatistik from '../pages/Istatistik'

import DosyaIndir from '../pages/DosyaIndir'

// Profile
import UserProfile from '../pages/Authentication/user-profile'

// Pages Calendar
import Calendar from '../pages/Calendar/index'

// //Tasks
import TasksList from '../pages/Tasks/tasks-list'
import TasksKanban from '../pages/Tasks/tasks-kanban'
import TasksCreate from '../pages/Tasks/tasks-create'

// //Ecommerce Pages
import EcommerceProducts from '../pages/Ecommerce/EcommerceProducts/index'
import EcommerceProductDetail from '../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail'
import EcommerceOrders from '../pages/Ecommerce/EcommerceOrders/index'
import EcommerceCustomers from '../pages/Ecommerce/EcommerceCustomers/index'
import EcommerceCart from '../pages/Ecommerce/EcommerceCart'
import EcommerceCheckout from '../pages/Ecommerce/EcommerceCheckout'
import EcommerceShops from '../pages/Ecommerce/EcommerceShops/index'
import EcommerceAddProduct from '../pages/Ecommerce/EcommerceAddProduct'

//Email
import EmailInbox from '../pages/Email/email-inbox'
import EmailRead from '../pages/Email/email-read'
import EmailBasicTemplte from '../pages/Email/email-basic-templte'
import EmailAlertTemplte from '../pages/Email/email-template-alert'
import EmailTemplateBilling from '../pages/Email/email-template-billing'

//Invoices
import InvoicesList from '../pages/Invoices/invoices-list'
import InvoiceDetail from '../pages/Invoices/invoices-detail'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'

//  // Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login'
import Login2 from '../pages/AuthenticationInner/Login2'
import Register1 from '../pages/AuthenticationInner/Register'
import Register2 from '../pages/AuthenticationInner/Register2'
import Recoverpw from '../pages/AuthenticationInner/Recoverpw'
import Recoverpw2 from '../pages/AuthenticationInner/Recoverpw2'
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword'
import ForgetPwd2 from '../pages/AuthenticationInner/ForgetPassword2'
import LockScreen from '../pages/AuthenticationInner/auth-lock-screen'
import LockScreen2 from '../pages/AuthenticationInner/auth-lock-screen-2'
import ConfirmMail from '../pages/AuthenticationInner/page-confirm-mail'
import ConfirmMail2 from '../pages/AuthenticationInner/page-confirm-mail-2'
import EmailVerification from '../pages/AuthenticationInner/auth-email-verification'
import EmailVerification2 from '../pages/AuthenticationInner/auth-email-verification-2'
import TwostepVerification from '../pages/AuthenticationInner/auth-two-step-verification'
import TwostepVerification2 from '../pages/AuthenticationInner/auth-two-step-verification-2'

// Dashboard
import Dashboard from '../pages/Dashboard/index'
import DashboardSaas from '../pages/Dashboard-saas/index'
import DashboardCrypto from '../pages/Dashboard-crypto/index'
import Blog from '../pages/Dashboard-Blog/index'

//Crypto
import CryptoWallet from '../pages/Crypto/CryptoWallet/crypto-wallet'
import CryptoBuySell from '../pages/Crypto/crypto-buy-sell'
import CryptoExchange from '../pages/Crypto/crypto-exchange'
import CryptoLending from '../pages/Crypto/crypto-lending'
import CryptoOrders from '../pages/Crypto/CryptoOrders/crypto-orders'
import CryptoKYCApplication from '../pages/Crypto/crypto-kyc-application'
import CryptoIcoLanding from '../pages/Crypto/CryptoIcoLanding/index'

// Charts
import ChartApex from '../pages/Charts/Apexcharts'
import ChartistChart from '../pages/Charts/ChartistChart'
import ChartjsChart from '../pages/Charts/ChartjsChart'
import EChart from '../pages/Charts/EChart'
import SparklineChart from '../pages/Charts/SparklineChart'
import ToastUIChart from '../pages/Charts/ToastUIChart'
import ChartsKnob from '../pages/Charts/charts-knob'
import ReCharts from '../pages/Charts/ReCharts'

// Maps
import MapsGoogle from '../pages/Maps/MapsGoogle'
import MapsVector from '../pages/Maps/MapsVector'
import MapsLeaflet from '../pages/Maps/MapsLeaflet'

//Icons
import IconBoxicons from '../pages/Icons/IconBoxicons'
import IconDripicons from '../pages/Icons/IconDripicons'
import IconMaterialdesign from '../pages/Icons/IconMaterialdesign'
import IconFontawesome from '../pages/Icons/IconFontawesome'

//Tables
import BasicTables from '../pages/Tables/BasicTables'
import DatatableTables from '../pages/Tables/DatatableTables'
import ResponsiveTables from '../pages/Tables/ResponsiveTables'
import EditableTables from '../pages/Tables/EditableTables'
import DragDropTables from '../pages/Tables/DragDropTables'

//Blog
import BlogList from '../pages/Blog/BlogList/index'
import BlogGrid from '../pages/Blog/BlogGrid/index'
import BlogDetails from '../pages/Blog/BlogDetails'

// Forms
import FormElements from '../pages/Forms/FormElements'
import FormLayouts from '../pages/Forms/FormLayouts'
import FormAdvanced from '../pages/Forms/FormAdvanced'
import FormEditors from '../pages/Forms/FormEditors'
import FormValidations from '../pages/Forms/FormValidations'
import FormMask from '../pages/Forms/FormMask'
import FormRepeater from '../pages/Forms/FormRepeater'
import FormUpload from '../pages/Forms/FormUpload'
import FormWizard from '../pages/Forms/FormWizard'
import FormXeditable from '../pages/Forms/FormXeditable'
import DualListbox from '../pages/Tables/DualListbox'

//Ui
import UiAlert from '../pages/Ui/UiAlert'
import UiButtons from '../pages/Ui/UiButtons'
import UiCards from '../pages/Ui/UiCards'
import UiCarousel from '../pages/Ui/UiCarousel'
import UiColors from '../pages/Ui/UiColors'
import UiDropdown from '../pages/Ui/UiDropdown'
import UiGeneral from '../pages/Ui/UiGeneral'
import UiGrid from '../pages/Ui/UiGrid'
import UiImages from '../pages/Ui/UiImages'
import UiLightbox from '../pages/Ui/UiLightbox'
import UiModal from '../pages/Ui/UiModal'
import UiProgressbar from '../pages/Ui/UiProgressbar'
import UiSweetAlert from '../pages/Ui/UiSweetAlert'
import UiTabsAccordions from '../pages/Ui/UiTabsAccordions'
import UiTypography from '../pages/Ui/UiTypography'
import UiVideo from '../pages/Ui/UiVideo'
import UiSessionTimeout from '../pages/Ui/UiSessionTimeout'
import UiRating from '../pages/Ui/UiRating'
import UiRangeSlider from '../pages/Ui/UiRangeSlider'
import UiNotifications from '../pages/Ui/ui-notifications'
import UiDrawer from 'pages/Ui/UiDrawer'
import UiBreadcrumb from '../pages/Ui/UiBreadcrumb'

//Pages
import PagesStarter from '../pages/Utility/pages-starter'
import PagesMaintenance from '../pages/Utility/pages-maintenance'
import PagesComingsoon from '../pages/Utility/pages-comingsoon'
import PagesTimeline from '../pages/Utility/pages-timeline'
import PagesFaqs from '../pages/Utility/pages-faqs'
import PagesPricing from '../pages/Utility/pages-pricing'
import Pages404 from '../pages/Utility/pages-404'
import HomePage from '../pages/Utility/homepage'
import Pages500 from '../pages/Utility/pages-500'

//Contacts
import ContactsGrid from '../pages/Projeler/contacts-grid'
import ContactsList from '../pages/Projeler/ContactList/contacts-list'

// fiziblite

import Fizibilite from '../pages/Fizibilite'

const userRoutes = [
  // fiziblite
  { path: '/fiziblite/:id', component: Fizibilite },

  // Bilgilendirme
  { path: '/bilgilendirme/:id', component: Bilgilendirme },

  //Surec Takibi
  { path: '/surec-takibi/:id', component: SurecTakibi },
  { path: '/hasta-gruplari/:id', component: HastaGruplari },
  { path: '/visit-islemleri/:id', component: VisitIslemleri },

  //File Manager
  { path: '/planlama-ve-takvim/:id', component: PlanlamaVeTakvim },

  //File Manager
  { path: '/istatistik/:id', component: Istatistik },

  //Download Files
  { path: '/dosya-indir/:id', component: DosyaIndir },

  // Contacts
  { path: '/projeler', component: ContactsList },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: HomePage },
  { path: '/*', exact: true, component: Pages404 },
]

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
]

export { userRoutes, authRoutes }
