import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PROJECTS, ADD_NEW_PROJECT, UPDATE_PROJECT, GET_PROJECTS_USERS, ADD_USER_TO_PROJECT,REMOVE_USER_TO_PROJECT, CREATE_NEW_USER,GET_PROJECT_BUDGET_ABSTRACT } from "./actionTypes"

import {
  getProjectsSuccess,
  getProjectsFail,
  addProjectFail,
  addProjectSuccess,
  getProjectUsersSuccess,
  getProjectUsersFail,
  updateUserSuccess,
  updateUserFail,
  addUserToProjectSuccess,
  addUserToProjectFail,
  addUserToProjectLastResponse,
  removeUserToProjectSuccess,
  removeUserToProjectFail,
  removeUserToProjectLastResponse,
  createNewUserSuccess,
  createNewUserFail,
  projectBudgetAbstractSuccess,
  getProjectBudgetAbstract,
} from "./actions"

//Include Both Helper File with needed methods
import { getProjects, updateProject, addProject, getProjectUsersRequest, addUserToProjectRequest,removeUserToProjectRequest, createNewUserRequest,projectBudgetAbstractRequest } from "../../helpers/backend"


function* fetchProjects() {
  try {
    const response = yield call(getProjects)
    yield put(getProjectsSuccess(response))
  } catch (error) {
    yield put(getProjectsFail(error))
  }
}

function* onCreateNewUser({payload}) {
  try {
    const response = yield call(createNewUserRequest, payload)
    yield put(createNewUserSuccess(response))
  } catch (error) {
    yield put(createNewUserFail(error.response.data));
  }
}
function* getProjectBudgetAbstractFunction({payload}) {
  try {
    const response = yield call(projectBudgetAbstractRequest, payload)
    yield put(projectBudgetAbstractSuccess(response))
  } catch (error) {
    yield put(createNewUserFail(error.response.data));
  }
}


function* fetchProjectUsers({ payload: project }) {
  try {
    const response = yield call(getProjectUsersRequest, project)
    yield put(getProjectUsersSuccess(project, response))
  } catch (error) {
    yield put(getProjectUsersFail(error))
  }
}

function* onAddUserToProject({payload}) {
  try {
    const response = yield call(addUserToProjectRequest, payload.project_id, payload.user_mail)
    yield put(addUserToProjectSuccess(response))
    yield put(addUserToProjectLastResponse(response));
  }
  catch (error) {
    if (error.statusCode == 406){
      yield put(addUserToProjectLastResponse(error.response.data));
    }
    else{
      yield put(addUserToProjectFail(error))
    }
  }
}
function* onRemoveUserToProject({payload}) {
  try {
    const response = yield call(removeUserToProjectRequest, payload.project_id, payload.user_id)
    yield put(removeUserToProjectSuccess(response))
    yield put(removeUserToProjectLastResponse(response));
  }
  catch (error) {
    if (error.statusCode = 406){
      yield put(removeUserToProjectLastResponse(error.response.data));
    }
    else{
      yield put(removeUserToProjectFail(error))
    }
  }
}

function* onUpdateProjects({ payload: project }) {
  try {
    const response = yield call(updateProject, project)
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddNewUser({ payload: project }) {

  try {
    const response = yield call(addProject, project)

    yield put(addProjectSuccess(response))
  } catch (error) {

    yield put(addProjectFail(error))
  }
}

function* contactsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects)
  yield takeEvery(ADD_NEW_PROJECT, onAddNewUser)
  yield takeEvery(UPDATE_PROJECT, onUpdateProjects)
  yield takeEvery(GET_PROJECTS_USERS, fetchProjectUsers)
  yield takeEvery(ADD_USER_TO_PROJECT, onAddUserToProject)
  yield takeEvery(REMOVE_USER_TO_PROJECT, onRemoveUserToProject)
  yield takeEvery(CREATE_NEW_USER, onCreateNewUser)
  yield takeEvery(GET_PROJECT_BUDGET_ABSTRACT, getProjectBudgetAbstractFunction)
}

export default contactsSaga;
