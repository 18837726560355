import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container, Button } from 'reactstrap'
import MetaTags from 'react-meta-tags'
import { downloadAllFilesOfProjectRequest } from '../../helpers/backend'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

// import Component
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'

const Index = props => {
  const dispatch = useDispatch()

  // STORE
  const { projeler } = useSelector(state => ({
    projeler: state.contacts.projeler,
  }))

  // const [activeTab, setActiveTab] = useState('1')

  // States
  const [current_proje, setCurrentProje] = useState({})
  useEffect(() => {
    setCurrentProje(
      projeler.find((element, _index, _array) => {
        if (element.id == props.match.params.id) {
          return element
        }
      })
    )
  }, [props, projeler])

  const dosyalari_indir = proje_id => {
    downloadAllFilesOfProjectRequest(proje_id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Crceye - Dosyaları İndir</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dosyaları İndir" breadcrumbItem={current_proje ? current_proje.name : ''} />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <Button onClick={dosyalari_indir} color="primary">
                        Tüm Dosyaları İndir
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Index
