import {
  GET_PROJECTS_FOLDER,
  GET_PROJECTS_FOLDER_SUCCESS,
  GET_PROJECTS_FOLDER_FAIL,
  CREATE_PROJECTS_FOLDER,
  GLOBAL_FAIL,
  GET_FOLDERS_FILES,
  GET_FOLDERS_FILES_SUCCESS,
  DELETE_FILE,
  CREATE_FILE,
  FORM_DONE,
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  GET_FILES_WORKFLOW,
  CREATE_OR_UPDATE_WORKFLOW,
  GET_WORKFLOW_SUCCESS,
  GET_WORKFLOW_FAILED,
  CLEAR_ERROR_MESSAGE,
} from "./actionTypes"

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR_MESSAGE
})
export const getProjectsFolderAction = (project) => ({
  type: GET_PROJECTS_FOLDER,
  payload: project,
})

export const getProjectsFolderSuccessAction = (project_id, folders) => ({
  type: GET_PROJECTS_FOLDER_SUCCESS,
  payload: {
    project_id: project_id,
    folders: folders,
  },
})

export const getProjectsFolderFailAction = (error) => ({
  type: GET_PROJECTS_FOLDER_FAIL,
  payload: error,
})


export const createProjectsFolderAction = (project_id, name) => ({
  type: CREATE_PROJECTS_FOLDER,
  payload: {
    project_id: project_id,
    name: name,
  },
})


export const globalFailAction = (message) => ({
  type: GLOBAL_FAIL,
  payload: {
    message: message
  }
})

export const getFoldersFilesAction = (folder_id) => ({
  type: GET_FOLDERS_FILES,
  payload: { folder_id: folder_id }
})
export const getFoldersFilesWorkFlowAction = (file_id, workflows) => ({
  type: GET_FILES_WORKFLOW,
  payload: {
    file_id: file_id,
    workflows: workflows
  }
})

export const getFoldersFileWorkFlowActionSuccess = (file_id, workflows) => ({
  type: GET_WORKFLOW_SUCCESS,
  payload: {
    file_id: file_id,
    workflows: workflows
  }
})
export const getFoldersFileWorkFlowActionFailed = (file_id, error) => ({
  type: GET_WORKFLOW_FAILED,
  payload: {
    file_id: file_id,
    error: error
  }
})
export const getFoldersFilesActionSuccess = (folder_id, files) => ({
  type: GET_FOLDERS_FILES_SUCCESS,
  payload: {
    folder_id: folder_id,
    files: files
  }
})

export const deleteFile = (file_id, folder_id) => ({
  type: DELETE_FILE,
  payload: {
    file_id: file_id,
    folder_id: folder_id,
  }
})


export const createFile = (folder_id, file_type, version_note, base64file, name) => ({
  type: CREATE_FILE,
  payload: {
    folder_id: folder_id,
    file_type: file_type,
    version_note: version_note,
    file: base64file,
    name: name,
  }
})


export const formDoneStatusAction = (status) => ({
  type: FORM_DONE,
  payload: status
})


export const fileWorkFlowStatus = (file_id) => ({
  type: GET_FILES_WORKFLOW,
  payload: {
    file_id: file_id
  }
})
export const workFlowSet = (file_id,data) => ({
  type: CREATE_OR_UPDATE_WORKFLOW,
  payload: {
    file_id: file_id,
    id: data.id,
    assigned_user_id: data.assigned_user_id,
    create_datetime: data.create_datetime,
    delete_wf: data.delete_wf,
  }
})


export const createPermissionAction = (file_id, folder_id, user_type) => ({
  type: CREATE_PERMISSION,
  payload: {
    file_id: file_id,
    user_type: user_type,
    folder_id: folder_id,
  }
})

export const deletePermissionAction = (file_id, folder_id, user_type) => ({
  type: DELETE_PERMISSION,
  payload: {
    file_id: file_id,
    user_type: user_type,
    folder_id: folder_id,
  }
})


