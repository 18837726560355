import React, { useEffect, useState } from 'react'

import { downloadAllFilesOfProjectRequest } from '../../../helpers/backend'
import MetaTags from 'react-meta-tags'
import { withRouter, Link } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody } from 'reactstrap'
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator'
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import { get_user_type } from '../../../helpers/storage'
//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'

import {
  getProjects,
  addNewProject as onAddNewUser,
  updateUser as onUpdateProjects,
  getProjectUsers,
  addUserToProject,
  addUserToProjectLastResponseHandled,
  removeUserToProject,
  removeUserToProjectLastResponseHandled,
  createNewUser,
  createNewUserResetResult,
  getProjectBudgetAbstract,
} from 'store/projeler/actions'
import { isEmpty } from 'lodash'
import * as moment from 'moment'

//redux
import { useSelector, useDispatch } from 'react-redux'

const ContactsList = () => {
  const dispatch = useDispatch()

  const [userType, setUserType] = useState('DCT')
  useEffect(() => {
    setUserType(get_user_type())
  }, [])

  const { projeler, projects_get, add_user_to_project_last_response, create_new_user_response, budget_abs } = useSelector(
    state => ({
      projeler: state.projeler.projeler,
      projects_get: state.projeler.projects_get,
      add_user_to_project_last_response: state.projeler.add_user_to_project_last_response,
      create_new_user_response: state.projeler.create_new_user_response,
      budget_abs: state.projeler.budget_abs,
    })
  )

  const [current_budget_abs, setCurrentBudget] = useState({})
  const [budgetAbsModalShow, setBudgetAbsModalShow] = useState(false)
  const [userList, setUserList] = useState([])

  const [new_user_modal, setNewUserModal] = useState(false)
  const [add_user_last_response_state, setAddUserLastResponseState] = useState('')
  const [clicked_project_users_id, setClickedProjectUsersId] = useState()
  const [new_edit_modal, setNewEditModal] = useState(false)
  const [show_users_modal, setShowUsersModal] = useState(false)
  const [add_user_to_project_modal, addUserToProjectModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 20,
    totalSize: projeler.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: 'created_datetime', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc', // desc or asc
    },
  ]

  const selectRow = {
    mode: 'checkbox',
  }

  const humanReadableDate = date => {
    const date1 = moment(new Date(date)).format('DD MMM Y')
    return date1
  }

  const datetimeToStr = date => {
    const date1 = moment(new Date(date)).format('YYYY-MM-DD')
    return date1
  }

  const projectListColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      text: 'İsim',
      dataField: 'name',
      sort: true,
      formatter: (cellContent, project) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {project.name}
            </Link>
          </h5>
        </>
      ),
    },
    {
      dataField: 'firm',
      text: 'Firma',
      sort: true,
    },
    {
      dataField: 'start_datetime',
      text: 'Başlangıç Tarihi',
      sort: true,
      formatter: (cellContent, project) => <>{humanReadableDate(project.start_datetime)}</>,
    },
    {
      dataField: 'num_of_assigned_users',
      text: 'Görevli Üye Sayısı',
      sort: true,
      formatter: (cellContent, project) => (
        <div className="avatar-group float-start">
          <div className="avatar-group-item" key={project.id}>
            <Link to="#" className="d-inline-block" onClick={() => handleShowUsersClick(project)}>
              <div className="avatar-xs">
                <span
                  className={`avatar-title rounded-circle + ${project.num_of_assigned_users > 0 ? 'bg-warning' : 'bg-info'
                    } + text-white font-size-16`}>
                  {project.num_of_assigned_users}
                </span>
              </div>
            </Link>{' '}
            Kullanıcı
          </div>
        </div>
      ),
    },
    {
      dataField: 'end_datetime',
      text: 'BitiŞ Tarihi',
      sort: true,
      formatter: (cellContent, project) => <>{humanReadableDate(project.end_datetime)}</>,
    },
    {
      dataField: 'is_active',
      text: 'Aktif',
      sort: true,
    },
    {
      dataField: 'id',
      text: 'Dosyaları İndir',
      sort: false,
      formatter: (cellContent, project) => (
        <Button onClick={dosyalari_indir.bind(this, project.id)} color="primary">
          Dosyaları Indir
        </Button>
      ),
    },
    {
      dataField: 'menu',
      isDummyField: true,
      editable: false,
      text: 'Düzenle',
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => handleUserEditClick(user)}></i>
          </Link>
          {/* <Link className="text-danger" to="#"> */}
          {/*   <i */}
          {/*     className="mdi mdi-delete font-size-18" */}
          {/*     id="deletetooltip" */}
          {/*     onClick={() => handleDeleteUser(user)}></i> */}
          {/* </Link> */}
        </div>
      ),
    },
    {
      dataField: 'budgetMenu',
      isDummyField: true,
      editable: false,
      text: 'Bütçe',
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-primary" to="#">
            <i className="mdi mdi-eye font-size-18" id="showBudget" onClick={() => handleShowBudget(user)}></i>
          </Link>
          {/* <Link className="text-danger" to="#"> */}
          {/*   <i */}
          {/*     className="mdi mdi-delete font-size-18" */}
          {/*     id="deletetooltip" */}
          {/*     onClick={() => handleDeleteUser(user)}></i> */}
          {/* </Link> */}
        </div>
      ),
    },
  ]

  const dosyalari_indir = proje_id => {
    downloadAllFilesOfProjectRequest(proje_id)
  }

  useEffect(() => {
    if (projects_get == false) {
      dispatch(getProjects())
      setIsEdit(false)
    }
  }, [dispatch, projeler])

  useEffect(() => {
    setUserList(projeler)
    setIsEdit(false)
  }, [projeler])

  useEffect(() => {
    if (!isEmpty(projeler) && !!isEdit) {
      setUserList(projeler)
      setIsEdit(false)
    }
  }, [projeler])

  useEffect(() => {
    if (add_user_to_project_last_response !== null) {
      setAddUserLastResponseState(add_user_to_project_last_response)
      if (add_user_to_project_last_response.status === 'USER_NOT_FOUND') {
        setNewUserModal(true)
      } else if (add_user_to_project_last_response.status === 'ALLREADY_ADDED') {
        alert('User allready_added')
      } else if (add_user_to_project_last_response.status === 'OK') {
        add_users_to_project_modal_toggle()
        alert('ADDED')
        dispatch(getProjectUsers(add_user_to_project_last_response.project))
      }

      dispatch(addUserToProjectLastResponseHandled())
    }
  }, [add_user_to_project_last_response])

  useEffect(() => {
    if (create_new_user_response.result === 'OK') {
      alert('User Created')
      setNewUserModal(false)
      dispatch(createNewUserResetResult())
    }
    if (create_new_user_response.result === 'FAIL') {
      for (const [key, value] of Object.entries(create_new_user_response.reason)) {
        alert(`${key}: ${value}`)
      }
    }
  }, [create_new_user_response])


  const new_edit_modal_toggle = () => {
    setNewEditModal(!new_edit_modal)
  }

  const show_users_modal_toggle = () => {
    setShowUsersModal(!show_users_modal)
  }
  const add_users_to_project_modal_toggle = () => {
    addUserToProjectModal(!add_user_to_project_modal)
  }
  const create_new_user_modal_toggle = () => {
    setNewUserModal(!new_user_modal)
  }

  const handleShowUsersClick = project => {
    setShowUsersModal(true)

    setClickedProjectUsersId(project.id)
    dispatch(getProjectUsers(project))
  }

  const handleUserEditClick = arg => {
    const user = arg

    setUserList({
      id: user.id,
      name: user.name,
      firm: user.firm,
      is_active: user.is_active,
      start_datetime: user.start_datetime,
      end_datetime: user.end_datetime,
      budget: user.budget,
    })
    setIsEdit(true)
    console.log(userList)

    new_edit_modal_toggle()
  }
  useEffect(() => {
    console.log(budget_abs);
    if (budget_abs.project_budget) {
      setCurrentBudget(budget_abs)
      setBudgetAbsModalShow(true)
    }
  }, [budget_abs])

  const toggle_budget_modal = () => {
      setBudgetAbsModalShow(!budgetAbsModalShow)
  }
  const handleShowBudget = arg => {

    if (arg.id) {
      setCurrentBudget({})
      dispatch(getProjectBudgetAbstract(arg.id))
    }
  }

  // const handleDeleteUser = user => {
  //   dispatch(onDeleteUser(user))
  // }

  /**
   * Handling submit user on user form
   */
  const handleValidUserSubmit = (e, values) => {
    if (isEdit) {
      const updateUser = {
        id: userList.id,
        name: values.name,
        firm: values.firm,
        end_datetime: values.end_datetime,
        is_active: values.is_active,
        budget: values.budget,
      }

      // update user
      dispatch(onUpdateProjects(updateUser))
      setIsEdit(false)
    } else {
      const newUser = {
        name: values['name'],
        firm: values['firm'],
        end_datetime: values['end_datetime'],
        is_active: values['is_active'],
        budget: values['budget'],
      }
      // save new user
      dispatch(onAddNewUser(newUser))
    }
    new_edit_modal_toggle()
  }

  const handleNewAddUserToProjectSubmit = (e, values) => {
    dispatch(addUserToProject(clicked_project_users_id, values['email']))
  }

  const handleNewUserSubmit = (e, values) => {
    dispatch(createNewUser(values))
  }

  const handleUserClicks = () => {
    setUserList('')
    setIsEdit(false)
    new_edit_modal_toggle()
  }
  const removeUserFromProject = (project_id, user_id) => {
    dispatch(removeUserToProject(project_id, user_id))
    const removedItem = document.getElementById(user_id);

    // it removes the id from a div element
    removedItem.remove();
    alert("Kullanıcı Silindi!");

    // dispatch(getProjectUsers(project_id))
  }

  const getUsersFromProjectUsersId = () => {
    let x = projeler
      .map(proje => {
        if (proje.id == clicked_project_users_id) {
          if (proje.users) {
            return proje.users.map(user => {
              return <li id={user.id} key={user.id}>{user.email}<a class="text-danger" onClick={() => removeUserFromProject(proje.id, user.id)}><i class="mdi mdi-trash-can font-size-18" id="edittooltip"></i></a></li>
            })
          }
        }
      })
      .filter(x => x)
    return x
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User List </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Proje Yönetimi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={projectListColumns}
                    data={projeler}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="id" data={projeler} columns={projectListColumns} bootstrap4 search>
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                {userType === 'ADM' ? (
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={handleUserClicks}>
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Yeni Proje
                                    </Button>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap table-hover'}
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                  {/*
                    /////////////////
                    Add edit project modal
                    /////////////////
                                    */}
                                  <Modal isOpen={new_edit_modal} toggle={new_edit_modal_toggle}>
                                    <ModalHeader toggle={new_edit_modal_toggle} tag="h4">
                                      {!!isEdit ? 'Proje Düzenle' : 'Proje Ekle'}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm onValidSubmit={handleValidUserSubmit}>
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="name"
                                                label="İsim"
                                                type="text"
                                                errorMessage="Invalid name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.name || ''}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="firm"
                                                label="Firma"
                                                type="text"
                                                errorMessage="Invalid name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.firm || ''}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="budget"
                                                label="Bütçe"
                                                type="number"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.budget || ''}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="end_datetime"
                                                label="Bitiş Tarihi"
                                                type="date"
                                                errorMessage="Invalid Date"
                                                format="YYYY/MM/DD"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={datetimeToStr(userList.end_datetime) || ''}></AvField>
                                            </div>
                                            {isEdit ? (
                                              <div className="mb-3">
                                                <AvField
                                                  name="is_active"
                                                  label="Aktif"
                                                  type="checkbox"
                                                  value={userList.is_active}></AvField>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="submit" className="btn btn-success save-user">
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                  {/*
                    /////////////////
                    Edit User Modal
                    /////////////////
                                    */}
                                  <Modal isOpen={show_users_modal} toggle={show_users_modal_toggle}>
                                    <ModalHeader toggle={show_users_modal_toggle} tag="h4">
                                      USERS
                                    </ModalHeader>
                                    <ModalBody>
                                      <Row>
                                        <Col>{clicked_project_users_id ? getUsersFromProjectUsersId() : <></>}</Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              onClick={add_users_to_project_modal_toggle}
                                              type="submit"
                                              className="btn btn-success save-user">
                                              Projeye Kullanıcı Ekle
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </ModalBody>
                                  </Modal>
                                  {/*
                    /////////////////
                    Add user to project modal
                    /////////////////
                                    */}
                                  <Modal isOpen={add_user_to_project_modal} toggle={add_users_to_project_modal_toggle}>
                                    <ModalHeader toggle={add_users_to_project_modal_toggle} tag="h4">
                                      Add users to
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm onValidSubmit={handleNewAddUserToProjectSubmit}>
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                errorMessage="Invalid email"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.name || ''}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="submit" className="btn btn-success save-user">
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                  {/*
                    /////////////////
                    New user modal
                    /////////////////
                                    */}
                                  <Modal isOpen={new_user_modal} toggle={create_new_user_modal_toggle}>
                                    <ModalHeader toggle={create_new_user_modal_toggle} tag="h4">
                                      Yeni Kullanıcı Yarat
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm onValidSubmit={handleNewUserSubmit}>
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                errorMessage="Invalid email"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={add_user_last_response_state.email}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="name"
                                                label="İsim"
                                                type="text"
                                                errorMessage="Invalid name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="password"
                                                label="Şifre"
                                                type="text"
                                                errorMessage="Invalid password"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </div>
                                            <AvRadioGroup
                                              name="user_type"
                                              label="Kullanıcı Tipi"
                                              required
                                              errorMessage="Pick one!">
                                              <AvRadio label="ADMIN" value="ADM" disabled />
                                              <AvRadio label="PM" value="PM" />
                                              <AvRadio label="SPONSOR" value="SPS" />
                                              <AvRadio label="CRA" value="CRA" />
                                              <AvRadio label="SC" value="SC" />
                                              <AvRadio label="DOCTOR" value="DCT" />
                                            </AvRadioGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button type="submit" className="btn btn-success save-user">
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                  {/*
                    ///////////////////////////////////////////////////
                                    */}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={budgetAbsModalShow} toggle={toggle_budget_modal}>
          <ModalHeader toggle={toggle_budget_modal} tag="h4">
            Proje Bütçe Özeti
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col xs={12}>
                <div className="mb-3">
                  {current_budget_abs.project_budget ? (
                    <div>
                      <h5>Proje Bütçesi: {current_budget_abs.project_budget}</h5>
                      <h5>Tüm Giderler: {current_budget_abs.total}</h5>
                      <h5>Bütçeden Karşılanacak Tutar: {current_budget_abs.totalProforma}</h5>
                      <h5>Kalan Bakiye: {current_budget_abs.remaining}</h5>
                    </div>
                  ) : (
                    <div>
                      <h3>Proje Bütçesi: 0</h3>
                      <h3>Tüm Giderler: 0</h3>
                      <h3>Kalan Bakiye: 0</h3>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsList)
