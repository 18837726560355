import sign from "jwt-encode"
import { v4 as uuidv4 } from "uuid"

const jwtGenerate = {
  tokenGenerate: function (req, res) {
    const secret = uuidv4()
    const jti = uuidv4()
    const data = {
      name: "admin",
      jti: jti,
      iat: Date.now() / 1000,
      exp: Date.now() / 1000 + 3600,
    }
    let token = sign(data, secret)

    console.log(`signed`, token)
    return "Bearer " + token
  },
}

export default jwtGenerate
