export const GET_SUREC_TAKIPLER = "GET_SUREC_TAKIPLER"
export const GET_SUREC_TAKIPLER_SUCCESS = "GET_SUREC_TAKIPLER_SUCCESS"

export const SET_PROCESS_FOLLOW = "SET_PROCESS_FOLLOW"
export const DELETE_PROCESS_FOLLOW = "DELETE_PROCESS_FOLLOW"
export const NEW_PROCESS_FOLLOW = "NEW_PROCESS_FOLLOW"

export const GET_PATIENT_GROUP = "GET_PATIENT_GROUP"
export const GET_PATIENT_GROUP_SUCCESS = "GET_PATIENT_GROUP_SUCCESS"
export const SET_PATIENT_GROUP = "SET_PATIENT_GROUP"

export const NEW_VISIT_FOLLOW = "NEW_VISIT_FOLLOW"
export const GET_VISIT_FOLLOW = "GET_VISIT_FOLLOW"
export const GET_VISIT_FOLLOW_SUCCESS = "GET_VISIT_FOLLOW_SUCCESS"
