import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getProjectsFolderAction, createProjectsFolderAction } from '../../store/bilgilendirme/actions'
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'

const FileRightBar = props => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(true)

  const [current_projects_folders, setCurrentProjectFolders] = useState([])

  const { folders } = useSelector(state => ({
    folders: state.Bilgilendirme.folders,
  }))

  useEffect(() => {
    if (props.proje && props.proje.id) {
      dispatch(getProjectsFolderAction(props.proje))
    }
  }, [props.proje])

  useEffect(() => {
    if (folders.length) {
      let _folders = folders.filter(folder => {
        if (folder.project_id === props.proje.id) {
          return true
        }
        return false
      })

      if (_folders.length) {
        setCurrentProjectFolders(_folders[0]['folders'])
      } else {
        setCurrentProjectFolders([])
      }
    }
  }, [folders])

  useEffect(() => {
    if (current_projects_folders.length) {
    }
  }, [current_projects_folders])

  // New folder things
  const [new_folder_modal, setNewFolderModal] = useState(false)
  const set_new_folder_modal_toggle = () => {
    setNewFolderModal(!new_folder_modal)
  }
  const handle_new_folder_modal = (_, folder) => {
    dispatch(createProjectsFolderAction(props.proje.id, folder.name))
    set_new_folder_modal_toggle()
  }

  // // //
  const toggle = () => setIsOpen(!isOpen)

  const select_folder = (id, e) => {
    props.setCurrentFolder(id)
  }

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              <div className="mb-3">
                <UncontrolledDropdown>
                  {user_type == 'ADM' || user_type == 'SPS' || user_type == 'PM' || user_type == 'CRA' ? (
                    <Button className="btn btn-light w-100" color="#eff2f7" onClick={set_new_folder_modal_toggle}>
                      <i className="mdi mdi-plus me-1"></i> Klasör Ekle
                    </Button>
                  ) : (
                    ''
                  )}
                  <DropdownMenu></DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <ul className="list-unstyled categories-list">
                {current_projects_folders.map(folder => (
                  <li key={folder.id}>
                    <div className="custom-accordion">
                      <Link
                        className="text-body fw-medium py-1 d-flex align-items-center"
                        onClick={select_folder.bind(null, folder.id)}
                        to="#">
                        <i className="mdi mdi-folder font-size-16 text-warning me-2"></i> {folder.name}{' '}
                        {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
      {/*
      /////////////////
      New Klasör
      /////////////////
      */}
      <Modal isOpen={new_folder_modal} toggle={set_new_folder_modal_toggle}>
        <ModalHeader toggle={set_new_folder_modal_toggle} tag="h4">
          Yeni Klasör Yarat
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handle_new_folder_modal}>
            <Row form>
              <Col xs={12}>
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="İsim"
                    type="text"
                    errorMessage="YanlıŞ İsim"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      {/*
                    ///////////////////////////////////////////////////
                                    */}
    </React.Fragment>
  )
}

export default FileRightBar
